import React from "react";
import { View, Text } from "react-native";
import { SingleSelectDropdown } from "../common/controls/Dropdown-filter";
import { logStyles } from "./LogStyles";

interface Props {
    selected: string;
    label: string;
    options: Array<any>;
    onSelect: (val: string) => void;
}

const LogFilter: React.FC<Props> = ({ options, label, onSelect, selected }) => {
    return (
        <View style={{ width: '100%' }}>
            {/* <View style={{ padding: logStyles.container.padding }}>
                <Text>{'Filter by year'}</Text>
            </View> */}
            <View style={[logStyles.container, { width: '100%' }]}>
                <Text style={{ fontWeight: 'bold', fontSize: 14 }}>{'Filter by year'}</Text>

                <View style={{ width: '90%' }}>
                    <SingleSelectDropdown
                        options={options}
                        label={''}
                        onSelect={onSelect}
                        selected={selected}
                    />
                </View>
            </View>
        </View>
    )
}

export default LogFilter;