import React from "react";
import { View, Text } from "react-native";
import { logStyles } from "./LogStyles";
import ChartStyles from "../common/charts/chartStyles";
interface CountCardProps {
    cardTitle: string;
    number: number
}
const LogCountCard: React.FC<CountCardProps> = ({ number, cardTitle }) => {
    return (
        <View style={[logStyles.card, ChartStyles.box2, { borderRadius: 8, alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFFFFF' }]}>
            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{cardTitle}</Text>
            <Text style={{ fontSize: 18, fontWeight: 'bold' }}>{number}</Text>
        </View>
    )
}

export default LogCountCard