import React, { useState } from 'react';
import { View, Text, LayoutChangeEvent, Platform, Modal, Dimensions, ScrollView, TouchableOpacity } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import ChartStyles from './chartStyles';
import Legend from './Legend';
import { Divider, IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import { BlurView } from 'expo-blur';
import { Rect, Text as TextSVG, Svg, Line, G } from "react-native-svg";
//import * as ScreenOrientation from 'expo-screen-orientation';
import { LinearGradient } from 'expo-linear-gradient';
import { VictoryArea, VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryGroup, VictoryLabel, VictoryLine, VictoryScatter, VictoryStack, VictoryTooltip, VictoryVoronoiContainer, VictoryZoomContainer } from 'victory-native';
interface TooltipData {
  x: number;
  y: number;
}
interface MonthMapping {
  [key: string]: string;
}
interface LineChartScreenProps {
  label: string[];
  data: any;
  //data:Array<any>
  ChartTitle: string;
  subTitle?: string;
  legend?: any;
}

export default function LineChartScreen(props: LineChartScreenProps) {

  //const nullFilteredData = props.data.Line1.filter((value) => value !== null);
  const nullFilteredLabels = props.label;//.filter((label) => !label.includes('null'));
  const Months: MonthMapping = { "Jan": "01", "Feb": "02", "Mar": "03", "Apr": "04", "May": "05", "Jun": "06", "Jul": "07", "Aug": "08", "Sep": "09", "Oct": "10", "Nov": "11", "Dec": "12" }
  const [showFull, setShowFull] = useState<boolean>(false)
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const labelCount = nullFilteredLabels.length;
  const maxVisibleLabels = 5;
  const step = Math.ceil(labelCount / maxVisibleLabels);
  //const filteredLabels = nullFilteredLabels.filter((_, index) => index % step === 0);
  const filteredLabels = nullFilteredLabels.slice(0, 5).reverse();
  //const filteredLabels = nullFilteredLabels.slice(-6);

  const [containerWidth, setContainerWidth] = useState<number | null>(null); // set initial width to null
  const lineData = {
    labels: filteredLabels,
    datasets: props.data.datasets,
    //legend:props.data.legend,
  };

  const transformedDatasets: any = [];
  props.data.datasets.forEach((dataset: any, index: number) => {
    const top5Data = dataset.data.slice(0, 5).reverse();
    const lbls = props.data.labels.slice(0, 5).reverse()
    const transformedDataset = {
      data: top5Data.map((value: any, dataIndex: number) => ({
        x: lbls[dataIndex],
        y: value,
        key: props.legend[index].name
      })),
      strokeWidth: dataset.strokeWidth,
    };
    transformedDatasets.push(transformedDataset);
  });

  const transformedDatasetsAll: any = [];
  props.data.datasets.forEach((dataset: any, index: number) => {
    const allData = dataset.data
    const transformedDataset = {
      data: allData.map((value: any, dataIndex: number) => ({
        x: props.data.labels[dataIndex],
        y: value,
        key: props.legend[index].name
      })),
      strokeWidth: dataset.strokeWidth,
    };
    transformedDatasetsAll.push(transformedDataset);
  });


  const fullScreenLineData = {
    labels: nullFilteredLabels,
    datasets: props.data.datasets,
    //legend:props.data.legend,
  };

  const [tooltip, setTooltip] = useState<TooltipData | null>(null);
  let [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0, visible: false, value: 0 })
  const handleDataPointClick = (data: any, index: number) => {
    //debugger;
    const { x, y } = data.datasets[0].data[index];
    setTooltip({ x, y });
  };

  const onChartLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width); // set the width of the container View
  };

  return (
    <View>
      <View style={ChartStyles.style}>
        {props.data.datasets.length === 0 ?
          (
            <View>

              <View style={[ChartStyles.container, ChartStyles.box2]} onLayout={onChartLayout}>
                <View style={{ alignItems: 'center', flexDirection: 'row', width: containerWidth, justifyContent: 'center' }}>
                  <View>
                    <TouchableOpacity style={ChartStyles.TitleIconHolder}>
                      <IconButton icon="chart-bell-curve" iconColor={'#fff'} size={30} />
                    </TouchableOpacity>
                  </View>
                  <View style={ChartStyles.chartTitleContainer}>
                    <View>
                      <Text style={[ChartStyles.chartTitle]}>{props.ChartTitle}</Text>
                    </View>
                    <View>
                      <Text style={[ChartStyles.chartSubTitle]}>{props.subTitle}</Text>
                    </View>
                  </View>
                  {Platform.OS === 'web' ? (
                    <View style={ChartStyles.fullScreenIconContainer}>
                      <Tooltip title="View in Full Screen">
                        <IconButton
                          icon="fullscreen"
                          iconColor={ChartStyles.chartTitle.color}
                          size={20}
                          onPress={() => {
                            setShowFull(true);
                          }}
                        />
                      </Tooltip>
                    </View>
                  ) : null}
                </View>
                <ScrollView
                  style={{ backgroundColor: '#FFFFFF' }}
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={{ justifyContent: 'center' }}
                >
                  <View style={{ paddingLeft: 5, paddingRight: 5 }}>
                    <VictoryChart
                      domainPadding={{ x: 15 }}
                      containerComponent={
                        <VictoryVoronoiContainer labels={({ datum }) => `${datum.x}, ${datum.y}`} />
                      }
                    >
                      <VictoryGroup style={{ data: { fillOpacity: 0.3 } }}>

                        <VictoryArea
                          data={[{ x: 'Data', y: 0 }]}
                          style={{
                            data: { stroke: 'gray', fill: 'lightgray' },
                          }}
                        />
                      </VictoryGroup>


                      <VictoryAxis
                        style={{
                          tickLabels: {
                            fontSize: 10,
                            padding: 5,
                            angle: 45,
                            textAnchor: 'start',
                          },
                        }}
                      />


                      <VictoryAxis
                        dependentAxis
                        style={{
                          tickLabels: { fontSize: 10, padding: 5 },
                        }}
                      />
                    </VictoryChart>
                  </View>
                </ScrollView>

                <View
                  style={{
                    width: containerWidth,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    padding: 10,
                  }}
                >
                  <ScrollView
                    showsHorizontalScrollIndicator={false}
                    horizontal
                    contentContainerStyle={{ alignItems: 'center', paddingVertical: 8 }}
                  >
                    <Text>No data available to display</Text>
                  </ScrollView>
                </View>
              </View>
            </View>
          )
          :
          <View>
            <View style={[ChartStyles.container, ChartStyles.box2]} onLayout={onChartLayout}>
              <View style={{ alignItems: 'center', flexDirection: 'row', width: containerWidth, justifyContent: 'center' }}>
                <View>
                  <TouchableOpacity style={ChartStyles.TitleIconHolder}>
                    <IconButton
                      icon="chart-bell-curve"
                      iconColor={'#fff'}
                      size={30}
                    />
                  </TouchableOpacity>
                </View>
                <View style={ChartStyles.chartTitleContainer}>
                  <View><Text style={[ChartStyles.chartTitle]}>{props.ChartTitle}</Text></View>
                  <View><Text style={[ChartStyles.chartSubTitle]}>{props.subTitle}</Text></View>
                </View>
                {Platform.OS === 'web' ?
                  <View style={ChartStyles.fullScreenIconContainer}>
                    <Tooltip title="View in Full Screen">
                      <IconButton
                        icon="fullscreen"
                        iconColor={ChartStyles.chartTitle.color}
                        size={20}
                        onPress={() => { setShowFull(true) }}
                      />
                    </Tooltip>
                  </View> : null}
              </View>
              <ScrollView
                style={{ backgroundColor: '#FFFFFF' }}
                horizontal
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ justifyContent: 'center' }}
              >
                <View style={{ paddingLeft: 5, paddingRight: 5 }}>
                  <VictoryChart
                    //domainPadding={{ x: 15 }}
                    containerComponent={
                      <VictoryVoronoiContainer labels={({ datum }) => `${datum.x}, ${datum.y}`} />
                    }
                    domainPadding={{ x: 15, y: 20 }} // Add padding to avoid clipping
                    //domain={{ y: [0, Math.max(...transformedDatasetsAll.flatMap((dataset:any) => dataset.data.map((d:any) => d.y))) + 10] }} // Ensure y-domain covers the highest point
                  >
                    <VictoryGroup style={{ data: { fillOpacity: 0.3 } }}>
                      {transformedDatasets.map((dataset:any, index:number) => (
                        <VictoryArea
                          key={index}
                          name={`area-${index}`}
                          interpolation="natural"
                          data={dataset.data}
                          animate={{
                            duration: 2000,
                            onLoad: { duration: 2000 },
                          }}
                          style={{
                            data: {
                              stroke: props.data.datasets[index].color(1),
                              fill: props.data.datasets[index].color(1),
                            },
                          }}
                        />
                      ))}
                    </VictoryGroup>


                    <VictoryAxis
                      style={{
                        tickLabels: {
                          fontSize: 10,
                          padding: 5,
                          angle: 45,
                          textAnchor: 'start',
                        },
                      }}
                    />


                    <VictoryAxis
                      dependentAxis
                      style={{
                        tickLabels: { fontSize: 10, padding: 5 },
                      }}
                    />
                  </VictoryChart>
                </View>
              </ScrollView>
              <View style={{ width: containerWidth, flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
                <ScrollView showsHorizontalScrollIndicator={false} horizontal contentContainerStyle={{ alignItems: 'center', paddingVertical: 8 }}>
                  {props.legend && props.legend.map((legendItem: any, index: number) => {
                    const name = legendItem.name;
                    const color = props.data.datasets[index].color(1);
                    return <View key={index}><Legend fontColor={ChartStyles.chartTitle.color} key={name} name={name} color={color} /></View>;
                  })}
                </ScrollView>
              </View>
            </View>
          </View>}
      </View>
      {
        showFull === false ? null :
          <View>

            <ScrollView>
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 22 }}>
                <Modal
                  animationType="fade"
                  transparent={true}
                  visible={showFull}
                  onRequestClose={() => {
                    setShowFull(!showFull);
                  }}>
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 22 }}>
                    <BlurView intensity={80} style={{ flex: 1, padding: 10, justifyContent: 'center', }}>
                      <View style={{ marginLeft: '90%' }}>
                        <TouchableOpacity style={ChartStyles.TitleIconHolder}>
                          <Tooltip title="Close">
                            <IconButton
                              icon="close-circle-outline"
                              iconColor={'#fff'}
                              size={30}
                              onPress={() => setShowFull(!showFull)}
                            />
                          </Tooltip>
                        </TouchableOpacity>
                      </View>
                      <View style={{ width: screenWidth, flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
                        <ScrollView showsHorizontalScrollIndicator={false} horizontal contentContainerStyle={{ alignItems: 'center', paddingVertical: 8 }}>
                          {props.legend && props.legend.map((legendItem: any, index: number) => {
                            const name = legendItem.name;
                            const color = props.data.datasets[index].color(1);
                            return <View key={index}><Legend fontColor={ChartStyles.chartTitle.color} key={name} name={name} color={color} /></View>;
                          })}
                        </ScrollView>
                      </View>
                      <ScrollView horizontal>
                        <VictoryChart
                          width={screenWidth}
                          
                          containerComponent={
                            <VictoryVoronoiContainer
                              labels={({ datum }) => `${datum.x}, ${datum.y}, ${datum.key}`}
                            />
                          }
                          domainPadding={{ x: 50, y: 20 }} // Add padding to avoid clipping
                          domain={{ y: [0, Math.max(...transformedDatasetsAll.flatMap((dataset:any) => dataset.data.map((d:any) => d.y))) + 10] }} // Ensure y-domain covers the highest point
                        >
                          {/* Add the VictoryAxis with rotated tick labels */}
                          <VictoryAxis
                            tickLabelComponent={<VictoryLabel angle={45} />}
                            style={{
                              tickLabels: {
                                fontSize: 10, // You can adjust the font size as well
                                padding: 10, // Add some padding to avoid cutting off the labels
                              },
                            }}
                          />

                          <VictoryGroup
                            style={{
                              data: {
                                fillOpacity: 0.3,
                              },
                            }}
                          >
                            {transformedDatasetsAll.map((dataset:any, index:number) => (
                              <VictoryArea
                                key={index}
                                name={`area-${index}`}
                                //interpolation="bundle"
                                interpolation="natural"
                                data={dataset.data}
                                animate={{
                                  duration: 3000,
                                  onLoad: { duration: 3000 },
                                }}
                                style={{
                                  data: {
                                    stroke: props.data.datasets[index].color(1),
                                    fill: props.data.datasets[index].color(1),
                                  },
                                }}
                              />
                            ))}
                          </VictoryGroup>
                        </VictoryChart>
                      </ScrollView>

                    </BlurView>
                  </View>
                </Modal>
              </View>
            </ScrollView>
          </View>
      }
    </View>
  );
}