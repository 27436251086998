import * as React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Breadcrumb from './Unused/BreadcrumbNavigator/Breadcrumb';
import UserDetails from './Unused/UserDetails';

const Header: React.FC = ({ }) => {

    return (
        <View style={styles.container}>
            <View style={styles.breadCrumbContainer}>
                <Breadcrumb />
            </View>
            {/* <View style={styles.imageContainer}>
                <Image style={styles.tinyLogo} source={require('../assets/logo.png')}></Image>
            </View><View style={styles.userDetailContainer}>
                
            </View> */}
            </View>

    );
};


const styles = StyleSheet.create({
    container: {
        gap: 20,        
    },
    tinyLogo: {
        width: 100,
        height: 15,
    },

    imageContainer: {
        alignSelf: 'center'
        // alignItems: 'center',
        // flexGrow: 1
    },
    userDetailContainer: {
        alignSelf: 'center'
        // flexGrow: 1
    },
    breadCrumbContainer: {
       // padding: 10,
        backgroundColor:'#fff',
        width:'100%',
        //backgroundColor:'#fff',
        alignSelf: 'flex-start',
        //   flexGrow: 1
    },


});
export default Header;