import React, { useEffect } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { RootStackParams } from './Home';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { useNavigation } from '@react-navigation/core';
import { useNav } from '../providers/NavProvider';
import { useClient } from '../providers/ClientProvider';
import HomeDashboard from './Logs/Dashboard/Dashboard';


export interface ISitee {
  id: string;
  title: string;
  url: string;
  parentId?: string;
  type: string;
  path: string;
  children?: ISitee[];
  permissions?: string[];
  permissionType?: string;
  description:string;
  bannerImage:string;
  category?:string;
  hidden?:boolean
}


export interface IGroupedSites{
  title:string;
  sites:ISitee[];
}
type Props = NativeStackScreenProps<RootStackParams, "Client">



const ClientLanding: React.FC<Props> = ({route}) => {
  const { sitesResp, setCurrentLeafObj } = useNav();
  const { clientObj, setCurrentClientShortURL } = useClient();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParams>>();
  const { clientName, clientAPIURL } = useClient();
  useFocusEffect(() => {
    if (sitesResp) {
      setCurrentLeafObj(sitesResp);
      //navigation.replace("Site", { sitePath: sitesResp && sitesResp.path, clientShortURL: clientObj.shortURL })
    }
  });

  useEffect(() => {
    setCurrentClientShortURL(route.params && route.params.clientShortURL);
    const fetchData = async () => {
      const dataToSet: any = sitesResp; //(sitesResp && sitesResp.children) && sitesResp.children[0].children && sitesResp.children[0].children;
      //navigation.popToTop();
      setCurrentLeafObj(dataToSet);
      //navigation.replace("Site", { sitePath: sitesResp && sitesResp.path, clientShortURL: clientObj.shortURL })

    };
    if (sitesResp) {
      fetchData();
    }
  }, [sitesResp]);

  const ContinueToSite = () =>{
    if(sitesResp){
    navigation.navigate("Site", { sitePath: sitesResp && sitesResp.path, clientShortURL: route.params.clientShortURL })
    }
  }

  return (
    <View>
      {/* <View style={styles.container}>        
        <Text>{route.params.clientObj.name}</Text>
        <Sites sites={sites} clientObj={route.params.clientObj} ></Sites>
      </View> */}
      { clientAPIURL && <HomeDashboard clientAPIURL={clientAPIURL} Source={'Client'} ContinueToSite={ContinueToSite} sitesResp={sitesResp}/>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    gap: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly'


  }
});

export default ClientLanding