import React from 'react';
import { Button } from 'react-native-paper';
import ControlStyles from './ControlStyles';
interface Props{
    icon?:string;
    mode?:string;
    onPress: () => void;
    disabled?: boolean;
    Title: string;
    style?:any;
}
export default function ButtonControl(props: any) {

    const bgColor = (props.style && props.style.backgroundColor) ? props.style.backgroundColor:'#4BB75F'
    return (
        <Button
            icon={props.icon}
            mode={props.mode}
            onPress={props.onPress}
            disabled={props.disabled}
            style={[props.style,{borderRadius:2, backgroundColor:props.disabled ? '#676767':bgColor, fontWeight: '600'}]}
        >
            {props.Title}

        </Button>
    )
}