import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
interface LegendProps {
    name: string;
    color: string;
    fontColor?:string;
  }
  export default function Legend (props: LegendProps) {
  return (
    <View style={styles.legendItem}>
      <View style={[styles.legendColor, { backgroundColor: props.color }]} />
      <Text style={[styles.legendText, {color:props.fontColor}]}>{props.name}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15, // Adjust the spacing between legend items
  },
  legendColor: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 5,
  },
  legendText: {
    fontSize: 14,
  },
});


