import { Platform } from 'react-native';
import nativeStorage from './nativeStorage';
import webStorage from './webStorage';

const platformStorage = () => {
  switch (Platform.OS) {
    case 'web':
      return webStorage;
    default:
      return nativeStorage;
  }
};

export const setStringAsync = (key: string, value: string): Promise<void> =>
  platformStorage().setStringAsync(key, value);

export const getStringAsync = (key: string): Promise<string | null> =>
  platformStorage().getStringAsync(key);

export const deleteStringAsync = (key: string): Promise<void> =>
  platformStorage().deleteStringAsync(key);