import React, { useState, useEffect } from 'react';
import { View, StyleSheet, LogBox, ScrollView } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10 } from 'd3-scale-chromatic'
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { Loader } from '../../Common/Loader';
import { colorType3, colorType5, logStyles } from './LogStyles';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import LogCountCard from './LogCountCard';
interface Props {
  siteId: string;
  logTitle: string;
}
export default function RegionalMaterial(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);

  const [LogData, setLogData] = useState<null | any>(null);
  const [LogColumns, setLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {

        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
        setLogData(json.items);
        setLogColumns(json.columns);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return LogData && LogColumns ? <CreateNoiseLogCharts setLogData={setLogData} LogData={LogData} LogColumns={LogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : <Loader />;
}
interface CreateNoiseLogChartsProps {
  LogData: any;
  LogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setLogData: any;
}

const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({ setLogData, LogData, LogColumns, siteId, logTitle, clientAPIURL }) => {

  const { getAccessToken } = useAuth();

  const ColumnsWithIDColumn = LogColumns && [
    ...LogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const updatedData = LogData.map((item: any) => {
    const key = item.ID;
    return { ...item, key };
  });


  const pieData: any = {};
  updatedData.forEach((item: any) => {
    const csi = item.CSI;
    if (pieData[csi]) {
      pieData[csi] += 1;
    } else {
      pieData[csi] = 1;
    }
  });
  const colorScale = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byCSITypePieData = Object.keys(pieData).map((csi, index) => ({
    name: csi,
    value: pieData[csi],
    color: colorType5(index.toString()),//colorScale(pieData[csi] / Math.max(...Object.values(pieData) as number[])),
  }));

  const pieData2: any = {};
  updatedData.forEach((item: any) => {
    const csi = item.CSI;
    const materialValue = item.MaterialValue || 0;
    if (pieData2[csi]) {
      pieData2[csi] += materialValue;
    } else {
      pieData2[csi] = materialValue;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byCSIMaterialValuePieData = Object.keys(pieData2).map((csi, index) => ({
    name: csi,
    value: pieData2[csi],
    color: colorType3(index.toString()),//colorScale2(pieData2[csi] / Math.max(...Object.values(pieData2) as number[])),
  }));

  const [newData, setNewData] = useState<any[]>(updatedData);

  async function handleDataUpdate(item: any) {
    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const newItem = { ...result.items[0] }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }
  const TotalCount = newData.reduce((total, item) => {
    if (item.MaterialValue) {
      return total + item.MaterialValue;
    }
    return total;
  }, 0);
  return (
    <View style={logStyles.logRoot}>
      <TabsProvider defaultIndex={0}>
        <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
          <TabScreen label="Charts" icon="chart-bar" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
            <View style={[logStyles.container]} key={0}>
                <View style={logStyles.card}>
                  <LogCountCard cardTitle={'Total Regional Material Value'} number={Math.round(TotalCount)} />
                </View>
              </View>
              <View>
                <View style={[logStyles.container]}>
                  <View key={1} style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={byCSITypePieData} colorPallate={interpolateBlues} subTitle={'(%)'} ChartTitle={"CSI Type"} /></View>
                  <View key={2} style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={byCSIMaterialValuePieData} colorPallate={interpolateGnBu} subTitle={'(Regional Material (%) / CSI)'} ChartTitle={"Material Value"} /></View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
          {/* <TabScreen label="Tables" icon="table" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>

              <View>

              </View>
            </ScrollView>
          </TabScreen> */}
          <TabScreen label="List" icon="database" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>

              <View>
                <View style={logStyles.container}>
                  <View style={logStyles.cardTwo}>
                    {
                      setNewData &&
                      newData &&
                      ColumnsWithIDColumn && (
                        <PaperTableDynamic
                          data={newData}
                          columns={ColumnsWithIDColumn}
                          siteId={siteId}
                          logTitle={logTitle}
                          updateData={handleDataUpdate}
                          isConsolidatedTable={false}
                        />
                      )
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
        </Tabs>
      </TabsProvider>



    </View>
  );
};
