//dev
export class Constants {
    public static readonly AUTH_DISCOVERY_URI: string = 'https://keogreenport.b2clogin.com/keogreenport.onmicrosoft.com/B2C_1_SignIn/v2.0';
    public static readonly AUTH_CLIENT_ID: string = 'df25a87a-99c2-47eb-9283-66006a65887b';
    public static readonly AUTH_SCOPES: string[] = ['offline_access', 'https://keogreenport.onmicrosoft.com/61f00c03-85f6-4fb9-896b-668e19daf197/access_as_user'];
    public static readonly AUTH_SCHEME: string = 'com.keoic.greenport';
    public static readonly COMMON_API_ENDPOINT: string = 'https://dev-common-greenport.azurewebsites.net';

}


//prod
// export class Constants {
//     public static readonly AUTH_DISCOVERY_URI: string = 'https://keogreenport.b2clogin.com/keogreenport.onmicrosoft.com/B2C_1_SignIn/v2.0';
//     public static readonly AUTH_CLIENT_ID: string = 'df25a87a-99c2-47eb-9283-66006a65887b';
//     public static readonly AUTH_SCOPES: string[] = ['offline_access', 'https://keogreenport.onmicrosoft.com/61f00c03-85f6-4fb9-896b-668e19daf197/access_as_user'];
//     public static readonly AUTH_SCHEME: string = 'com.keoic.greenport';
//     public static readonly COMMON_API_ENDPOINT: string = 'https://dev-common-greenport.azurewebsites.net';

// }

