import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import LineChartScreen from '../common/charts/lineChart-builder';
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import CustomDT from '../common/controls/customDataTable';
import { Loader } from '../../Common/Loader';
import { colorType5, logStyles } from './LogStyles';
import LogFilter from './LogFilterDropdown';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import LogCountCard from './LogCountCard';
interface Props {
  siteId: string;
  logTitle: string;
}
export default function VibrationMonitoring(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);
  const [vibrationLogData, setVibrationLogData] = useState<null | any>(null);
  const [vibrationLogColumns, setVibrationLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
        setVibrationLogData(json.items);
        setVibrationLogColumns(json.columns);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return vibrationLogData && vibrationLogColumns ? <CreateNoiseLogCharts setVibrationLogData={setVibrationLogData} vibrationLogData={vibrationLogData} vibrationLogColumns={vibrationLogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : <Loader />;
}
interface CreateNoiseLogChartsProps {
  vibrationLogData: any;
  vibrationLogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setVibrationLogData: any;
}

const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({ setVibrationLogData, vibrationLogData, vibrationLogColumns, siteId, logTitle, clientAPIURL }) => {

  const { getAccessToken } = useAuth();
  const ColumnsWithIDColumn = vibrationLogColumns && [
    ...vibrationLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const sortedData = [...vibrationLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    let conformity = "";
    if (item.MonitoringResultsMaxPPVmms != "") {
      conformity = item.MonitoringResultsMaxPPVmms <= item.ComplianceLimitmms ? "Conformity" : "Non Conformity";
    }

    const Conformity = conformity
    const pieKey = Conformity;
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Conformity, Period, key, Date, pieKey };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }
  function handleYearFilterSelect(val: string) {
    let filteredRows;
    if (val === 'ALL') {
      filteredRows = updatedData; // No filter applied
    } else {
      filteredRows = updatedData.filter((item: any) => item.Year === val);
    }
    setNewData(filteredRows);
    setSelectedYear(val);
  }
  async function handleDataUpdate(item: any) {
    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const newItem = { ...result.items[0], Date }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }
  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  const lineChartConformityData: number[] = [];
  const lineChartNonConformityData: number[] = [];

  uniquePeriod.forEach((period) => {
    const filteredDataConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Conformity').length;
    const filteredDataNonConf = newData
      .filter((item) => item.Period === period)
      .filter((item) => item.Conformity === 'Non Conformity').length;

    lineChartConformityData.push(filteredDataConf);
    lineChartNonConformityData.push(filteredDataNonConf);
  });

  const lineChartData = {
    Line1: lineChartConformityData,
    Line2: lineChartNonConformityData
  };

  const colorScaleLine1 = scaleOrdinal(schemeCategory10);
  const datasets = Object.values(lineChartData).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine1(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData1 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets
  };
  const conformityTypes = new Set();
  conformityTypes.add('Conformity');
  conformityTypes.add('Non Conformity');

  const legendData1 = [...conformityTypes].map((type: any, index: any) => ({
    name: type,
  }));




  const periods = [...new Set(newData.map(item => item.Period))];

  const filteredDataByPeriod = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const TotalConfirmity: any = filteredItems.filter(item => item.Conformity === "Conformity").length;
    const TotalNonConfirmity: any = filteredItems.filter(item => item.Conformity === "Non Conformity").length;
    const itemCount = filteredItems.length;//parseFloat(TotalConfirmity) + parseFloat(TotalNonConfirmity);
    const sumMonitoringResults = filteredItems.reduce((sum, item) => sum + item.MonitoringResultsMaxPPVmms, 0);
    const Average = sumMonitoringResults / itemCount;
    const Maximum = Math.max(...filteredItems.map(item => item.MonitoringResultsMaxPPVmms));
    const Lower = 3;
    const Higher = 10;
    const ConformityPercentage = ((parseFloat(TotalConfirmity) / (parseFloat(TotalConfirmity) + parseFloat(TotalNonConfirmity))) * 100).toFixed(2)
    const NonConformityPercentage = ((parseFloat(TotalNonConfirmity) / (parseFloat(TotalConfirmity) + parseFloat(TotalNonConfirmity))) * 100).toFixed(2)

    return { "Period": Period, "Number Of Results": itemCount, "Average": Average, "Maximum": Maximum, "Lower": Lower, "Higher": Higher, "Confirmity": TotalConfirmity, "Confirmity Percentage": ConformityPercentage, "Non Conformity": TotalNonConfirmity, "Non Confirmity Percentage": NonConformityPercentage };
  });
  const columnKeys = filteredDataByPeriod[0] && Object.keys(filteredDataByPeriod[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  });


  const pieDataTotal: any = {};
  updatedData.forEach((item: any) => {
    const Key = item.Conformity;
    const quantitym3 = 1 || 0;

    if (pieDataTotal[Key]) {
      pieDataTotal[Key] += 1;
    } else {
      pieDataTotal[Key] = 1;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const byConformityPieData = Object.keys(pieDataTotal).map((key, index) => ({
    name: key,
    value: pieDataTotal[key],
    color: colorType5(index.toString()),//colorScale2(pieDataTotal[key] / Math.max(...Object.values(pieDataTotal) as number[])),
  }));

  const lineChartDataMax: number[] = [];
  const lineChartDataAverage: number[] = [];

  uniquePeriod.forEach((period) => {
    const periodLength = newData.filter((item) => item.Period === period).length;
    // const filteredDataSum = newData
    //   .filter((item) => item.Period === period)
    //   .reduce((accumulator, currentValue) => accumulator + currentValue.MonitoringResultsMaxPPVmms, 0);
    const filteredDataMax = newData
      .filter((item) => item.Period === period)
      .reduce((maxValue, currentValue) => Math.max(maxValue, currentValue.MonitoringResultsMaxPPVmms), 0);

    const filteredDataAverage = newData
      .filter((item) => item.Period === period)
      .reduce((accumulator, currentValue) => accumulator + currentValue.MonitoringResultsMaxPPVmms, 0) / periodLength;

    lineChartDataMax.push(filteredDataMax);
    lineChartDataAverage.push(filteredDataAverage);
  });

  const lineChartDataLevel = {
    Line1: lineChartDataMax,
    Line2: lineChartDataAverage
  };

  const colorScaleLine3 = scaleOrdinal(schemeCategory10);
  const datasets3 = Object.values(lineChartDataLevel).map((values, index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine3(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataLevelChart = {
    labels: uniquePeriod,
    datasets: datasets3
  };
  const BaseLineTypes = new Set();
  BaseLineTypes.add('Maximum');
  BaseLineTypes.add('Average');
  const legendData2 = [...BaseLineTypes].map((type: any, index: any) => ({
    name: type,
  }));


  const uniqueYears = uniqueYearsArray && uniqueYearsArray.filter(item => item !== null).sort((a: any, b: any) => b - a);
  const yearFilterOptions = [
    {
      label: 'ALL',
      value: 'ALL',
    },
    ...new Set(uniqueYears.map((item) => {
      return {
        label: item,
        value: item,
      };
    }))]
  const TotalCount = newData.length;
  return (
    <View style={logStyles.logRoot}>
      <TabsProvider defaultIndex={0}>
        <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
          <TabScreen label="Charts" icon="chart-bar" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View style={[logStyles.container]} key={0}>
                <LogCountCard cardTitle={'Total Vibration Monitoring Results'} number={Math.round(TotalCount)} />
                <View style={[logStyles.card, { justifyContent: 'center' }]}>
                  <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                </View>
                <View style={[logStyles.card]}></View>
              </View>
              <View>
                <View style={logStyles.container}>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={byConformityPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative Data)'} ChartTitle={"Vibration Results"} /></View>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData1} label={uniquePeriod} subTitle={'(Monthly Data)'} ChartTitle={"Vibration Results"} /></View>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineDataLevelChart} label={uniquePeriod} subTitle={'(Evening Data)'} ChartTitle={"Total"} /></View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
          <TabScreen label="Tables" icon="table" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View style={[logStyles.container]} key={0}>
                <View style={logStyles.cardThreeFilter}>
                  <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                </View>
              </View>
              <View>
                <View style={logStyles.container}>
                  <View style={logStyles.cardThree}>
                    { (
                      <CustomDT
                        data={filteredDataByPeriod}
                        columns={columnKeys}
                        siteId={siteId}
                        logTitle={logTitle}
                        tableTitle={"Vibration Monitoring"}
                      />
                    )
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
          <TabScreen label="List" icon="database" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              {showFilter === true ?
                <View style={[logStyles.container]} key={0}>
                  <View style={logStyles.cardTwoFilter}>
                    <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                  </View>
                </View> : null
              }
              <View>
                <View style={logStyles.container}>
                  <View style={logStyles.cardThree}>
                    {
                      setNewData &&
                      newData &&
                      uniqueYearsArray &&
                      ColumnsWithIDColumn && (
                        <PaperTableDynamic
                          data={newData}
                          columns={ColumnsWithIDColumn}
                          siteId={siteId}
                          logTitle={logTitle}
                          updateData={handleDataUpdate}
                          isConsolidatedTable={false}
                          funcSetShowFilter={setShowFilter}
                        />
                      )
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
        </Tabs>
      </TabsProvider>
    </View>
  );
};

