import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox, Dimensions, Modal } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import { useAuth } from '../../../providers/AuthProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { Loader } from '../../Common/Loader';
import { colorType3, colorType5, logStyles } from '../LogControllers/LogStyles';
import GroupBarChartsComp from '../common/charts/barChart-builder-Grouped';
import ConsolLogCountCard from './ConsolLogCountCard';
interface LogProps {
  logTitle: string;
  accessToken: string;
  clientAPIURL: string;
  filterVal?:number;
}
export const DbTrainingLog: React.FC<LogProps> = ({ logTitle, accessToken, clientAPIURL, filterVal }) => {
  const [trainingLogData, setLogData] = useState<null | any>(null);
  const [trainingLogColumns, setLogColumns] = useState<null | any>(null);;
  useEffect(() => {
    async function fetchData() {
      try {
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return trainingLogData === null || trainingLogColumns === null ? <Loader />
    :
    trainingLogData && trainingLogColumns ? <CreateLogCharts filterVal={filterVal} setTrainingLogData={setLogData} trainingLogData={trainingLogData} trainingLogColumns={trainingLogColumns} logTitle={logTitle} clientAPIURL={clientAPIURL} /> : null;
}
export default DbTrainingLog;

interface CreateLogChartsProps {
  trainingLogData: any;
  trainingLogColumns: any;
  logTitle: string;
  clientAPIURL: string;
  setTrainingLogData: any;
  filterVal?:number;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({filterVal, setTrainingLogData, trainingLogData, trainingLogColumns, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const { getAccessToken } = useAuth();
  const ColumnsWithIDColumn = trainingLogColumns && [
    ...trainingLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  //const sortedData = trainingLogData//[...trainingLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const currentDate = new Date();  // Get the current date
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - filterVal);  // Calculate the date 30 days ago

    const dbFilteredData = filterVal===0? trainingLogData : trainingLogData.filter((item:any) => {
      // Assuming item.Date is a JavaScript Date object
      const itemDate = item.Date && new Date(item.Date);
      return itemDate && itemDate >= thirtyDaysAgo && itemDate <= currentDate;
    });
  const updatedData = dbFilteredData.map((item: any) => {
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    return { ...item, Period, key, Date };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");

  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function checkReused(div: string) {
    switch (div) {
      case "Reuse on-site": return "Reuse";
      case "Reuse off-site": return "Reuse";
      case "Temporary storage for future reuse": return "Reuse";

      case "Disposal off-site": return "Not Reused";
      case "Landfill": return "Not Reused";
      case "Slurry lagoon": return "Not Reused";
      case "Other": return "Not Reused";
      default: return null
    }
  }

  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  /**
   * Creating Line Chart 1
   */
  const colorScaleLine1 = scaleOrdinal(schemeCategory10);
  const categoryValues = [...new Set(newData.map((item: any) => item.Category))].filter(type => type !== null);
  const conformityData: any = {};

  categoryValues.forEach((category) => {
    conformityData[category] = [];

    uniquePeriod.forEach((period) => {
      const filteredData = newData
        .filter((item) => item.Period === period)
        .filter((item) => item.Category === category)
        .length;

      conformityData[category].push(filteredData);
    });
  });

  const datasets = Object.entries(conformityData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine1(index) + Math.round(opacity * 255).toString(16),
  }));

  const lineData1 = {
    labels: [...new Set(newData.map((item: any) => item.Period))],
    datasets,
  };
  const legendData1 = [...categoryValues].map((type: any, index: any) => ({
    name: type,
  }));



  /**
   * Creating BarChart 1
   */

  const colorScaleBar1 = scaleOrdinal(schemeCategory10);
  const TrainingData: any = {};
  TrainingData["Trainings"] = [];
  uniquePeriod.forEach((period) => {
    const filteredData = newData
      .filter((item) => item.Period === period)
      .length;

    TrainingData["Trainings"].push(filteredData);
  });

  const BarDatasets1 = Object.entries(TrainingData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleBar1(index) + Math.round(opacity * 255).toString(16),
  }));

  const BarData1 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: BarDatasets1,
  };



  /**
   * Creating BarChart 2
   */
  const isValidNumber = (value: number) => {
    return typeof value === 'number' && !isNaN(value) && isFinite(value);
  };
  const colorScaleBar2 = scaleOrdinal(schemeCategory10);
  const AttendeesData: any = {};
  AttendeesData["Attendees"] = [];
  uniquePeriod.forEach((period) => {
    const filteredData = newData
      .filter((item) => item.Period === period)
      .reduce((acc, item) => isValidNumber(item.NoOfAttendees) ? acc + item.NoOfAttendees : acc, 0);

    AttendeesData["Attendees"].push(filteredData);
  });

  const BarDatasets2 = Object.entries(AttendeesData).map(([key, values], index: any) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleBar2(index) + Math.round(opacity * 255).toString(16),
  }));

  const BarData2 = {
    labels: [...new Set(updatedData.map((item: any) => item.Period))],
    datasets: BarDatasets2,
  };

  /**
   * Pie Chart Jan
   */
  const pieDataJanTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("01") || item.Period.includes("Jan")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataJanTrainingSubject[Key]) {
      pieDataJanTrainingSubject[Key] += 1;
    } else {
      pieDataJanTrainingSubject[Key] = 1;
    }
  });
  const colorScaleJan = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const JanTrainingSubjectPieData = Object.keys(pieDataJanTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataJanTrainingSubject[key],
    color: colorType5(index.toString()),//colorScaleJan(pieDataJanTrainingSubject[key] / Math.max(...Object.values(pieDataJanTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart Feb
   */
  const pieDataFebTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("02") || item.Period.includes("Feb")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataFebTrainingSubject[Key]) {
      pieDataFebTrainingSubject[Key] += 1;
    } else {
      pieDataFebTrainingSubject[Key] = 1;
    }
  });
  const colorScaleFeb = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const FebTrainingSubjectPieData = Object.keys(pieDataFebTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataFebTrainingSubject[key],
    color: colorType3(index.toString()),//colorScaleFeb(pieDataFebTrainingSubject[key] / Math.max(...Object.values(pieDataFebTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart Mar
   */
  const pieDataMarTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("03") || item.Period.includes("Mar")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataMarTrainingSubject[Key]) {
      pieDataMarTrainingSubject[Key] += 1;
    } else {
      pieDataMarTrainingSubject[Key] = 1;
    }
  });
  const colorScaleMar = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const MarTrainingSubjectPieData = Object.keys(pieDataMarTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataMarTrainingSubject[key],
    color: colorType5(index.toString()),//colorScaleMar(pieDataMarTrainingSubject[key] / Math.max(...Object.values(pieDataMarTrainingSubject) as number[])),
  }));
  /**
   * Pie Chart Apr
   */
  const pieDataAprTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("04") || item.Period.includes("Apr")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataAprTrainingSubject[Key]) {
      pieDataAprTrainingSubject[Key] += 1;
    } else {
      pieDataAprTrainingSubject[Key] = 1;
    }
  });
  const colorScaleApr = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const AprTrainingSubjectPieData = Object.keys(pieDataAprTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataAprTrainingSubject[key],
    color: colorType5(index.toString()),//colorScaleApr(pieDataAprTrainingSubject[key] / Math.max(...Object.values(pieDataAprTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart May
   */
  const pieDataMayTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("05") || item.Period.includes("May")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataMayTrainingSubject[Key]) {
      pieDataMayTrainingSubject[Key] += 1;
    } else {
      pieDataMayTrainingSubject[Key] = 1;
    }
  });
  const colorScaleMay = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const MayTrainingSubjectPieData = Object.keys(pieDataMayTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataMayTrainingSubject[key],
    color: colorType3(index.toString()),//colorScaleMay(pieDataMayTrainingSubject[key] / Math.max(...Object.values(pieDataMayTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart Jun
   */
  const pieDataJunTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("06") || item.Period.includes("Jun")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataJunTrainingSubject[Key]) {
      pieDataJunTrainingSubject[Key] += 1;
    } else {
      pieDataJunTrainingSubject[Key] = 1;
    }
  });
  const colorScaleJun = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const JunTrainingSubjectPieData = Object.keys(pieDataJunTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataJunTrainingSubject[key],
    color: colorType5(index.toString()),//colorScaleJun(pieDataJunTrainingSubject[key] / Math.max(...Object.values(pieDataJunTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart Jul
   */
  const pieDataJulTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("07") || item.Period.includes("Jul")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataJulTrainingSubject[Key]) {
      pieDataJulTrainingSubject[Key] += 1;
    } else {
      pieDataJulTrainingSubject[Key] = 1;
    }
  });
  const colorScaleJul = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const JulTrainingSubjectPieData = Object.keys(pieDataJulTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataJulTrainingSubject[key],
    color: colorType5(index.toString()),//colorScaleJul(pieDataJulTrainingSubject[key] / Math.max(...Object.values(pieDataJulTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart Aug
   */
  const pieDataAugTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("08") || item.Period.includes("Aug")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataAugTrainingSubject[Key]) {
      pieDataAugTrainingSubject[Key] += 1;
    } else {
      pieDataAugTrainingSubject[Key] = 1;
    }
  });
  const colorScaleAug = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const AugTrainingSubjectPieData = Object.keys(pieDataAugTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataAugTrainingSubject[key],
    color: colorType3(index.toString()),//colorScaleAug(pieDataAugTrainingSubject[key] / Math.max(...Object.values(pieDataAugTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart Sep
   */
  const pieDataSepTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("09") || item.Period.includes("Sep")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataSepTrainingSubject[Key]) {
      pieDataSepTrainingSubject[Key] += 1;
    } else {
      pieDataSepTrainingSubject[Key] = 1;
    }
  });
  const colorScaleSep = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const SepTrainingSubjectPieData = Object.keys(pieDataSepTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataSepTrainingSubject[key],
    color: colorType5(index.toString()),//colorScaleSep(pieDataSepTrainingSubject[key] / Math.max(...Object.values(pieDataSepTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart Oct
   */
  const pieDataOctTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("10") || item.Period.includes("Oct")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataOctTrainingSubject[Key]) {
      pieDataOctTrainingSubject[Key] += 1;
    } else {
      pieDataOctTrainingSubject[Key] = 1;
    }
  });
  const colorScaleOct = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const OctTrainingSubjectPieData = Object.keys(pieDataOctTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataOctTrainingSubject[key],
    color: colorType5(index.toString()),//colorScaleOct(pieDataOctTrainingSubject[key] / Math.max(...Object.values(pieDataOctTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart Nov
   */
  const pieDataNovTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("11") || item.Period.includes("Nov")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataNovTrainingSubject[Key]) {
      pieDataNovTrainingSubject[Key] += 1;
    } else {
      pieDataNovTrainingSubject[Key] = 1;
    }
  });
  const colorScaleNov = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const NovTrainingSubjectPieData = Object.keys(pieDataNovTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataNovTrainingSubject[key],
    color: colorType3(index.toString()),//colorScaleNov(pieDataNovTrainingSubject[key] / Math.max(...Object.values(pieDataNovTrainingSubject) as number[])),
  }));

  /**
   * Pie Chart Dec
   */
  const pieDataDecTrainingSubject: any = {};
  newData.filter((item) => item.Period.includes("12") || item.Period.includes("Dec")).forEach((item: any) => {
    const Key = item.TrainingSubject;
    if (pieDataDecTrainingSubject[Key]) {
      pieDataDecTrainingSubject[Key] += 1;
    } else {
      pieDataDecTrainingSubject[Key] = 1;
    }
  });
  const colorScaleDec = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const DecTrainingSubjectPieData = Object.keys(pieDataDecTrainingSubject).map((key, index) => ({
    name: key,
    value: pieDataDecTrainingSubject[key],
    color: colorType5(index.toString()),//colorScaleDec(pieDataDecTrainingSubject[key] / Math.max(...Object.values(pieDataDecTrainingSubject) as number[])),
  }));


  /**
   * Custom Table 1
   */

  const periods = [...new Set(newData.map(oItem => oItem.Period))];
  const filteredDataByPeriod = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const itemCount = filteredItems.length;//parseFloat(TotalHaz.length) + parseFloat(TotalNonHaz.length);
    const sumAttendees = filteredItems.reduce((sum, item) => sum + item.NoOfAttendees, 0);
    return { Period, "No. of Trainings": itemCount, "Attendees": sumAttendees };
  });
  const transformedDataTable1 = Object.values(filteredDataByPeriod).map((values: any) => { return { ...values } });
  const combinedData1: { [key: string]: { [key: string]: number } } = {};
  filteredDataByPeriod.forEach(item => {
    const { Period, ...rest } = item;
    combinedData1[Period] = { ...combinedData1[Period], ...rest };
  });
  const mergedData1 = Object.entries(combinedData1).map(([Period, values]) => ({
    Period,
    ...values
  }));
  const columnKeys1 = mergedData1.length > 0 ? Object.keys(mergedData1[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    }
  }) : [];



  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
  const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
  const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");

  function handleYearFilterSelect(val: string) {
    setSelectedYear(val);
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handleMonthFilterSelect(val: string) {
    setSelectedMonth(val);
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  const TrainingCount = updatedData.length;
  const TotalCount = updatedData.reduce((total:any, item:any) => {
    if (isValidNumber(item.NoOfAttendees)) {
      return total + item.NoOfAttendees;
    }
    return total;
  }, 0);
  return (
    <View>
      <View style={[{ justifyContent: 'center', alignItems: 'center' }]}>
        <View style={{ flexDirection: 'row', gap:10}}>
          <ConsolLogCountCard cardTitle={'Total Attendees'} number={Math.round(TrainingCount)} />
          <ConsolLogCountCard cardTitle={'Total Trainings'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <View style={CommonStyles.container}>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}>
          <GroupBarChartsComp data={BarData1} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Number of Trainings"} />
        </View>
        {/* <View style={[CommonStyles.card, { backgroundColor: '' }]}>
          <GroupBarChartsComp data={BarData2} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Number of Attendees"} />
        </View> */}
        {/* <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData1} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Training Category"} /></View>

        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={JanTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Jan"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={FebTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Feb"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={MarTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Mar"} /></View>

        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={AprTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Apr"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={MayTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - May"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={JunTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Jun"} /></View>

        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={JulTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Jul"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={AugTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Aug"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={SepTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Sep"} /></View>

        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={OctTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Oct"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={NovTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Nov"} /></View>
        <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={DecTrainingSubjectPieData} colorPallate={interpolateBlues} ChartTitle={"Training Subjects % - Dec"} /></View> */}
      </View>
    </View>
  );
};


