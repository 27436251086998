
import { scaleOrdinal } from 'd3-scale';
import { schemeAccent, schemeCategory10, schemePaired, schemeSet2, schemeSet3 } from 'd3-scale-chromatic';
import { StyleSheet, Platform } from 'react-native';
const logStyles = StyleSheet.create({
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    borderRadius: 16,
  },
  scrollViewContentContainer:{
    paddingBottom:Platform.OS!=='web'?250:20,   
  },
logRoot:{
  height:'100%'
},
  container: {
    flex: 1,
    gap: 20,
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding:10,
  },
  card: {
    maxWidth: 500,
    minWidth: 300,
    marginVertical: 8,
    minHeight: 100,
    width: '100%',
    //height:'100%',
    
  },
  cardTwo: {
    maxWidth: Platform.OS === 'web' ? '70%' : '100%', minWidth: 300, marginVertical: 8, minHeight: 100, width: '100%'
  },
  cardThree: {
    maxWidth: Platform.OS === 'web' ? '90%' : '100%', minWidth: 300, marginVertical: 8, minHeight: 100, width: '100%'
  },
  cardTwoFilter:{
    maxWidth: Platform.OS === 'web' ? '70%' : '100%', minWidth: 300, marginVertical: 8, minHeight: 100, width: '100%'
  },
  cardThreeFilter:{
    maxWidth: Platform.OS === 'web' ? '90%' : '100%', minWidth: 300, marginVertical: 8, minHeight: 100, width: '100%'
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: 'silver',
    marginBottom: 20,
  },
  pieChart: {

  },
  barChart: {

  },
  lineChart: {

  },
  dataTable: {

  },
});
const colorType1 = scaleOrdinal(schemeAccent);
  const colorType2 = scaleOrdinal(schemeSet2);
  const colorType3 = scaleOrdinal(schemePaired);
  const colorType4 = scaleOrdinal(schemeCategory10);
  const colorType5 = scaleOrdinal(schemeSet3);

export { logStyles, colorType1, colorType2, colorType3, colorType4, colorType5 }