import React, { useEffect, useRef, useState } from 'react';
import { View, StyleSheet, ImageBackground, FlatList, Animated, Platform, ScrollView, Pressable, Modal } from 'react-native';
import { useClient } from '../providers/ClientProvider';
import { Card, FAB, Text } from 'react-native-paper';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
//import { DrawerNavigationProp, DrawerScreenProps } from '@react-navigation/drawer';
import { useFocusEffect } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/core';
import { RootStackParams } from './Home';
import { useNav } from '../providers/NavProvider';
import { IGroupedSites, ISitee } from './ClientLanding';
import { getObjectByPath, groupSitesByCategory, sortByOrder } from '../services/Utils';
import Header from './Header';
import AuthImage from './Common/AuthImage';
//import { ScrollView } from 'react-native-gesture-handler';
import { LinearGradient } from 'expo-linear-gradient';
import CardGroup from './CardGroup';
import HomeDashboard from './Logs/Dashboard/Dashboard';
type Props = NativeStackScreenProps<RootStackParams, "Site">

const Sitelanding: React.FC<Props> = ({ route }) => {
  const { setCurrentClientShortURL, clientObj, clientAPIURL } = useClient();
  const { sitesResp, setCurrentLeafObj } = useNav();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParams>>();
  const [siteObj, setSiteObj] = React.useState<ISitee>(null);
  const [groupedSites, setGroupedSites] = React.useState<IGroupedSites[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [rootSite, setRootSite] = useState(false)
  //const { clientName, clientAPIURL } = useClient();
  useFocusEffect(() => {
    if (siteObj) {
      setCurrentLeafObj(siteObj);
    }
  });

  React.useEffect(() => {
    setCurrentClientShortURL(route.params && route.params.clientShortURL && route.params.clientShortURL);
    if (sitesResp) {
      const tempSiteObj = (route.params && route.params.sitePath) ? getObjectByPath(decodeURIComponent(route.params.sitePath), sitesResp) : sitesResp;
      if (tempSiteObj) {
        // setCurrentLeafObj(tempsiteObj);
        setSiteObj(tempSiteObj);
        if (tempSiteObj && tempSiteObj.children && tempSiteObj.children.length > 0) {
          let groupedResults = groupSitesByCategory(tempSiteObj.children);
          setGroupedSites(groupedResults)
        }
        if(sitesResp.id && tempSiteObj.id){
          sitesResp.id === tempSiteObj.id ? setRootSite(true): setRootSite(false);
        }
      }
      else {
        navigation.replace('NotFoundSite', { clientShortURL: route.params.clientShortURL, sitePath: route.params.sitePath });
      }

    }
  }, [route.params && route.params.sitePath && route.params.sitePath, sitesResp, siteObj]);

 

  const cardClicked = (item: ISitee) => {
    setCurrentLeafObj(item);
    switch (item.type) {
      case 'site': navigation.push("Site", { sitePath: item.path, clientShortURL: clientObj.shortURL }); break;
      case 'list': navigation.push("List", { sitePath: item.path, clientShortURL: clientObj.shortURL }); break;
      case 'report': navigation.push("Report", { sitePath: item.path, clientShortURL: clientObj.shortURL }); break;


    }
    // item.type == 'site' ?
    //   : navigation.push("List", { sitePath: item.path, clientShortURL: clientObj.shortURL });

  }

  // const CardData = (siteObj && siteObj.children && siteObj.children.length > 0) && sortByOrder(siteObj.children).map((item, index) => {
  //   return {
  //     id: index,
  //     title: item.title,
  //     Path: item.path,
  //     Permissions: item.permissions,
  //     SubTitle: "Sample Description",
  //     itemObject: item
  //   };
  // })
  const [isReady, setIsReady] = useState(false);
  const opacityValue = new Animated.Value(0);
  const startAnimation = () => {
    Animated.timing(opacityValue, {
      toValue: 1,
      duration: 1000, // Duration of the animation in milliseconds
      useNativeDriver: true, // This is required for better performance on some devices
    }).start();
  };
  // useEffect(() => {
  //   startAnimation();
  // }, [siteObj]);
  siteObj && siteObj.children && siteObj.children.length > 0 && startAnimation();

  const goToDashboard = () =>{
    navigation.navigate("Client",{clientShortURL: clientObj.shortURL });
  }
  return (
    <View>
      <View>
        <Header></Header>
      </View>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        {/* {console.log('inside site')} */}
        <View style={styles.navWrapper}>

          {/* <ImageBackground source={require('./Common/wallpaper.webp')} style={[{ height: 900 }]}>
          <View style={styles.container}>
            {(siteObj && siteObj.children && siteObj.children.length > 0) && sortByOrder(siteObj.children).map((item, index) => (
              <Card key={index} style={[styles.card]} onPress={() => {
                cardClicked(item);
              }}>
                <Card.Title title={item.title}></Card.Title>
              </Card>
            ))
            }

          </View>
        </ImageBackground> */}
          {/* <ImageBackground source={require('./Common/wallpaper.webp')} style={{ flex: 1 }}> */}
          {(siteObj && siteObj.title) &&
            <View style={{ backgroundColor: '#102B27', width: "100%", padding: 10, alignItems: 'center' }}>
              <Text variant='titleLarge' style={{ color: '#fff' }} >{siteObj.title}</Text>
            </View>
          }
          {
            modalVisible === true && clientAPIURL?
              <ScrollView>
                {modalVisible &&
                  <View style={[styles.centeredView, styles.modalView]}>
                    {/* <Modal animationType="slide" transparent={true} visible={modalVisible}
                      onRequestClose={() => { setModalVisible(!modalVisible); }}>
                      <View>
                        <HomeDashboard clientAPIURL={clientAPIURL} setModalVisible={setModalVisible} modalVisible={modalVisible} />
                      </View>
                    </Modal> */}
                    <View>
                        <HomeDashboard clientAPIURL={clientAPIURL} setModalVisible={setModalVisible} modalVisible={modalVisible} />
                      </View>
                  </View>
                }
              </ScrollView>
              :
              <View style={styles.container}>
                <View style={styles.bannerContainer}>

                  {/* </View><View style={styles.bannerContainer}> */}
                  {(siteObj && siteObj.bannerImage) &&
                    <AuthImage imageFileName={siteObj.bannerImage} targetWidth={1000} />
                  }
                  <View>
                    {(siteObj && siteObj.description) &&
                      <Text variant="titleSmall" style={{ textAlign: 'justify', fontWeight: "700" }}>{siteObj.description}</Text>
                    }
                  </View>
                </View>

                {/* <View style={styles.tileContainer}> */}
                {/* {(siteObj && siteObj.children && siteObj.children.length > 0) &&

                sortByOrder(siteObj.children).map((item, index) => (
                  <Animated.View
                    key={index}
                    style={[styles.card, styles.box2, styles.cardTransformed, { opacity: opacityValue }]} // Apply the opacity value to the style
                  >

                    <Card
                      style={[{ flex: 1 }]}
                      onPress={() => {
                        cardClicked(item);
                      }}
                    >
                      <Card.Title title={item.title} />
                    </Card>
                  </Animated.View>
                ))} */}
                {
                  groupedSites.map((item, index) => {
                    return <CardGroup key={index} title={item.title} sites={item.sites} clickEvent={cardClicked} ></CardGroup>;
                  })


                }
                
                {
                  rootSite===true ?
                  <Pressable
                  style={[styles.button, styles.buttonClose]}
                  onPress={() => { goToDashboard() }}>
                  <Text style={styles.textStyle}>Show Dashboard</Text>
                </Pressable>:null}
              </View>
          }
        </View>
        
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  navWrapper: {},
  container: {
    flex: 1,
    padding: 16,
    gap: 20,
    flexDirection: "column",
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 100,
  },
  bannerContainer: {
    alignItems: 'center',
    gap: 20,
    padding: 5,
    minWidth: "90%",


  },
  tileContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',

    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "transparent",
    gap: 20,
  },
  card: {
    flex: 1,
    minWidth: 300,
    maxWidth: 300,
    marginVertical: 8,
    minHeight: 100,
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    padding: 16,
    // Shadow properties for iOS
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    // Elevation for Android
    elevation: 5,
    //display:'flex',
    justifyContent: 'center',
    //alignItems:'center',

  },
  box2: {
    shadowColor: 'rgba(0, 0, 0, 0.5)',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 20,
    //shadowColor: '#52006A',
  },
  // Apply the transform style when pressing the card
  cardTransformed: {
    transform: [{ translateY: 5 }],
    shadowColor: 'rgba(0, 0, 0, 0.25)',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 1,
    shadowRadius: 20,
  },
  cardContainer: {
    padding: 16,
  },
  itemContainer: {
    minWidth: 300,
    maxWidth: 300,
    marginVertical: 8,
    minHeight: 100,
    backgroundColor: '#e0e0e0',
    padding: 16,
    marginBottom: 8,
    borderRadius: 8,
  },
  itemText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#4BB75F',
  },
  buttonClose: {
    backgroundColor: '#4BB75F',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});

export default Sitelanding


