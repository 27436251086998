import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import Select from 'react-select';
interface Props {
    selected: string;
    label: string;
    options: Array<any>;
    onSelect: (val: string) => void;
}
const SingleSelectDropdown: React.FC<Props> = React.memo((props) => {
    const [selectedValue, setSelectedValue] = useState<string>(props.selected);
    function handleSelect(val:any) {
        setSelectedValue(val);
        props.onSelect(val.value);
    }
    const sharedInputStyles = {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
        shadowColor: 'blue',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.5,
        shadowRadius: 4,
        elevation: 4,
        minHeight: 60,
        maxHeight: 60,
      };
    return (
        props &&
        // <Dropdown
        //     label={props.selected ==="" ? props.label:""}
        //     //label={props.label}
        //     data={props.options}
        //     disableSort
        //     value={selectedValue}
        //     onChange={(val) => handleSelect(val)}
        //     selectedItemTextStyle={{ fontWeight: 'bold', color:'#ffffff' }}
        //     disableSelectionTick = {true}
        //     selectedItemViewStyle = {{backgroundColor:'#004876'}}
        //     //textInputStyle={styles.dropdown}
        //     mode="outlined"
        // />
        <Select
            options={props.options}
            isMulti={false} // Single select dropdown
            value={selectedValue} // Controlled component state
            onChange={(val:any) => handleSelect(val)}
            styles={{
                menu: (provided: any) => ({
                    ...provided,
                    //borderWidth: 0,
                    //backgroundColor: 'transparent',//'rgb(247, 243, 249)', // Set background color for the dropdown
                    //zIndex: 100, // Ensures the dropdown is on top of other elements
                }),
                control: (base: any) => ({
                    ...base,
                    sharedInputStyles,
                    //height: 40,
                    //minWidth:100,
                    //borderWidth: 0, // No border on control (input box)
                    boxShadow: 'none', // Remove shadow when focused
                    '&:hover': {
                        borderWidth: 0, // No border when hovered
                    },
                    '&:focus': {
                        borderWidth: 0, // No border on focus
                    },
                }),
                menuPortal: (provided: any) => ({
                    ...provided,
                    borderWidth: 0,
                    zIndex: 9999, // Ensures the dropdown portal is at the highest level
                }),
            }}
            menuPortalTarget={document.body}
        />
    );
})

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "transparent"
    },
    dropdown: {
        backgroundColor: "transparent",
        //marginVertical: 5,
        //marginBottom: 30,
        borderWidth: 1,
        height: 40
    },
    buttonView: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: 10,
    },

});
export { SingleSelectDropdown }