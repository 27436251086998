import { StyleSheet } from 'react-native';
const ControlStyles = StyleSheet.create({
    dtTitle: {
      //width: 100,
      flex: 1,
      //flexWrap: 'wrap',
      //borderWidth: 1,
      borderColor: '#DDDDDD',
      fontWeight: 'bold',
      color: '#FFFFFF',
      alignItems:'center',
      alignContent:'center',
      justifyContent:'center'
    },
    dtCell: {
      width: 100,
      flex: 2,
      flexWrap: 'wrap',
      //borderWidth: 1,
      borderColor: '#DDDDDD',
      alignItems:'center',
      alignContent:'center',
      justifyContent:'center'
    },
    dtCellText:{
      flexWrap:'wrap',
        padding:10,
    },
    dtHeaderText:{
      width:'auto',
        //fontFamily:"Avenir", 
        fontSize:14, 
        fontWeight:'bold',
        color: "#ffffff",
        flexWrap:'wrap',
        padding:10,
        //alignItems:'center',
        //alignContent:'center',
    },
    dtHeader:{
      //borderWidth: 1,
      flexDirection:'row',
      paddingHorizontal:16,
      borderBottomWidth:StyleSheet.hairlineWidth * 2,
      height:'auto',
      width:'auto',
      backgroundColor: '#102B27',//#2980B9',//rgba(0, 0, 128, 0.9)',
      alignItems:'center',
        alignContent:'center',
    },
    ribbonContainer:{
      flexDirection: 'row', 
      height: 60, 
      paddingTop:10,
      flex:1,
      gap: 20,
      //flexWrap: 'wrap',
    },
    ribbonScrollView:{},
    buttonContainer:{
      //width: 100,
      flex:1
    },
    buttonDivider:{
      width: 10
    },
    tableContainer:{
      flex:1,
      //borderWidth: 1, 
      borderColor: 'grey', 
      paddingTop:10
    },
    dtRow:{ 
      flex: 1, 
      width: '100%', 
      height: 50 
    },
    actionButton:
    {
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 1,
      shadowRadius: 10,
      elevation: 20,
    }
  });
  export default ControlStyles