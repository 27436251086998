import React, { useState, useEffect } from 'react';
import { View, StyleSheet, LogBox, Dimensions, Modal, ScrollView } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10 } from 'd3-scale-chromatic'
import PieChartComp from '../common/charts/piChart-builder';


import { scaleOrdinal, scaleSequential } from 'd3-scale';

import {
  filterData,
  handleFilterReset,
  handleExportClick,
  constructData,
  EnableExport,
  CommonStyles,
  GetConsolidatedLog,
  FilterChoices
} from './ConsolidatedController';
import { colorType3, colorType5, logStyles } from '../LogControllers/LogStyles';
import ConsolLogCountCard from './ConsolLogCountCard';
interface LogProps {
  logTitle: string;
  accessToken: string;
  clientAPIURL: string;
}
export const DbRecycledMaterial:React.FC<LogProps> = ({logTitle, accessToken, clientAPIURL }) => {
  const [LogData, setLogData] = useState<null | any>(null);
  const [LogColumns, setLogColumns] = useState<null | any>(null);
  useEffect(() => {
    async function fetchData() {
      try {
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  return LogData && LogColumns ? <CreateNoiseLogCharts setLogData={setLogData} LogData={LogData} LogColumns={LogColumns} logTitle={logTitle} clientAPIURL={clientAPIURL} /> : null;
}
export default DbRecycledMaterial;

interface CreateNoiseLogChartsProps {
  LogData: any;
  LogColumns: any;
  
  logTitle: string;
  clientAPIURL: string;
  setLogData: any;
}
const CreateNoiseLogCharts: React.FC<CreateNoiseLogChartsProps> = ({ setLogData, LogData, LogColumns, logTitle, clientAPIURL }) => {
  const [FilterVisible, setFilterVisible] = React.useState(false);
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
  const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const ColumnsWithIDColumn = LogColumns && [
    ...LogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const updatedData = LogData.map((item: any) => {
    const key = item.ID;
    const TotalValueOfRecycleContentMaterial = (item.MaterialValue * item.RecycledContentPostConsumer) + (item.MaterialValue * item.RecycledContentPreConsumer * 0.5);
    return { ...item, key, TotalValueOfRecycleContentMaterial };
  });


  const pieData: any = {};
  updatedData.forEach((item: any) => {
    const csi = item.CSI;
    if (pieData[csi]) {
      pieData[csi] += 1;
    } else {
      pieData[csi] = 1;
    }
  });
  const colorScale = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byCSITypePieData = Object.keys(pieData).map((csi, index) => ({
    name: csi,
    value: pieData[csi],
    color: colorType5(index.toString()),//colorScale(pieData[csi] / Math.max(...Object.values(pieData) as number[])),
  }));



  const pieData2: any = {};
  updatedData.forEach((item: any) => {
    const csi = item.CSI;
    const totalValue = item.TotalValueOfRecycleContentMaterial || 0;
    if (pieData2[csi]) {
      pieData2[csi] += totalValue;
    } else {
      pieData2[csi] = totalValue;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byCSITotalValuePieData = Object.keys(pieData2).map((csi, index) => ({
    name: csi,
    value: pieData2[csi],
    color: colorType3(index.toString()),//colorScale2(pieData2[csi] / Math.max(...Object.values(pieData2) as number[])),
  }));



  const [newData, setNewData] = useState<any[]>(updatedData);

  const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
  const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");

  function handleYearFilterSelect(val: string) {
    setSelectedYear(val);
    filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handleMonthFilterSelect(val: string) {
    setSelectedMonth(val);
    filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
  }
  function handlePackageFilterSelect(val: string) {
    filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
    setSelectedPackage(val);
  }
  function handleToastDismiss() {
    setToastVisibility(false);
  };
  const TotalCount = newData.reduce((total, item) => {
    if (item.TotalValueOfRecycleContentMaterial) {
      return total + item.TotalValueOfRecycleContentMaterial;
    }
    return total;
  }, 0);
  return (
    <View>

      <View style={[{ justifyContent: 'center', alignItems: 'center' }]}>
        <View style={{}}>
          <ConsolLogCountCard cardTitle={'Total Recycled Material Value'} number={Math.round(TotalCount)} />
        </View>
      </View>
      <View style={[CommonStyles.container, CommonStyles.divider]}>
        <View key={1} style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byCSITypePieData} colorPallate={interpolateBlues} subTitle={'(%)'} ChartTitle={"CSI Type (%)"} /></View>
        {/* <View key={2} style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={byCSITotalValuePieData} colorPallate={interpolateGnBu} subTitle={'(Recycled Material (%) / CSI)'} ChartTitle={"Total Value"} /></View> */}
      </View>

    </View>
  );
};