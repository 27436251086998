import { View, StyleSheet, Text, Platform, Dimensions, Modal, TouchableOpacity, ScrollView } from 'react-native';
import { BarChart } from 'react-native-chart-kit';
import React, { useState } from 'react';
import ChartStyles from './chartStyles';
import Legend from './Legend';
import { transparentize } from 'polished';
import { BlurView } from 'expo-blur';
import { Divider, IconButton, MD3Colors, Tooltip } from 'react-native-paper';
//import { ScrollView } from 'react-native-gesture-handler';
import { LinearGradient } from 'expo-linear-gradient';
import { VictoryArea, VictoryBar, VictoryChart, VictoryGroup, VictoryVoronoiContainer } from 'victory-native';
interface BarChartScreenProps {
  label: string[];
  data: any;
  //data:Array<any>
  ChartTitle: string;
  subTitle?: string;
  legend?: any;
}
function BarChartsComp(props: BarChartScreenProps) {
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [showFull, setShowFull] = useState<boolean>(false)
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const nullFilteredLabels = props.label;//.filter((label:string) => !label.includes('null'));
  //const Months:MonthMapping = {"Jan":"01", "Feb":"02","Mar":"03", "Apr":"04","May":"05", "Jun":"06","Jul":"07", "Aug":"08","Sep":"09", "Oct":"10","Nov":"11", "Dec":"12"}

  const labelCount = nullFilteredLabels.length;
  const maxVisibleLabels = 5;
  const step = Math.ceil(labelCount / maxVisibleLabels);
  //const filteredLabels = nullFilteredLabels.filter((_: any, index: any) => index % step === 0);
  const filteredLabels = nullFilteredLabels.slice(0, 5);
  const onChartLayout = (event: any) => {
    const { width } = event.nativeEvent.layout;
    setContainerWidth(width);
  };

  // const Bar1 = props.data.Line1;
  // const Bar2 = props.data.Line2;
  // const mergedData: any[] = [];
  // props.label.forEach((label: string, index: number) => {
  //   mergedData.push({
  //     label: label,
  //     bar1: Bar1[index],
  //     bar2: Bar2[index]
  //   });
  // });

  const barData = {
    labels: filteredLabels,//props.label,
    datasets: props.data.datasets
  };
  const transformedDatasets: any = [];


  // Loop through the datasets and transform each one
  props.data.datasets.forEach((dataset: any, index: number) => {
    const transformedDataset = {
      data: dataset.data.map((value: any, dataIndex: number) => ({
        x: props.data.labels[dataIndex],
        y: value,
        key: props.legend && props.legend[index].name
      })),
      strokeWidth: dataset.strokeWidth,
    };
    transformedDatasets.push(transformedDataset);
  });
  const fullScreenBarData = {
    labels: nullFilteredLabels,//props.label,
    datasets: props.data.datasets
  };
  // const barData = {
  //   labels: filteredLabels,//props.label,
  //   datasets: [
  //     {
  //       data: mergedData.map(item => item.bar1)
  //     },
  //     {
  //       data: mergedData.map(item => item.bar2)
  //     }
  //   ]
  // };

  const chartConfig = {
    //backgroundGradientFrom: '#fff',
    //backgroundGradientFromOpacity: 0,
    //backgroundGradientTo: '#fff',
    //backgroundGradientToOpacity: 0.5,
    //fillShadowGradient: '#DF5353',
    //fillShadowGradientOpacity: 1,
    color: (opacity = 0) => `rgba(26, 255, 146, ${opacity})`,//(opacity = 1) => `#023047`,
    //labelColor: (opacity = 1) => `#fcfcfc`,
    strokeWidth: 2,
    barPercentage: 1,
    useShadowColorFromDataset: false,
    decimalPlaces: 0,
    style: {
      borderRadius: 8,
      //marginBottom: 16,
      //backgroundColor: transparentize(0.9, '#b2b5b1'), // Set the desired darker background color here
    }
  };

  return (

    <View>
      {/* <LinearGradient colors={['#185760', '#30b0c2']}> */}
      <View style={[ChartStyles.style,]}>
        {props.data.datasets.length === 0 ? (
          <View>
            <Text style={ChartStyles.chartTitle}>{"There is no data available to show " + props.ChartTitle}</Text>
          </View>) :

          <View>
            <View style={[ChartStyles.container, Platform.OS === 'android' ? ChartStyles.boxAndroid : Platform.OS === 'ios' ? ChartStyles.boxIos : ChartStyles.box2]} onLayout={onChartLayout}>

              <View style={{ alignItems: 'center', flexDirection: 'row', width: containerWidth, justifyContent: 'center' }}>
                <View>
                  <TouchableOpacity style={ChartStyles.TitleIconHolder}>
                    <IconButton
                      icon="chart-box-outline"
                      iconColor={'#ffffff'}
                      size={30}
                    //onPress={() => GetLocation()}
                    />
                  </TouchableOpacity>
                </View>
                <View style={[ChartStyles.chartTitleContainer]}><Text style={[ChartStyles.chartTitle, { justifyContent: 'center' }]}>{props.ChartTitle}</Text></View>

                {Platform.OS === 'web' ?
                  <View style={ChartStyles.fullScreenIconContainer}><Tooltip title="View in Full Screen">
                    <IconButton
                      icon="fullscreen"
                      iconColor={ChartStyles.chartTitle.color}
                      size={20}
                      onPress={() => { setShowFull(true) }}
                    />
                  </Tooltip></View> : null}
              </View>
              {/* <Divider /> */}
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                {/* <BarChart
                    data={barData}
                    width={containerWidth}
                    height={300}
                    yAxisLabel=""
                    yAxisSuffix=""
                    chartConfig={chartConfig}
                    showBarTops={true}
                    style={{
                      marginVertical: 8,
                      borderRadius: 16,
                      //backgroundColor: 'transparent'
                    }}
                  /> */}
                <VictoryChart domainPadding={{x:25}} containerComponent={
                  <VictoryVoronoiContainer labels={({ datum }) => `${datum.x}, ${datum.y}`} />
                }
                >
                  <VictoryGroup
                    style={{
                      data: {
                        fillOpacity: 0.3
                      }
                    }}
                  >
                    {transformedDatasets.map((dataset, index) => (
                      <VictoryBar
                        key={index}
                        name={`area-${index}`}
                        data={dataset.data}
                        animate={{
                          duration: 3000,
                          onLoad: { duration: 3000 }
                        }}
                        style={{
                          data: {
                            stroke: props.data.datasets[index].color(1),
                            fill: props.data.datasets[index].color(1)
                          }
                        }}
                      />
                    ))}
                  </VictoryGroup>
                </VictoryChart>
              </ScrollView>
            </View>
            {/* <View style={{width:screenWidth, flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
                <ScrollView style={{backgroundColor:'#00000000'}} horizontal contentContainerStyle={{ alignItems: 'center', paddingVertical: 8 }}>
                  {props.legend && props.legend.map((legendItem:any, index: number) => {
                    const name = legendItem.name;
                    const color = props.data.datasets[index].color(1);
                    return <View key={index}><Legend key={name} name={name} color={color} /></View>;
                  })}
                  
                </ScrollView>
            </View> */}
            <View><Text> {' '} </Text></View>
          </View>}
      </View>
      {/* </LinearGradient> */}
      {
        showFull === false ? null :
          <View>
            <ScrollView>
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 22 }}>
                <Modal
                  animationType="fade"
                  transparent={true}
                  visible={showFull}
                  onRequestClose={() => {
                    setShowFull(!showFull);
                  }}>
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 22 }}>
                    <BlurView intensity={80} style={{ flex: 1, padding: 10, justifyContent: 'center', }}>
                      {/* <LinearGradient colors={['#004876', '#676767']} > */}
                      <View style={{ marginLeft: '90%' }}>
                        <TouchableOpacity style={ChartStyles.TitleIconHolder}>
                          <Tooltip title="Close">
                            <IconButton
                              //style={{ marginRight: 60 }}
                              icon="close-circle-outline"
                              iconColor={'#fff'}
                              size={30}
                              onPress={() => setShowFull(!showFull)}
                            />
                          </Tooltip>
                        </TouchableOpacity>
                      </View>
                      {/* <View style={{width:containerWidth, flexDirection: 'row', justifyContent: 'center', padding: 10 }}>
                        <ScrollView showsHorizontalScrollIndicator={false} shouldCancelWhenOutside={true} horizontal contentContainerStyle={{ alignItems: 'center', paddingVertical: 8 }}>
                          {props.legend && props.legend.map((legendItem:any, index: number) => {
                            const name = legendItem.name;
                            const color = props.data.datasets[index].color(1);
                            return <View key={index}><Legend key={name} name={name} color={color} /></View>;
                          })}
                        </ScrollView>
                      </View> */}
                      <ScrollView horizontal>
                        {/* <BarChart
                          data={fullScreenBarData}
                          width={Platform.OS === "web" ? screenWidth : 2000}
                          height={screenHeight - 400}
                          yAxisLabel=""
                          yAxisSuffix=""
                          chartConfig={chartConfig}
                          showBarTops={true}
                          style={{
                            marginVertical: 8,
                            borderRadius: 16,
                            backgroundColor: 'transparent'
                          }}
                        /> */}
                        <VictoryChart domainPadding={{x:25}} containerComponent={
                  <VictoryVoronoiContainer labels={({ datum }) => `${datum.x}, ${datum.y}`} />
                }
                >
                  <VictoryGroup
                    style={{
                      data: {
                        fillOpacity: 0.3
                      }
                    }}
                  >
                    {transformedDatasets.map((dataset, index) => (
                      <VictoryBar
                        key={index}
                        name={`area-${index}`}
                        data={dataset.data}
                        animate={{
                          duration: 3000,
                          onLoad: { duration: 3000 }
                        }}
                        style={{
                          data: {
                            stroke: props.data.datasets[index].color(1),
                            fill: props.data.datasets[index].color(1)
                          }
                        }}
                      />
                    ))}
                  </VictoryGroup>
                </VictoryChart>
                      </ScrollView>
                      {/* </LinearGradient> */}
                    </BlurView>
                  </View>
                </Modal>
              </View>
            </ScrollView>
          </View>
      }

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default BarChartsComp;