import { IGroupedSites, ISitee } from "../components/ClientLanding";

export const getObjectByPath = (path: string, data: ISitee): ISitee => {
  if (data.path === path) {
    return data;
  }

  if (data.children && data.children.length > 0) {
    for (const child of data.children) {
      const result = getObjectByPath(path, child);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

export const getObjectByID = (id: string, data: ISitee): ISitee => {
  if (data.id === id) {
    return data;
  }

  if (data.children && data.children.length > 0) {
    for (const child of data.children) {
      const result = getObjectByID(id, child);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

export function removeLastAndKeepLastTwo<T>(arr: T[]): T[] {
  arr.splice(arr.length - 1, 1);
  if (arr.length <= 2) {
    return arr;
  }
  return arr.slice(-2);
}

export function generateInitials(str: string): string {
  if (str) {
    const words = str.trim().split(' ');

    const firstChar = words[0].charAt(0).toUpperCase();
    const lastChar = words.length < 2 ? '' : words[words.length - 1].charAt(0).toUpperCase();

    return `${firstChar}${lastChar}`;
  }
  return '';
}

export function sortByOrder(items: any[]): any[] {
  return items.sort((a, b) => a.order - b.order);
}

export function groupSitesByCategory(items: ISitee[]): IGroupedSites[] {
  const grouped: Record<string | null, ISitee[]> = {};

  for (const item of items) {
    const category = item.category || null;
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push(item);
  }

  const groupedArray: IGroupedSites[] = [];
  for (const category in grouped) {
    groupedArray.push({ title: category, sites: grouped[category] });
  }
  groupedArray.sort((a, b) => (a.title || '').localeCompare(b.title || ''));

  return groupedArray;
}