import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { TextInput } from 'react-native-paper';
import { getQuantityCalculation } from './BusinessLogic';
import FormFieldTitle from './FormFieldTitle';

interface NumberFieldProps {
  field: any;
  control: any;
  editable: boolean;
  styles: any;
  Ctrl: any;
  errors: any;
  logTitle?: string;
  fields?: any;
  setFields?: any;
  invalidNumber?: any;
  conversionFactor?: number | undefined;
  changeEvent?: any
  updatedValue?: any;
}
const QuantityField: React.FC<NumberFieldProps> = ({ field, control, editable, styles, Ctrl, errors, fields, setFields, logTitle, invalidNumber, conversionFactor, changeEvent, updatedValue }) => {
  const [defaultVal, setDefaultVal] = useState();
  useEffect(() => {
    setDefaultVal(field.defaultValue ? field.defaultValue : 0)
  }, [field.defaultValue])
  //console.log(updatedValue && updatedValue ? "updated: " + updatedValue : field.defaultValue && "default: " + field.defaultValue)
  const rule = field.required ? { required: field.label + ' is required' } : { required: false };
  const QuantityChanged = (val: string) => {
    if (isNaN(Number(val))) {
      invalidNumber();
      return false
    }
    {
      // let updatedFields = fields.map((f: any) => {
      //   if (f.key === "QuantityTonne") {
      //     if (Number(conversionFactor)) {
      //       const convertedVal = ((parseInt(val) * conversionFactor).toFixed(2)).toString()
      //       return { ...f, "defaultValue": convertedVal };
      //     }
      //     else {
      //       return { ...f, "defaultValue": getQuantityCalculation(logTitle, val) };
      //     }
      //   }
      //   else if (f.key === "QuantityGallons") {
      //     return { ...f, "defaultValue": getQuantityCalculation(logTitle, val) };
      //   }
      //   else if (f.key === "QuantityLiters") {
      //     return { ...f, "defaultValue": getQuantityCalculation(logTitle, val) };
      //   }
      //   else if (f.key === "QuantityGallon") {
      //     return { ...f, "defaultValue": getQuantityCalculation(logTitle, val) };
      //   }

      //   return f;
      // });

      // setFields(updatedFields);
    }
    //changeEvent && changeEvent(val);
  }


  return (
    <View>
      <View style={{ width: '100%' }}>{<FormFieldTitle field={field} />}</View>

      {editable === false ? <View><Text style={{ fontWeight: 'normal', fontSize: 12 }}>{field.defaultValue ?? null}</Text></View> :
        <Ctrl
          key={field.key}
          control={control}
          name={field.key}
          rules={rule && rule}
          //defaultValue={defaultVal && defaultVal}
          render={({ field: { onChange, onBlur, value } }: { field: { onChange: any, onBlur: any, value: any } }) => (

            logTitle === "Energy Log" || logTitle === "Imported Soil Log" || logTitle === "Spoil Tracking Log" ||
              logTitle === "Waste Tracking Log" || logTitle === "Wastewater Tracking Log" || logTitle === "Water Log"
              ?
              <TextInput
                label={null}
                //keyboardType = {'numeric'}
                value={value}
                // value={
                //   field.key === "QuantityTonne" && field.defaultValue ? field.defaultValue
                //     : field.key === "QuantityGallons" && field.defaultValue ? field.defaultValue
                //       : field.key === "QuantityLiters" && field.defaultValue ? field.defaultValue
                //         : field.key === "QuantityGallon" && field.defaultValue ? field.defaultValue
                //           : value
                // }
                onChangeText={
                  field.key === "Quantitym3" ? (text: any) => { onChange(text); QuantityChanged(text) }

                    //: field.key === "QuantityTonne" ? (text: any) => { onChange(text); QuantityChanged(text) }
                    : (text) => { onChange(text) }
                }
                style={styles}
                inputMode='numeric'
                editable={editable}
                disabled={!editable}
                onBlur={onBlur}
              //ref={ref}
              />
              :
              <TextInput
                label={null}
                value={value}
                onChangeText={(text) => { onChange(text) }}
                style={styles}
                inputMode='numeric'
                editable={editable}
                disabled={!editable}
                onBlur={onBlur}
              //ref={ref}
              />
          )}
        />
      }
      {errors[field.key]?.message && <Text style={{ color: 'red' }}>{errors[field.key]?.message}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    backgroundColor: 'transparent',
    marginVertical: 5,
    marginBottom: 30,
    borderWidth: 1,
  },
});

export default QuantityField;
