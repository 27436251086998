import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, IconButton } from 'react-native-paper';
import { useNav } from '../../../providers/NavProvider';
import { getObjectByID, removeLastAndKeepLastTwo } from '../../../services/Utils';
import { ISitee } from '../../ClientLanding';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParams } from '../../Home';
import { useClient } from '../../../providers/ClientProvider';


const Breadcrumb: React.FC = () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParams>>();
    const { clientObj } = useClient();
    const { sitesResp, currentLeafObj } = useNav();
    const [breadcrumbTrail, setbreadcrumbTrail] = React.useState([]);

    React.useEffect(() => {
        if (currentLeafObj) {
            let trail = buildBreadcrumbTrail(currentLeafObj.id);
            setbreadcrumbTrail(removeLastAndKeepLastTwo(trail));
        }
    }, [currentLeafObj])



    const buildBreadcrumbTrail = (id: string): ISitee[] => {
        const trail: ISitee[] = [];
        const node = getObjectByID(id, sitesResp);
        if (node) {
            trail.push(node);
            if (node.parentId) {
                const parentTrail = buildBreadcrumbTrail(node.parentId);
                trail.unshift(...parentTrail);
            }
        }
        return trail;
    };



    return (
        <>
            {breadcrumbTrail&&breadcrumbTrail.length>0&&
                <View style={styles.container}>
                    {breadcrumbTrail.map((node, index) => (
                        <View key={node.id} style={styles.breadcrumbItem}>
                            <Text style={styles.breadcrumbLink} onPress={() => {
                                navigation.push("Site", { sitePath: node.path, clientShortURL: clientObj.shortURL })
                            }}>{node.title}</Text>
                            {/* {index < breadcrumbTrail.length - 1 && <IconButton size={16} icon='arrow-right' ></IconButton>} */}
                            {index < breadcrumbTrail.length - 1 && <Text style={styles.separator}>&rarr;</Text>}
                        </View>
                    ))}
                </View>
            }
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        padding:10,
    },
    breadcrumbItem: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    breadcrumbLink: {
        fontSize: 18,
        //color: 'blue', // Customize the link text color
        textDecorationLine: 'underline', // Add underline to the link text
        //marginRight: 5, // Add some spacing between the links
    },
    separator: {
        color: 'black', // Customize the separator text color
        marginRight: 10, // Add some spacing between the links and separator
        marginLeft:10
    },
});

export default Breadcrumb;
