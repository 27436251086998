import * as React from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { VictoryLabel, VictoryPie } from 'victory-native';
import Legend from '../Legend';
import ChartStyles from '../chartStyles';

interface inputData {
  name: string;
  value: number;
  color: string;
}

interface Data {
  x: string;
  y: number;
}

interface prop {
  input: inputData[];
  donutRadius?: number;
  containerWidth?: number;
  legendFontColor?: string;
}
const PieChart: React.FC<prop> = ({ input, donutRadius, containerWidth, legendFontColor }) => {
  const [data, setData] = React.useState<Data[]>([]);
  const[colors,setColors]=React.useState<string[]>([]);


  React.useEffect(() => {
    if (input && input.length > 0) {
      const tempData = input.map(obj => { 
        return { x: obj.name, y: obj.value }
      });
      setData(tempData);

      const tempColors = input.map(obj => {
        return obj.color;
      });
      setColors(tempColors);
    }
    else {
      setData([]);
    }

  }, [input])
  const hasData = data && data.length > 0;
  return (
  <View style={{height:'auto', flexDirection: 'column'}}>
    <VictoryPie
      animate={{
        duration: 1000
      }}
      //colorScale={colors}
      colorScale={hasData ? colors : ["#f0f0f0"]}
      innerRadius={donutRadius ? 30 : 50}
      style={{ labels: { display: "none" } }}
      //labels={({ datum }) => ` ${datum.y}`}
      //labelComponent={<VictoryLabel angle={45}/>}
      //padAngle={({ datum }) => 25}
      height={Platform.OS === 'web'?250:280}
      data={hasData ? data : [{ x: "No Data", y: 1 }]}
    />
    {!hasData && (
          <VictoryLabel
            text="No data available"
            x={Platform.OS === 'web' ? 200 : 140}  // Center the text
            y={Platform.OS === 'web' ? 200 : 140}
            textAnchor="middle"
            style={{ fontSize: 16, fill: 'gray' }}
          />
        )}
      {hasData && (
        <View style={{ width: containerWidth, flexDirection: 'row', justifyContent: 'center', paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
          <ScrollView showsHorizontalScrollIndicator={false} horizontal contentContainerStyle={{ alignItems: 'center', paddingVertical: 8 }}>
            {input.map((item, index) => (
              <View key={index}>
                <Legend fontColor={legendFontColor || ChartStyles.chartTitle.color} key={index} name={item.name + ': ' + item.value} color={item.color} />
              </View>
            ))}
          </ScrollView>
        </View>
      )}
  </View>)
}

export default PieChart;
