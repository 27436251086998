import { StyleSheet, View, Text, LayoutChangeEvent, Platform } from 'react-native';
import { transparentize } from 'polished';
const ChartStyles = StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
        borderRadius: 8,
        //marginBottom: 16,
        //backgroundColor:'ffffff',//transparentize(0.9, 'fff'), // Set the desired darker background color here
    },
    chartTitleContainer:{
      alignItems:'flex-start',
      flex:1,
      flexDirection:'column',
      justifyContent: 'center',
      height:40,
      width:'60%'
    },
    fullScreenIconContainer:{
      flex:1,
      width:'100%',
      alignItems:'flex-end',
    },
    chartSubTitle:{
      color:'#102B27',//'#FFFFFF',//#ffcf1a',
      fontWeight: 'bold',
      flexWrap:'wrap',
      //marginTop: 10, 
      paddingLeft: 20,
      //paddingTop:10,
      fontSize:12,
    },

    chartTitle:{
      color:'#102B27',//'#FFFFFF',//#ffcf1a',
      fontWeight: 'bold',
      flexWrap:'wrap',
      //marginTop: 10, 
      paddingLeft: 20,
      //paddingTop:10,
    fontSize:16,
    },
    chartlegendsText:{
      color:'#102B27',
    },
    chartXText:{
      color: '#102B27',//'#FFFFFF',
    },
    fullScreenIconColor:{

    },
    chartYText:{

    },
    TitleIconHolder:{
      // shadowColor: 'rgba(0,0,0, .4)', 
      // shadowOffset: { height: 3, width: 1 }, 
      borderRadius: 8, 
      // shadowOpacity: 1, 
      // shadowRadius: 5, 
      backgroundColor: '#102B27',//#3EB54B',//#ffcf1a', 
      //elevation: 5, 
      height: 50, 
      width: 50, 
      justifyContent: 'center', 
      alignItems: 'center', 
      flexDirection: 'row',
      marginLeft:10,
      marginTop:10,
    },
    style:{
      borderRadius: 8,
      //borderBottomRightRadius:8,
      //borderBottomLeftRadius:8,
      //marginBottom: 16,
      //backgroundColor: transparentize(0.2, '#b2b5b1'),
      
      backgroundColor:'#F9FCFF'
    },
    chart: {
      marginVertical: 8,
      borderRadius: 10,
    },
    box2: {
      //backgroundColor:'ffffff',
      shadowColor: 'rgba(0, 0, 0, 0.5)',
      shadowOffset: {
        width: 0,
        height: 5,
      },
      shadowOpacity: 1,
      shadowRadius: 10,
      elevation: 20,
      //shadowColor: '#52006A',
    },
    boxIos: {
      // shadowColor: 'rgba(0, 0, 0, 0.5)',
      // shadowOffset: {width: -2, height: 4},
      // shadowOpacity: 0.2,
      // shadowRadius: 10,
    },
    boxAndroid: {
      // elevation: 20,
      // shadowColor: '#52006A',//'rgba(0, 0, 0, 0.5)',
    },
    legendContainer: {
      flex: 1,
      marginTop: 20,
      alignItems: 'center',
      flexDirection:'column'
    },
  });

  export default ChartStyles