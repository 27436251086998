import React from "react";
import { createContext, useContext } from "react";
import { IClientsResp } from "../components/Clients";
import { LoggedInStatus, useAuth } from "./AuthProvider";
import { sortByOrder } from "../services/Utils";
import { Constants } from "../services/Constants";

const ClientContext = createContext({
    clientName: '',
    clientAPIURL: '',
    clientThemeColor: '',
    clientLogoURL: '',
    setClient: (clientObj: IClientsResp) => { },
    clientObj: {} as IClientsResp,
    arrclientObj: [] as IClientsResp[],
    fetchClients: (): Promise<IClientsResp[]> => { return Promise.resolve(null) },
    setCurrentClientShortURL: (shortURL: string) => { },
});
export type Props = {
    children: React.ReactNode;
};

const ClientProvider = (props: Props) => {
    const [clientObj, setClientObj] = React.useState<IClientsResp>({} as IClientsResp);
    const [arrclientObj, setArrclientObj] = React.useState<IClientsResp[]>([]);
    const [currentClientShortURL, setCurrentClientShortURL] = React.useState<string>(null);



    const { getAccessToken, accessToken, loggedInStatus } = useAuth();

    React.useEffect(() => {
        if (arrclientObj && arrclientObj.length > 0) {

        }
        else {
            fetchClients();
        }
    }, [loggedInStatus, currentClientShortURL])


    const fetchClients = async (): Promise<IClientsResp[]> => {
        if (arrclientObj && arrclientObj.length > 0) {
            return arrclientObj;
        }
        else {
            if (loggedInStatus == LoggedInStatus.LoggedIn) {
                const tempAccessToken = await getAccessToken();
                if (tempAccessToken) {

                    try {
                        const response = await fetch(Constants.COMMON_API_ENDPOINT + '/api/Common/clients'
                            ,
                            {
                                headers: { Authorization: `Bearer ${tempAccessToken}` },
                            }
                        );
                        const jsonData: IClientsResp[] = await response.json();
                        setArrclientObj(jsonData ? sortByOrder(jsonData) : []);
                        if (jsonData.length > 1) {
                            if (currentClientShortURL) {
                                let tempObj = jsonData.find(i => i.shortURL == currentClientShortURL);
                                setClientObj(tempObj);
                            }
                        }
                        else {
                            setClientObj(jsonData[0]);
                        }

                        return jsonData;

                    } catch (error) {
                        console.error('Error fetching data:', error);
                    }
                }
            }
            return [];
        }
    };


    const setClient = (clientObj: IClientsResp) => {
        setClientObj(clientObj);
    }

    return (
        <ClientContext.Provider
            value={{
                clientName: clientObj ? clientObj.name : '',
                clientAPIURL: clientObj ? clientObj.apiUrl : '',
                clientThemeColor: clientObj ? clientObj.themeColor : '',
                clientLogoURL: clientObj ? clientObj.iconURL : '',
                setClient,
                clientObj,
                arrclientObj,
                fetchClients,
                setCurrentClientShortURL,
            }}
        >
            {props.children}
        </ClientContext.Provider>
    );
}
const useClient = () => useContext(ClientContext);

export { ClientProvider, useClient };