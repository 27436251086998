import React, { useState, useEffect } from 'react';
import { Snackbar } from 'react-native-paper';

const ToastMessage = (props) => {
  const [visible, setVisible] = useState(props.show);

  useEffect(() => {
    setVisible(props.show);
  }, [props.show]);

  const hideSnackbar = () => {
    setVisible(false);
    if (props.onDismiss) {
      props.onDismiss(); // Call the callback prop if provided
    }
  };

  return (
    <Snackbar
      visible={visible}
      onDismiss={hideSnackbar}
      duration={props.duration}
      action={{
        label: props.actionLabel,
        onPress: hideSnackbar,
      }}
    >
      {props.toastMessage}
    </Snackbar>
  );
};

export default ToastMessage;
