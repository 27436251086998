import React, { useState, useEffect, useRef, useCallback } from 'react';
import { StyleSheet, View, TouchableOpacity, ScrollView, Text, Image, Platform, Dimensions, Modal, Linking } from 'react-native';
//import { Input, Button } from 'react-native-elements';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import TextField from '../controls/TextBox';
import NumberField from '../controls/NumberField';
import PaperDates from '../controls/datePicker-paperDates';
//import { DropdownControl, MultiDropdownControl } from '../controls/Dropdown-sharingan-rn';
import ButtonControl from '../controls/Button';
import { createItem, updateItem, triggerWorkflow } from '../service/dataOperations';
import { useAuth } from '../../../../providers/AuthProvider';
import { useClient } from '../../../../providers/ClientProvider';
import ToastMessage from '../controls/Toast';
import * as ExpoDocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system';
import * as Permissions from 'expo-permissions';
import DocPicker from '../controls/DocPicker';
import { IconButton, Tooltip, MD3Colors, Portal } from 'react-native-paper';
import { BlurView } from 'expo-blur';
import formCustomStyles from './formStyles';
import { useNav } from '../../../../providers/NavProvider';
import { getObjectByID } from '../../../../services/Utils';
import WebView from 'react-native-webview';
import * as Location from 'expo-location'
import CameraScreen from '../controls/CameraControl';
import LocationField from '../controls/LocationBox';
import { Loader } from '../../../Common/Loader';
import ConfirmBox from '../../../Common/ConfirmBox';
import MaterialDropdown from '../controls/Dropdown-Single';
import ReactDropdownMultiSelect from '../controls/Dropdown-Multi';
import { margin } from 'polished';
import { logStyles } from '../../LogControllers/LogStyles';
import ReactDropdownSingleSelect from '../controls/Dropdown-Single';
interface PaperFormProps {
  formMode: string;
  fields: Array<any>;
  selectedRow: Array<any>;
  closeForm: () => void;
  siteId: string;
  logTitle: string;
  updateFormState: () => void;
  ItemCreated: (Item: Array<any>) => void;
  Permissions?: string;
  chartVisibility?: (val: boolean) => void;
}

const WebObservationForm: React.FC<PaperFormProps> = React.memo((props) => {

  //console.log("Into Form Comp");
  const editFormFields = ['EscalationNote', 'DateClosedOut', 'ResponsibilityActionBy', 'RootCause','ActionTakenByContractor'];
  const [formMode, setFormMode] = useState(props.formMode);
  const [showButtons, setShowButtons] = useState(true);
  const [fields, setFields] = useState(formMode === 'New' ? props.fields && props.fields.filter(field => !editFormFields.includes(field.key)) : props.fields);
  const [fileResponse, setFileResponse] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  //const [escalated, setEscalated] = useState<boolean>(null);
  const [fileApi, setFileApi] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [loadForm, setLoadForm] = useState<boolean>(false);
  const [downloadedFileUri, setDownloadedFileUri] = useState(null);
  const [docType, setDocType] = useState(null);
  const [docWarning, setDocWarning] = useState<boolean>(null);
  const [warningText, setWarningText] = useState<string>(null);
  const [fileType, setFileType] = useState<string>();
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const [LocationLink, setLocationLink] = useState(null);
  const [fileLoad, setFileLoad] = useState<boolean>(false);
  const [ManualLocation, setManualLocation] = useState<string>("")
  let escalated: boolean = null;
  //const [uploadedFiles, setUploadedFiles] = useState(JSON.parse(column.defaultValue));
  let item: any;
  //let fileApi:string

  
  // const newFormFields = props.fields && props.fields.filter(field => !editFormFields.includes(field.key));
  // if (formMode === 'Edit' || formMode === 'View') {
  //   setFields(props.fields);
  // }
  // else{
  //   setFields(newFormFields);
  // }
  
  useEffect(() => {

    let formFields = props.fields;
    if (formMode === 'Edit' || formMode === 'View') {
      item = props.selectedRow[0] && props.selectedRow[0];
      formFields = item && fields.map((field) => {
        //const defaultValue = field.subType === 'date'? returnDate(item[field.key]) : item[field.key];
        const defaultValue = item[field.key] && item[field.key];
        return { ...field, defaultValue };
      });
      const AttachmentColumn = formFields && formFields.find((c) => c.subType === "attachment");
      if (AttachmentColumn && AttachmentColumn.defaultValue) {
        setUploadedFiles(JSON.parse(AttachmentColumn.defaultValue));
      }
      const LocationColumn = formFields.find((c) => c.key === "Location");
      if (LocationColumn && LocationColumn.defaultValue && LocationColumn.defaultValue.includes('coords')) {
        let LocLinks = JSON.parse(LocationColumn.defaultValue)        //LocationColumn.defaultValue;
        if (LocLinks.coords && LocLinks.coords.latitude && LocLinks.coords.longitude) {
          setLocationLink(LocLinks);

          let updatedFields = formFields.map((f: any) => {
            if (f.key === "Location") {
              let googleMapLink;
              if (LocLinks && LocLinks.coords && LocLinks.coords.latitude && LocLinks.coords.longitude) {
                //googleMapLink = LocLinks.coords.latitude && LocLinks.coords.longitude && `https://www.google.com/maps/search/?api=1&query=${LocLinks.coords.latitude},${LocLinks.coords.longitude}`;
                googleMapLink = LocLinks.coords.latitude && LocLinks.coords.longitude && `Lattitude, Longitude: ${LocLinks.coords.latitude}, ${LocLinks.coords.longitude}`;
              }
              else {
                googleMapLink = LocLinks
              }
              return { ...f, "defaultValue": JSON.stringify(googleMapLink) };
            }
            return f;
          });
          setFields(updatedFields);
        }
        else {
          setFields(formFields);
        }
      } else {
        setFields(formFields);
      }
      setFileApi(encodeURI(clientAPIURL + "api/sites/" + props.siteId + "/lists/" + props.logTitle + "/items/" + item.ID + "/attachments/"));
      setLocationLink(item.Location);
      setItemId(item.ID);
      //setFields(formFields);
      setLoadForm(true);
    }
    else if (formMode === 'New') { 
      setLoadForm(true) 
    }

  }, [formMode, props.selectedRow, props.fields]);

  const firstErrorFieldRef = useRef(null);
  const { register, setValue, handleSubmit, control, reset, formState: { errors } } = useForm();
  const methods = useForm();

  const [toastVisibility, setToastVisibility] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("");
  const { getAccessToken, accessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  const { currentLeafObj, sitesResp } = useNav();
  const Categroy = getObjectByID(currentLeafObj.parentId, sitesResp)
  const PackageName = getObjectByID(Categroy.parentId, sitesResp)
  const [visible, setVisible] = React.useState(false);
  const [CamVisible, setCamVisible] = React.useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [fileId, setFileId] = useState<any>(null);
  function MonthYear(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const month = adjustedDate.toLocaleString('en-US', { month: 'short' });
      const year = adjustedDate.getFullYear();
      return month + "-" + year;
    } else {
      return null;
    }
  }
  const onSubmit = async (d: any) => {
    debugger;
    setShowButtons(false);
    let ReferenceNumber = null;
    const MonthANDYear = d.Date && d.Date != null ? MonthYear(d.Date) : null;
    const Location = LocationLink != null ? LocationLink : ManualLocation;
    const Month = MonthANDYear === null ? null : MonthANDYear.split('-')[0];
    const Year = MonthANDYear === null ? null : MonthANDYear.split('-')[1];
    if (d.ObservationType !== null || d.ObservationType !== undefined) {
      ReferenceNumber = d.ObservationType === "Good Practice/ Strong Point" ? "GPR" : "ENVO";
    }

    const uploadedFilesJson = JSON.stringify(uploadedFiles);
    let status = escalated === true ? "Escalated" : d.ClosedOut === "Yes" ? "Closed" : formMode === 'New' ? "Submitted" : "Re-Submitted";
    const data = { ...d, 'Attachments': uploadedFilesJson, 'Status': status, ReferenceNumber, Month, Year, Location };

    //setD(JSON.stringify(data));
    let oItem = data;
    const result = Object.keys(data).map((key) => {
      const column = fields.find((c) => c.key === key);
      if (!column) {
        return null;
      }
      const value = data[key];
      const dataType = column.datatype.toLowerCase();
      if (dataType === 'datetimes') {
        delete oItem.key;
        oItem = { ...oItem, key: value };
      }

      const formattedValue =
        dataType === 'strings'
          ? value
          : dataType === 'numbers'
            ? Number(value)
            : dataType === 'datetimes'
              ? value
              : null;

      return {
        column_Name: column.key,
        [`${dataType}_Value`]: formattedValue,
        dataype: dataType,
      };
    }).filter((x) => x !== null);

    console.log(result);
    let response: any;
    if (formMode === 'Edit') {
      const accessToken = await getAccessToken();
      response = await updateItem(props.logTitle, accessToken, clientAPIURL, props.siteId, itemId, result);
      console.log(response);
    } else {
      const accessToken = await getAccessToken();
      response = await createItem(props.logTitle, accessToken, clientAPIURL, props.siteId, result);
      console.log(response);
    }

    //if(response.status === 401 || response.statusText === "Unauthorized" || response.statusText === "Bad Request")
    if (!response) {
      setToastText("Operation Failed: You are not authorized to perform this action.");
      setShowButtons(true);
      setToastVisibility(true);
    }
    if (response) {
      setItemId(response);
      setToastText("Your changes are saved.");
      const accessToken = await getAccessToken();
      if (formMode === "Edit") {
        const result = await triggerWorkflow(currentLeafObj.path, itemId, status, PackageName.title, props.siteId, props.logTitle, clientAPIURL, accessToken)
        console.log(result);
      }
      else {
        const result = await triggerWorkflow(currentLeafObj.path, response, status, PackageName.title, props.siteId, props.logTitle, clientAPIURL, accessToken)
        console.log(result);
      }
      setToastVisibility(true);
      setTimeout(function () {
        if (formMode === 'Edit') {
          //const createdItem = oItem && { ...oItem, "ID": itemId, "key": itemId };
          if (oItem) {
            oItem["ID"] = itemId
            oItem["key"] = itemId
          }
          const createdItem = oItem
          props.ItemCreated(createdItem);
        }
        else {
          if (oItem) {
            oItem["ID"] = response
            oItem["key"] = response
          }
          const createdItem = oItem //&& { ...oItem, "ID": response, "key": response };
          props.ItemCreated(createdItem);
        }
      }, 2000)
    }
    else setShowButtons(true);
  };
  function userTypedLocation(txt: string) {
    setManualLocation(txt);
    let updatedFields = fields.map((f: any) => {
      if (f.key === "Location") {
        return { ...f, "defaultValue": txt };
      }
      return f;
    });
    setFields(updatedFields);
  }
  const onFileUploaded = (fileObj: any) => {
    let parsedFileData = JSON.parse(fileObj);
    setUploadedFiles(prevFiles => [...prevFiles, parsedFileData]);
  }
  const [formStyle, setFormStyle] = useState(styles.formContainerMobile);
  function returnDate(value: any) {
    if (value) {
      //const datePattern = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      //if (datePattern.test(value)) 
      {
        //const date = new Date(`${value.split('/')[1]}/${value.split('/')[0]}/${value.split('/')[2]}`);
        const date = new Date(`${value.split('/')[0]}/${value.split('/')[1]}/${value.split('/')[2]}`);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
        return formattedDate
      }
    }
    else return value
  }
  const handleClose = () => {
    props.closeForm();
    props.chartVisibility(false);
  }

  const handleToastDismiss = () => {
    setToastVisibility(false);
  };
  const uploadFile = async (fileUri: any) => {
    try {

      const byteCharacters = atob(fileUri);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: 'application/octet-stream' });
      const file = new File([blob], 'filename.extension', { type: 'application/octet-stream' });
      const formData = new FormData();
      formData.append('file', file);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "multipart/form-data");
      const requestOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow',
      };

      const response = await fetch("https://dev-dgda-client-greenport.azurewebsites.net/api/uploadFile", requestOptions);
      const result = await response.text();
      console.log(result);
    } catch (error) {
      console.log('Error uploading file:', error);
    }
  };

  const _pickDocument = async () => {
    let result: any = await ExpoDocumentPicker.getDocumentAsync({});

    if (result.type === 'success') {
      // Read the file contents as binary data
      const fileContent = await FileSystem.readAsStringAsync(result.uri, {
        encoding: FileSystem.EncodingType.Base64,
      });
      const formData: any = new FormData();
      formData.append('file', {
        uri: result.uri,
        name: result.name,
        type: result.type,
        data: fileContent,
      });
      //uploadFile(result.uri);
      console.log(result);
    }
  }
  function ClearLocationText() {
    setLocationLink(null);
    userTypedLocation(" ");
    //setManualLocation(null);//Already we are setting it in userTypedLocation()
    // setTimeout(() => {
    //   userTypedLocation(null);
    // }, 1000);

  }
  function confirmDelete(id: any) {
    setFileId(id)
    showConfirmationBox();
  }
  function showConfirmationBox() {
    setShowConfirmation(!showConfirmation);
  }
  function removeFile() {
    const newFiles = fileId && uploadedFiles.filter(file => file.fileId !== fileId);
    setUploadedFiles(newFiles);
    showConfirmationBox();
  }

  const openFile = async (fileId: string, fileName: string) => {
    setFileLoad(true);
    const api = fileApi + fileId;
    const accessToken = await getAccessToken();
    if (Platform.OS == "web") {
      try {

        const response = await fetch(api, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = blobURL;
        tempLink.setAttribute('download', fileName);
        tempLink.click();
        window.URL.revokeObjectURL(blobURL);
        showModal();
        setFileLoad(false);
      }
      catch (err) {
        // Handle error if file couldn't be opened
        console.log('Error opening file: ', err);
      }
    }

    else {
      downloadFile(api, accessToken, fileName);
    }

  }
  const downloadFile = async (url: string, token: void, fileName: string) => {

    if (Platform.OS === 'ios') {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
      }
    } else if (Platform.OS === 'android') {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status !== 'granted') {
        alert('Sorry, we need write storage permissions to make this work!');
      }
    }

    const uri = url;
    setFileType(uri.split('.').pop().toLowerCase());
    setDocType(uri.split('.').pop().toLowerCase());
    const fileUri = FileSystem.documentDirectory + fileName;
    let options = {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    };
    console.info('Trying to download ' + uri);
    console.info('Download Loc ' + fileUri);
    // FileSystem.downloadAsync(uri, fileUri, options)
    //   .then(({ uri }) => {
    //     console.log('Finished downloading to ', uri);
    //     setDownloadedFileUri(uri);
    //     showModal();
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
    FileSystem.makeDirectoryAsync(FileSystem.documentDirectory, { intermediates: true })
      .then(() => {
        FileSystem.downloadAsync(uri, fileUri, options)
          .then(({ uri }) => {
            console.log('Finished downloading to ', uri);
            setDownloadedFileUri(uri);
            showModal();
            setFileLoad(false);
          })
          .catch(error => {
            console.error(error);
          });
      })
      .catch(error => {
        console.error('Error creating directory:', error);
      });
  }
  function submitClicked(status: string, handleSubmit: any) {
    handleSubmit();
  }
  function submitPressed() {
    handleSubmit(onSubmit)();
  }
  function escalationPressed() {
    handleSubmit(onSubmit)();
  }
  const GetLocation = async () => {
    try {
      const { status } = await Location.requestForegroundPermissionsAsync();
      console.log(status)
      if (status != 'granted') {
        console.log('Permissions not granted!')
      }
      else {
        setToastText("Permission denied to read location. Still you can manually enter location.")
        setToastVisibility(true);
      }
      const locationData = await Location.getCurrentPositionAsync();
      if (locationData.coords && locationData.coords.latitude && locationData.coords.longitude) {
        setLocationLink(JSON.stringify(locationData));
      } else {
        setToastText("Unable to read your location. Still you can manually enter location.")
        setToastVisibility(true);
      }
      //const googleMapLink = locationData.coords.latitude && locationData.coords.longitude && `https://www.google.com/maps?q=${locationData.coords.latitude},${locationData.coords.longitude}`;    
      //const googleMapLink = locationData.coords.latitude && locationData.coords.longitude && `https://www.google.com/maps/search/?api=1&query=${locationData.coords.latitude},${locationData.coords.longitude}`;



      console.info(JSON.stringify(locationData));
      let updatedFields = fields.map((f: any) => {
        if (f.key === "Location") {
          let googleMapLink;
          if (locationData && locationData.coords && locationData.coords.latitude && locationData.coords.longitude) {
            //googleMapLink = locationData.coords.latitude && locationData.coords.longitude && `https://www.google.com/maps/search/?api=1&query=${locationData.coords.latitude},${locationData.coords.longitude}`;
            googleMapLink = locationData.coords.latitude && locationData.coords.longitude && `Lattitude, Longitude: ${locationData.coords.latitude}, ${locationData.coords.longitude}`;
          }
          else {
            googleMapLink = locationData
          }
          return { ...f, "defaultValue": JSON.stringify(googleMapLink) };
        }
        return f;
      });
      setFields(updatedFields);
    }
    catch (error) {
      // Handle promise rejection
      console.error('An error occurred:', error);
    }
  }

  const GoToLocation = async () => {
    try {
      const locationData = JSON.parse(LocationLink);
      console.log(JSON.stringify(locationData));
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        const scheme = Platform.select({ ios: 'maps://0,0?q=', android: 'geo:0,0?q=' });
        const latLng = `${locationData.coords.latitude},${locationData.coords.longitude}`;
        const label = 'Observation Location';
        const url = Platform.select({
          ios: `${scheme}${label}@${latLng}`,
          android: `${scheme}${latLng}(${label})`
        });
        Linking.openURL(url);
      }
      else if (Platform.OS === 'web') {
        const googleMapLink = locationData.coords.latitude && locationData.coords.longitude && `https://www.google.com/maps/search/?api=1&query=${locationData.coords.latitude},${locationData.coords.longitude}`;
        window.open(googleMapLink, '_blank');
      }
    }
    catch (error) {
      // Handle promise rejection
      console.error('An error occurred:', error);
    }
  }
  const setEscalated = (val: boolean) => {
    escalated = val;
  }
  const fieldStyle = formMode === "View"? formCustomStyles.fieldContianerViewMode: formCustomStyles.fieldContianer
  return (
    loadForm === false ? null :
      <View style={[formStyle, { marginLeft: Platform.OS === 'web' ? '10%' : 0, marginRight: Platform.OS === 'web' ? '10%' : 0 }]}>
        {Platform.OS === 'web' ? null :
          visible === false ? null :
            <View style={formCustomStyles.centeredView}>
              <Modal
                animationType="fade"
                transparent={true}
                visible={visible}
                onRequestClose={() => {
                  setVisible(!visible);
                }}>
                <View style={formCustomStyles.centeredView}>
                  <BlurView intensity={80} style={{ flex: 1, padding: 100, justifyContent: 'center', }}>
                    <View>
                      <Tooltip title="Close">
                        <IconButton
                          style={{ marginLeft: screenWidth - 60 }}
                          icon="close-circle-outline"
                          iconColor={MD3Colors.error0}
                          size={20}
                          onPress={() => setVisible(!visible)}
                        />
                      </Tooltip>
                    </View>

                    {fileType === 'pdf' ?
                      fileLoad === true ?
                        <Loader size={'small'} />
                        :
                        <WebView
                          style={{ paddingLeft: 150, paddingRight: 150, paddingTop: 200, paddingBottom: 200 }}
                          source={{ uri: downloadedFileUri }}
                        />

                      :
                      fileLoad === true ?
                        <Loader size={'small'} />
                        :
                        <Image
                          style={{ paddingLeft: 150, paddingRight: 150, paddingTop: 200, paddingBottom: 200 }}
                          source={{ uri: downloadedFileUri }}
                          resizeMode='contain'
                        />

                    }
                    {/* <Pdf
                    source={downloadedFileUri}
                    onLoadComplete={(numberOfPages,filePath) => {
                        console.log(`Number of pages: ${numberOfPages}`);
                    }}
                    onPageChanged={(page,numberOfPages) => {
                        console.log(`Current page: ${page}`);
                    }}
                    onError={(error) => {
                        console.log(error);
                    }}
                    onPressLink={(uri) => {
                        console.log(`Link pressed: ${uri}`);
                    }}
                    style={{flex:1, width:Dimensions.get('window').width, height:Dimensions.get('window').height}}
                    
                /> */}
                  </BlurView>
                </View>
              </Modal>

            </View>

        }
        <ScrollView>
          <>
            <Modal
              animationType="fade"
              transparent={true}
              visible={CamVisible}
              onRequestClose={() => {
                setVisible(!CamVisible);
              }}>
              <View style={formCustomStyles.centeredView}>
                <BlurView intensity={80} style={{ flex: 1, padding: 100, justifyContent: 'center', }}>
                  <View>
                    <Tooltip title="Close">
                      <IconButton
                        style={{ marginLeft: screenWidth - 60 }}
                        icon="close-circle-outline"
                        iconColor={MD3Colors.error0}
                        size={20}
                        onPress={() => setCamVisible(!CamVisible)}
                      />
                    </Tooltip>
                  </View>
                  <CameraScreen onFileUploaded={onFileUploaded} clientAPIURL={clientAPIURL} setCamVisible={setCamVisible} />
                </BlurView>
              </View>
            </Modal>
            <View style={[{ width: 200 }, styles.buttonsContainer]}>
              {fileResponse.map((file, index) => (
                <Text
                  key={index.toString()}
                  style={{}}
                  numberOfLines={1}
                  ellipsizeMode={'middle'}>
                  {file?.uri}
                </Text>
              ))}
              {/* <ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /> */}
            </View>
            <View>
              {/* <View style={[styles.buttonsContainer, { padding: 10, flexDirection:'column' }]}>
                <View >
                  <ButtonControl icon="content-save-edit" mode="contained"
                    onPress={() => {
                      setEscalated(false);
                      handleSubmit(onSubmit)()
                    }}
                    Title='Submit' />
                </View>
                <View ><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
              </View> */}
              <View style={{ margin: 10 }}>
                {showButtons === false ? null :

                  formMode === "View"
                    ?
                    <View style={[{ flex: 1 }, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                    :
                    <ScrollView horizontal>
                      <View style={styles.buttonsContainer}>
                        <View>
                          <View style={{ flex: 1 }}>
                            <ButtonControl icon="content-save-edit" mode="contained"
                              onPress={() => {
                                setEscalated(false);
                                handleSubmit(onSubmit)()
                              }}
                              Title='Submit' />
                          </View>
                        </View>
                        <View>
                          {
                            ((props.Permissions) && (props.Permissions.includes('admin') || props.Permissions.includes('manage')) && formMode === "Edit") ?
                              <View style={{ flex: 1 }}><ButtonControl icon="content-save-edit" mode="contained"
                                onPress={() => {
                                  setEscalated(true);
                                  handleSubmit(onSubmit)()
                                }}
                                Title='Escalate' /></View>
                              : null
                          }
                        </View>
                        <View style={{ flex: 1 }}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                      </View>
                    </ScrollView>
                }

              </View>
              <View style={{ borderWidth: 2, borderColor: '#DDDDDD', paddingLeft: 10 }}>
                <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{"Attachments:"}</Text>
                <View style={{ flexDirection: 'column' }}>
                  {formMode === "View" ? null :
                    <View style={{ padding: 20 }}>
                      <DocPicker formMode={formMode} onFileUploaded={onFileUploaded} clientAPIURL={clientAPIURL} showWarning={setDocWarning} warningText={setWarningText} />
                    </View>
                  }
                  <View style={{ padding: 20, width: 240 }}>
                    {
                      formMode === "View" ? null :
                        Platform.OS === 'android' || Platform.OS === 'ios' ?
                          <ButtonControl icon='camera' mode="contained" onPress={() => setCamVisible(true)} Title='Camera' />
                          : null
                    }
                  </View>
                  {docWarning === true ?
                    <View>
                      <Text style={{ color: 'red' }}>{warningText}</Text>
                    </View>
                    : null}
                </View>
                <View style={{ paddingLeft: 20 }}>

                  {uploadedFiles.map((file: { fileId: string, fileName: string }, index) => (
                    formMode === "View" || formMode === "Edit" ?
                      <View key={index} style={{ flexDirection: "row", alignItems: "center", height: 50 }}>
                        <TouchableOpacity onPress={() => openFile(file.fileId, file.fileName)}>
                          <Text style={{ marginRight: 10 }}>Attachment {index + 1}: <Text style={{ color: 'blue', textDecorationLine: 'underline' }}>{file.fileName}</Text></Text>
                        </TouchableOpacity>
                        {formMode === "Edit" ? <IconButton
                          icon="close-circle-outline"
                          iconColor={MD3Colors.error50}
                          size={20}
                          onPress={() => confirmDelete(file.fileId)}
                        /> : null}
                      </View>
                      :
                      <View key={index} style={{ flexDirection: "row", alignItems: "center", height: 50 }}>
                        <Text style={{ marginRight: 10 }}>Attachment {index + 1}: {file.fileName}</Text>
                        <Tooltip title="Remove attachment">
                          <IconButton
                            icon="close-circle-outline"
                            iconColor={MD3Colors.error50}
                            size={20}
                            onPress={() => { confirmDelete(file.fileId) }}
                          />
                        </Tooltip>
                      </View>
                  ))}
                  {showConfirmation && (
                    <View>

                      <ConfirmBox title={"Confirmation"}
                        message={"Are you sure you want to proceed to delete selected items?"}
                        yesCallback={removeFile}
                        noCallback={showConfirmationBox} />

                    </View>
                  )}
                </View>
                <View style={{ padding: 10 }}></View>
              </View>
            </View>
            <View>

            </View>
            <View style={formCustomStyles.allFieldsContainer}>

              {fields.map((field, index) => {

                if (field.hidden === true) {
                  return null
                }
                else {
                  if (field.key === "EscalationNote") {
                    return ((props.Permissions) && (props.Permissions.includes('admin') || props.Permissions.includes('manage')) && (formMode === 'Edit' || formMode === 'View')) ?
                      <View key={index} style={[fieldStyle]}>
                        <TextField
                          key={field.key.toString()}
                          editable={formMode === 'View' ? false : true}
                          field={field}
                          control={control}
                          styles={styles.input}
                          Ctrl={Controller}
                          errors={errors}
                        //ref={register}
                        /></View>
                      : null
                  }
                  if (field.key === "Location") {
                    return (
                      <View key={index} style={[fieldStyle]}>
                        <View key={index} style={{ flexDirection: 'column' }}>
                          <View>
                            <LocationField
                              key={field.key.toString()}
                              editable={formMode === 'View' ? false : true}
                              field={field}
                              control={control}
                              styles={styles.input}
                              Ctrl={Controller}
                              errors={errors}
                              userTypedLocation={userTypedLocation}
                            />

                          </View>
                          <View style={{ height: 70, padding: 10, alignItems: 'flex-start' }}>
                            <View style={{ flex: 1, flexDirection: 'row', gap: 20 }}>
                              {formMode === "View" ? null :
                                <TouchableOpacity onPress={() => GetLocation()} style={styles.locationIconHolder}>
                                  <Tooltip title="Get live location">
                                    <IconButton
                                      icon="map-marker-plus-outline"
                                      iconColor={MD3Colors.error10}
                                      size={20}
                                      onPress={() => GetLocation()}
                                    />
                                  </Tooltip>
                                </TouchableOpacity>
                              }
                              {/* <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center' }}> */}
                              {
                                LocationLink && LocationLink !== null && LocationLink.includes('coords') ?
                                  <TouchableOpacity onPress={() => GoToLocation()} style={styles.locationIconHolder}>
                                    <Tooltip title="Open Location">
                                      <IconButton
                                        icon="map-marker-path"
                                        iconColor={MD3Colors.error10}
                                        size={20}
                                        onPress={() => GoToLocation()}
                                      />
                                    </Tooltip>
                                  </TouchableOpacity>
                                  : null
                              }
                              {/* </View> */}
                              {formMode === "View" ? null :
                                <TouchableOpacity onPress={() => ClearLocationText()} style={styles.locationIconHolder}>
                                  <Tooltip title="Clear Location Text">
                                    <IconButton
                                      icon="close-circle-outline"
                                      iconColor={MD3Colors.error50}
                                      size={20}
                                      onPress={() => ClearLocationText()}
                                    />
                                  </Tooltip>
                                </TouchableOpacity>
                              }
                            </View>
                          </View>
                        </View>
                      </View>
                    )
                  }
                  switch (field.subType) {
                    case 'text':
                      return (
                        // <Text>{field.key}</Text>
                        <View key={index} style={[fieldStyle]}>
                          <TextField
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            field={field}
                            control={control}
                            styles={styles.input}
                            Ctrl={Controller}
                            errors={errors}
                            logTitle={props.logTitle}
                          //ref={register}
                          /></View>
                      );
                    case 'choice':
                      return (
                        field.key === "RootCause" ? 
                            <View key={index} style={[fieldStyle]}>
                                <ReactDropdownMultiSelect
                                  field={field}
                                  key={field.key.toString()}
                                  editable={formMode === 'View' ? false : true}
                                  control={control}
                                  Ctrl={Controller}
                                  errors={errors}
                                />
                            </View>
                            :
                            <View key={index} style={[fieldStyle]}>
                              <ReactDropdownSingleSelect
                                field={field}
                                key={field.key.toString()}
                                editable={formMode === 'View' ? false : true}
                                control={control}
                                Ctrl={Controller}
                                errors={errors}
                              />
                            </View>
                      );
                    case 'float':
                      return (
                        // <Text>{field.key}</Text>
                        <View key={index} style={[fieldStyle]}>
                          <NumberField
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            field={field}
                            control={control}
                            styles={styles.input}
                            Ctrl={Controller}
                            errors={errors}
                          //ref={register}
                          />
                        </View>
                      );
                    case 'integer':
                      return (
                        // <Text>{field.key}</Text>
                        <View key={index} style={[fieldStyle]}>
                          <NumberField
                            key={field.key.toString()}
                            editable={formMode === 'View' ? false : true}
                            field={field}
                            control={control}
                            styles={styles.input}
                            Ctrl={Controller}
                            errors={errors}
                          //ref={register}
                          />
                        </View>
                      );
                    case 'date':
                      return (
                        // <Text>{field.key}</Text>
                        <View key={index} style={[fieldStyle]}>
                          <PaperDates
                            key={field.key.toString()}
                            field={field}
                            editable={formMode === 'View' ? false : true}
                            control={control}
                            styles={styles.date}
                            Ctrl={Controller}
                            errors={errors}
                          //ref={register}
                          />
                        </View>
                      );

                    default:
                      return null
                  }
                }
              }
              )}


            </View>
            <View style={{ margin: 10 }}>
              {showButtons === false ? null :
                formMode === "View"
                  ?
                  <View style={[{ flex: 1 }, styles.buttonsContainer]}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                  :
                  <ScrollView horizontal>
                    <View style={styles.buttonsContainer}>
                      <View>
                        <View style={{ flex: 1 }}>
                          <ButtonControl icon="content-save-edit" mode="contained"
                            onPress={() => {
                              setEscalated(false);
                              handleSubmit(onSubmit)()
                            }}
                            Title='Submit' />
                        </View>
                      </View>
                      <View>
                        {
                          ((props.Permissions) && (props.Permissions.includes('admin') || props.Permissions.includes('manage')) && formMode === "Edit") ?
                            <View style={{ flex: 1 }}><ButtonControl icon="content-save-edit" mode="contained"
                              onPress={() => {
                                setEscalated(true);
                                handleSubmit(onSubmit)()
                              }}
                              Title='Escalate' /></View>
                            : null
                        }
                      </View>
                      <View style={{ flex: 1 }}><ButtonControl icon="close-circle-outline" mode="contained" onPress={handleClose} Title='Close' /></View>
                    </View>
                  </ScrollView>
              }
            </View>
            <View>
              <ToastMessage
                show={toastVisibility}
                actionLabel="Dismiss"
                toastMessage={toastText}
                duration={3000}
                onDismiss={handleToastDismiss}
              />
            </View>
          </>
        </ScrollView>
      </View>


  );
});

const styles = StyleSheet.create({
  container: {
    //justifyContent: 'center',
    //alignItems: 'center',
    paddingLeft: '5%',
    paddingRight: '5%',

    flex: 1,
    padding: 16,
    gap: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  formContainerPC: {
    paddingLeft: '25%',
    paddingRight: '25%',
  },
  formContainerMobile: {
    borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)

    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4,
    //marginLeft: '5%',
    //marginRight: '5%',
    //paddingLeft: 5,
    //paddingRight: 5,
    backgroundColor: '#FFFFFF'
  },
  locationIconHolder: {
    shadowColor: 'rgba(0,0,0, .4)',
    shadowOffset: { height: 3, width: 1 },
    borderRadius: 50,
    shadowOpacity: 1,
    shadowRadius: 5,
    backgroundColor: '#fff',
    elevation: 5,
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  input: {
    backgroundColor: 'transparent',
    marginVertical: 5,
    marginBottom: 30,
    borderWidth: 1,
    //borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)
    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4,
    minHeight: 60,
    maxHeight: 60,
  },
  date: {
    minWidth: '100%',
    minHeight: 60,
    maxHeight: 60,

    backgroundColor: 'transparent',
    marginVertical: 5,
    marginBottom: 30,
    borderWidth: 1,
    //borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)
    shadowColor: 'blue', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4
  },
  buttonsContainer: {
    flex: 1,
    //padding: 16,
    gap: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    //alignItems: 'center',
    //justifyContent: 'space-evenly',
    height: 60
  }
});

export default WebObservationForm;
