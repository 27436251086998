import React, { useEffect, useRef, useState } from "react";
import { Pressable, View, StyleSheet } from "react-native";
import { CommonStyles, EnableExport, FilterChoices, GetConsolidatedLog, constructData, filterData, handleExportClick, handleFilterReset } from "../ConsolidatedLogs/ConsolidatedController";
import { interpolateBlues, interpolateCool, schemeCategory10 } from "d3-scale-chromatic";
import { colorType3, colorType5, logStyles } from "../LogControllers/LogStyles";
import ConsolLogCountCard from "../ConsolidatedLogs/ConsolLogCountCard";
import { scaleOrdinal, scaleSequential } from "d3-scale";
import GeoMap from '../common/controls/GeoMap';
interface LogProps {
    logTitle: string;
    accessToken: string;
    clientAPIURL:string;
    filterVal?:number;
  }
export const DbObservation:React.FC<LogProps> = ({logTitle, accessToken, clientAPIURL, filterVal}) => {
  const [observationLogData, setLogData] = useState<null | any>(null);
  const [observationLogColumns, setLogColumns] = useState<null | any>(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    async function fetchData() {
      try {
        const jResponse = await GetConsolidatedLog(logTitle, clientAPIURL, accessToken);
        const finalData = constructData(jResponse);
        setLogData(finalData.items);
        setLogColumns(finalData.columns);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [logTitle, clientAPIURL, accessToken]);
  
  return (

    // <ScrollView>
    //         {observationLogData && observationLogColumns ? <CreateLogCharts setObservationLogData={setLogData} observationLogData={observationLogData} observationLogColumns={observationLogColumns} logTitle={logTitle} clientAPIURL={clientAPIURL} /> : <Text>Loading Observations</Text>}
    // </ScrollView>
    <View>
      {/* <ShimmerPlaceholder visible={!loading} style={{ opacity: shimmerOpacity }}> */}
        {observationLogData && observationLogColumns && !loading && (
          <CreateLogCharts
            setObservationLogData={setLogData}
            observationLogData={observationLogData}
            observationLogColumns={observationLogColumns}
            logTitle={logTitle}
            clientAPIURL={clientAPIURL}
            filterVal={filterVal}
          />
        )}
      {/* </ShimmerPlaceholder> */}
    </View>

  )
}
export default DbObservation;
interface CreateLogChartsProps {
  observationLogData: any;
  observationLogColumns: any;
  filterVal?:number;
  logTitle: string;
  clientAPIURL: string;
  setObservationLogData: any;
}
  
  const CreateLogCharts: React.FC<CreateLogChartsProps> = ({filterVal, setObservationLogData, observationLogData, observationLogColumns, logTitle, clientAPIURL }) => {
    const [FilterVisible, setFilterVisible] = React.useState(false);  
    const ColumnsWithIDColumn = observationLogColumns && [
      ...observationLogColumns,
      {
        id: 'CODE_GENERATED_ID',
        title: 'Item ID',
        columnName: 'ID',
        list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
        datatype: 'calculated',
        createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
        modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
        required: false,
        subType: 'Float',
        choiceChoices: null,
        choiceType: null,
        choiceMultiselect: null,
        multiline: false,
        order: 0,
        calculatedFormula: null,
        listNavigation: null,
      }
    ];
    
    //const sortedData = observationLogData//[...observationLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
    
    
    const currentDate = new Date();  // Get the current date
    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - filterVal);  // Calculate the date 30 days ago

    const dbFilteredData = filterVal===0? observationLogData : observationLogData.filter((item:any) => {
      // Assuming item.Date is a JavaScript Date object
      const itemDate = item.Date && new Date(item.Date);
      return itemDate && itemDate >= thirtyDaysAgo && itemDate <= currentDate;
    });
    
    const updatedData = dbFilteredData.map((item: any) => {
      const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
      const key = item.ID;
      const Date = item.Date && returnDate(item.Date);
      const DueDate = item.DueDate && item.DueDate != null ? returnDate(item.DueDate) : null;
      const DateClosedOut = item.DateClosedOut && item.DateClosedOut != null ? returnDate(item.DateClosedOut) : null;
      const Status = checkStatus(item.ClosedOut)
      const ReferenceNumber = item.ReferenceNumber === null ? item.ID : item.ReferenceNumber + "-" + item.ID;
      return { ...item, Period, key, Date, ReferenceNumber, DueDate, DateClosedOut };
    });
  
    const [selectedYear, setSelectedYear] = useState<string>('ALL');
    const [selectedMonth, setSelectedMonth] = useState<string>('ALL');
    const [selectedPackage, setSelectedPackage] = useState<string>('ALL');
    const [toastVisibility, setToastVisibility] = useState<boolean>(false)
    const [toastText, setToastText] = useState<string>("");
  
    const [newData, setNewData] = useState<any[]>(updatedData);
    function GetPeriod(value: Date) {
      if (value && value != null) {
        const date = new Date(value);
        const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
        const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
        const day = adjustedDate.getDate();
        const month = adjustedDate.getMonth() + 1;
        const year = adjustedDate.getFullYear();
        const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
        return formattedDate;
      } else {
        return value;
      }
    }
    const recordsWithCoordinates = updatedData && updatedData.filter((record:any) => {
      
    
      let loc = record.Location && record.Location;
      if(loc && loc.includes('coords'))
      {
        const location = JSON.parse(record.Location);
        return location && location.coords && location.coords.latitude && location.coords.longitude ;
      }
    });
    function returnDate(value: Date) {
      if (value) {
        const date = new Date(value);
        const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
        const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
        const day = adjustedDate.getDate();
        const month = adjustedDate.getMonth() + 1;
        const year = adjustedDate.getFullYear();
        const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
        return formattedDate;
      } else {
        return value;
      }
    }
    function checkStatus(div: string) {
      switch (div) {
        case "Yes": return "Closed";
        case "No": return "Open";
        case "Escalated": return "Escalated";
        default: return null
      }
    }
    
    const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  
    /**
     * Creating Line Chart by Status
     */
    const colorScaleLine1 = scaleOrdinal(schemeCategory10);
    const statusValues = [...new Set(newData.map((item: any) => item.Status))].filter(type => type !== null);
    const statusData: any = {};
    statusValues.forEach((status) => {
      statusData[status] = [];
      uniquePeriod.forEach((period) => {
        const filteredData = newData
          .filter((item) => item.Period === period)
          .filter((item) => item.Status === status)
          .length;
        statusData[status].push(filteredData);
      });
    });
  
    const datasets = Object.entries(statusData).map(([key, values], index: any) => ({
      data: values,
      strokeWidth: 2,
      color: (opacity = 1) => colorScaleLine1(index) + Math.round(opacity * 255).toString(16),
    }));
  
    const lineData1 = {
      labels: [...new Set(newData.map((item: any) => item.Period))],
      datasets,
    };
  
  
    const legendData1 = [...statusValues].map((type: any, index: any) => ({
      name: type,
    }));
  
    /**
     * Creating Line Chart by Type
     */
  
    const colorScaleLine2 = scaleOrdinal(schemeCategory10);
    const oTypeValues = [...new Set(newData.map((item: any) => item.ObservationType))].filter(type => type !== null);
    const typeData: any = {};
    oTypeValues.forEach((type) => {
      typeData[type] = [];
      uniquePeriod.forEach((period) => {
        const filteredData = newData
          .filter((item) => item.Period === period)
          .filter((item) => item.ObservationType === type)
          .length;
        typeData[type].push(filteredData);
      });
    });
  
    const typeDatasets = Object.entries(typeData).map(([key, values], index: any) => ({
      data: values,
      strokeWidth: 2,
      color: (opacity = 1) => colorScaleLine2(index) + Math.round(opacity * 255).toString(16),
    }));
  
    const lineData2 = {
      labels: [...new Set(newData.map((item: any) => item.Period))],
      datasets: typeDatasets,
    };
    const legendData2 = [...oTypeValues].map((type: any, index: any) => ({
      name: type,
    }));
  
    /**
     * Creating Line Chart by Catgory
     */
    const colorScaleLine3 = scaleOrdinal(schemeCategory10);
    const CategoryValues = [...new Set(newData.map((item: any) => item.Category))].filter(type => type !== null);
    const CategoryData: any = {};
    CategoryValues.forEach((category) => {
      CategoryData[category] = [];
      uniquePeriod.forEach((period) => {
        const filteredData = newData
          .filter((item) => item.Period === period)
          .filter((item) => item.Category === category)
          .length;
        CategoryData[category].push(filteredData);
      });
    });
  
    const CategoryDatasets = Object.entries(CategoryData).map(([key, values], index: any) => ({
      data: values,
      strokeWidth: 2,
      color: (opacity = 1) => colorScaleLine3(index) + Math.round(opacity * 255).toString(16),
    }));
  
    const lineData3 = {
      labels: [...new Set(newData.map((item: any) => item.Period))],
      datasets: CategoryDatasets,
    };
    const legendData3 = [...CategoryValues].map((type: any, index: any) => ({
      name: type,
    }));
    /**
     * Pie Chart by Status
     */
    const pieDataStatus: any = {};
    newData.forEach((item: any) => {
      const Key = item.Status;
      if (pieDataStatus[Key]) {
        pieDataStatus[Key] += 1;
      } else {
        pieDataStatus[Key] = 1;
      }
    });
    const colorScaleStatus = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
    const StatusPieData = Object.keys(pieDataStatus).map((key, index) => ({
      name: key,
      value: pieDataStatus[key],
      color: colorType5(index.toString()),//colorScaleStatus(pieDataStatus[key] / Math.max(...Object.values(pieDataStatus) as number[])),
    }));
  
    /**
     * Pie Chart by Type
     */
    const pieDataObservationType: any = {};
    newData.forEach((item: any) => {
      const Key = item.ObservationType;
      if (pieDataObservationType[Key]) {
        pieDataObservationType[Key] += 1;
      } else {
        pieDataObservationType[Key] = 1;
      }
    });
    const colorScaleObservationType = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
    const TypePieData = Object.keys(pieDataObservationType).map((key, index) => ({
      name: key,
      value: pieDataObservationType[key],
      color: colorType3(index.toString()),//colorScaleObservationType(pieDataObservationType[key] / Math.max(...Object.values(pieDataObservationType) as number[])),
    }));
  
    /**
     * Pie Chart by Catgory
     */
    const pieDataCatgory: any = {};
    newData.forEach((item: any) => {
      const Key = item.Catgory;
      if (pieDataCatgory[Key]) {
        pieDataCatgory[Key] += 1;
      } else {
        pieDataCatgory[Key] = 1;
      }
    });
    const colorScaleCatgory = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
    const CatgoryPieData = Object.keys(pieDataCatgory).map((key, index) => ({
      name: key,
      value: pieDataCatgory[key],
      color: colorType5(index.toString()),//colorScaleCatgory(pieDataCatgory[key] / Math.max(...Object.values(pieDataCatgory) as number[])),
    }));
    /**
     * End of Charts
     */
  
  
    /**
     * Custom Table By Observation Type
     */
  
    const ObservationTypeLabels = observationLogColumns.find((col: any) => col.columnName === 'ObservationType')?.choiceChoices;
    const ObservationTypeTable = ObservationTypeLabels.split("#;#").filter((value: string) => value !== "undefined");
    const groupedDataTable1 = newData.filter(item => item.ObservationType != '' || item.ObservationType != null).reduce((result, item) => {
      const { Period, ObservationType } = item;
      if (!result[Period]) {
        result[Period] = { Period };
        ObservationTypeTable.forEach((oType: string) => {
          result[Period][oType] = 0; // Initialize with 0 for each ObservationType
        });
      }
      result[Period][ObservationType] += 1
      return result;
    }, {});
    interface GroupedDataItem2 {
      Period: any;
      [key: string]: number | null;
    }
    const groupedDataTable1Array: GroupedDataItem2[] = Object.values(groupedDataTable1);
    const fData = groupedDataTable1Array.map(item => {
      const newItem = { ...item };
      delete newItem.undefined;
      return newItem;
    });
  
  
    const periods = [...new Set(newData.map(oItem => oItem.Period))];
    const filteredDataByPeriod = periods.map(Period => {
      const filteredItems = newData.filter(item => item.Period === Period);
      const TotalOpen = filteredItems.filter(item => item.Status === "Open");
      const TotalClosed = filteredItems.filter(item => item.Status === "Closed");
      const sumOpen = TotalOpen.length;
      const sumClosed = TotalClosed.length;
      const filteredItems1 = fData.filter(item => item.Period === Period);
  
      const result = [
        ["Period", filteredItems1[0]?.Period],
        ["Total Open", sumOpen],
        ["Total Closed", sumClosed],
      ];
  
      filteredItems1.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (key !== "Period") {
            result.push([key, value]);
          }
        });
      });
      return Object.fromEntries(result);
    });
  
    const transformedDataTable1 = Object.values(filteredDataByPeriod).map((values: any) => {
      return {
        ...values,
      };
    });
  
  
    const combinedData1: { [key: string]: { [key: string]: number } } = {};
  
    filteredDataByPeriod.forEach(item => {
      const { Period, ...rest } = item;
      combinedData1[Period] = { ...combinedData1[Period], ...rest };
    });
  
    const mergedData1 = Object.entries(combinedData1).map(([Period, values]) => ({
      Period,
      ...values
    }));
  
    const columnKeys1 = mergedData1.length > 0 ? Object.keys(mergedData1[0]).map((key, index) => {
      return {
        ColumnName: key,
        Order: index,
      };
    }) : [];
  
    /**
     * Create Table 2 By Observation Category
     * 
     */
  
    const CategoryLabels = observationLogColumns.find((col: any) => col.columnName === 'Category')?.choiceChoices;
    const CategoryTable = CategoryLabels.split("#;#").filter((value: string) => value !== "undefined");
    const groupedDataTable2 = newData.filter(item => item.Category != '' || item.Category != null).reduce((result, item) => {
      const { Period, Category } = item;
      if (!result[Period]) {
        result[Period] = { Period };
        CategoryTable.forEach((category: string) => {
          result[Period][category] = 0; // Initialize with 0 for each Category
        });
      }
      result[Period][Category] += 1
      return result;
    }, {});
    interface GroupedDataItem {
      Period: any;
      [key: string]: number | null;
    }
    const groupedDataTable2Array: GroupedDataItem[] = Object.values(groupedDataTable2);
    const fData2 = groupedDataTable2Array.map(item => {
      const newItem = { ...item };
      delete newItem.undefined;
      return newItem;
    });
  
    const filteredDataByPeriod2 = periods.map(Period => {
      const filteredItems = newData.filter(item => item.Period === Period);
      const TotalOpen = filteredItems.filter(item => item.Status === "Open");
      const TotalClosed = filteredItems.filter(item => item.Status === "Closed");
      const sumOpen = TotalOpen.length;
      const sumClosed = TotalClosed.length;
      const filteredItems2 = fData2.filter(item => item.Period === Period);
  
      const result = [
        ["Period", filteredItems2[0]?.Period],
        ["Total Open", sumOpen],
        ["Total Closed", sumClosed],
      ];
  
      filteredItems2.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          if (key !== "Period") {
            result.push([key, value]);
          }
        });
      });
      return Object.fromEntries(result);
    });
  
    const transformedDataTable2 = Object.values(filteredDataByPeriod2).map((values: any) => {
      return {
        ...values,
      };
    });
  
  
    const combinedData2: { [key: string]: { [key: string]: number } } = {};
  
    filteredDataByPeriod2.forEach(item => {
      const { Period, ...rest } = item;
      combinedData2[Period] = { ...combinedData2[Period], ...rest };
    });
  
    const mergedData2 = Object.entries(combinedData2).map(([Period, values]) => ({
      Period,
      ...values
    }));
  
    const columnKeys2 = mergedData2.length > 0 ? Object.keys(mergedData2[0]).map((key, index) => {
      return {
        ColumnName: key,
        Order: index,
      };
    }) : [];
  
  
  
  
    const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
    const yearFilterOptions = FilterChoices(uniqueYearsArray, "Year");
    const uniqueMonthsArray = [...new Set(updatedData.map((item: any) => item.Month))];
    const monthFilterOptions = FilterChoices(uniqueMonthsArray, "Month");
    const uniquePackagesArray = [...new Set(updatedData.map((item: any) => item.packageName))];
    const packageFilterOptions = FilterChoices(uniquePackagesArray, "Package");
  
    function handleYearFilterSelect(val: string) {
      setSelectedYear(val);
      filterData(val, selectedMonth, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
    }
    function handleMonthFilterSelect(val: string) {
      setSelectedMonth(val);
      filterData(selectedYear, val, selectedPackage, setToastVisibility, updatedData, setToastText, setNewData);
    }
    function handlePackageFilterSelect(val: string) {
      filterData(selectedYear, selectedMonth, val, setToastVisibility, updatedData, setToastText, setNewData);
      setSelectedPackage(val);
    }
    function handleToastDismiss() {
      setToastVisibility(false);
    };
    const TotalCount = recordsWithCoordinates.length//newData.length;
    return (
      <View>
        <View style={[{justifyContent: 'center', alignItems:'center'}]}>
          <View style={{}}>
            <ConsolLogCountCard cardTitle={'Total Observations'} number={Math.round(TotalCount)} />
          </View>
        </View>
        

            <View style={CommonStyles.container}>
              <View style={[CommonStyles.card, { height: 400 }]}>
                {/* <PieChartComp data={StatusPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Observations/Status"} /> */}
                <GeoMap data={recordsWithCoordinates}/>
              </View>
              {/* <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineData1} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Observations/Status"} /></View> */}
              {/* <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData3} data={lineData3} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Observations/Category"} /></View>
              <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={TypePieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Observations/Type"} /></View> */}
              {/* <View style={[CommonStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineData2} label={uniquePeriod} subTitle={'(Monthly)'} ChartTitle={"Observations/Type"} /></View> */}
              {/* <View style={[CommonStyles.card, { backgroundColor: '' }]}><PieChartComp data={CatgoryPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative)'} ChartTitle={"Observations/Category"} /></View> */}              
            </View>
          {/* <View style={CommonStyles.container}>
            <View style={[CommonStyles.cardTwo, { height: 350 }]}>
              {recordsWithCoordinates && <GeoMapApp data={recordsWithCoordinates} />}
            </View>
          </View> */}

        
      </View>
    );
  };
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#F194FF',
    },
    buttonClose: {
        backgroundColor: '#2196F3',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
});