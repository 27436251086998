import React, { createContext, useContext, useEffect, useState } from 'react';
import * as WebBrowser from 'expo-web-browser';
import { DiscoveryDocument, exchangeCodeAsync, makeRedirectUri, refreshAsync, revokeAsync, useAuthRequest, useAutoDiscovery } from 'expo-auth-session';
import jwtDecode from 'jwt-decode';
import { deleteStringAsync, getStringAsync, setStringAsync } from '../services/storage/storage';
import { Constants } from '../services/Constants';

WebBrowser.maybeCompleteAuthSession();




const REDIRECT_URI = makeRedirectUri({
  scheme: Constants.AUTH_SCHEME,
  native: Constants.AUTH_SCHEME + '://auth',
  //useProxy: false,

});

const ACCESS_TOKEN_KEY = 'GREENPORT_ACCESS_TOKEN';
const REFRESH_TOKEN_KEY = 'GREENPORT_REFRESH_TOKEN';
const LOGOUT_URL_KEY = 'GREENPORT_LOGOUT_URL';

export enum LoggedInStatus {
  Unknown,
  LoggedIn,
  LoggedOut,

}

const AuthContext = createContext({
  accessToken: '',
  // refreshToken: '',
  login: () => { },
  logout: () => { },
  getAccessToken: (): Promise<string> => { return Promise.resolve('') },
  clearTokens: () => { },
  getAccessTokenForceRefresh: (): Promise<string> => { return Promise.resolve('') },
  userEmail: '',
  userDisplayName: '',
  loggedInStatus: LoggedInStatus.Unknown
});

export type Props = {
  children: React.ReactNode;
};


type AccessTokenDecoded = {
  exp: number;
  name: string;
  emails: string[];
};

const AuthProvider = (props: Props) => {
  //const [isTokenLoaded, setIsTokenLoaded] = useState(false);
  const [accessToken, setAccessToken] = useState<string>('');
  const [refreshToken, setRefreshToken] = useState<string>('');
  const [logoutUrl, setLogoutUrl] = useState<string>('');
  //const [tokenEndPoint, setTokenEndPoint] = useState<string | undefined>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userDisplayName, setDisplayName] = useState<string>('');
  const [loggedInStatus, setLoggedInStatus] = useState<LoggedInStatus>(LoggedInStatus.Unknown);

  const discovery = useAutoDiscovery(Constants.AUTH_DISCOVERY_URI);

  const [request, response, promptAsync] = useAuthRequest(
    {
      clientId: Constants.AUTH_CLIENT_ID,
      scopes: Constants.AUTH_SCOPES,
      redirectUri: REDIRECT_URI,
      // redirectUri: makeRedirectUri({
      //   scheme: authConstants.scheme,
      //   native:authConstants.scheme+'://auth',
      //   useProxy: false,

      // }),
    },
    discovery
  );

  React.useEffect(() => {
    if (discovery != null) {
      fetchAccessTokenAsync();
      // setTokenEndPoint(discovery?.tokenEndpoint)
    }
  }, [discovery]);


  const handleResponse = async () => {
    try {
      if (response?.type === 'success') {
        setLoggedInStatus(LoggedInStatus.Unknown);
        const { code } = response.params;
        const result = await exchangeCodeAsync(
          {
            clientId: Constants.AUTH_CLIENT_ID,
            scopes: Constants.AUTH_SCOPES,
            redirectUri: REDIRECT_URI,
            // redirectUri: makeRedirectUri({
            //   scheme: authConstants.scheme,
            //   useProxy: false,
            // }),
            code,
            extraParams: {
              // code_verifier: "CODE_VERIFIER",
              code_verifier: request?.codeVerifier || '',
            },
          },
          discovery as DiscoveryDocument
        );

        if (result?.accessToken) {
          setLoggedInStatus(LoggedInStatus.LoggedIn);
          setAccessTokenPersisted(result.accessToken);
          await setUserInfo(result.accessToken);
          const logoutRedirectUri = REDIRECT_URI;
          // const logoutRedirectUri = makeRedirectUri({
          //   scheme: authConstants.scheme,
          //   native:authConstants.scheme+'://auth',
          //   path: '/',
          // });
          const logoutUrlWithRedirectUri =
            discovery?.endSessionEndpoint +
            `?post_logout_redirect_uri=${logoutRedirectUri}`;
          setLogOutURLPersisted(logoutUrlWithRedirectUri);
        }

        if (result?.refreshToken) {
          setRefreshTokenPersisted(result.refreshToken);
          // setTokenEndPoint(discovery?.tokenEndpoint)
        }
      }
    }
    catch (ex) {
      setLoggedInStatus(LoggedInStatus.LoggedOut);
    }
  };

  const handleLoginPress = () => {
    promptAsync();
  };

  const handleLogoutPress = async () => {
    // await revokeAsync(
    //   {
    //     clientId: authConstants.clientID,
    //     token: refreshToken,
    //     // extraParams: {
    //     //   // code_verifier: "CODE_VERIFIER",
    //     //   code_verifier: request?.codeVerifier || '',
    //     // },
    //   },
    //   discovery
    // );
    let logOutURLTemp = await logOutURLAsync();
    WebBrowser.openAuthSessionAsync(logOutURLTemp, REDIRECT_URI);
    await clearTokens();
  };

  const setUserInfo = async (accessToken: string) => {
    const decodedToken: AccessTokenDecoded = jwtDecode(accessToken);
    setDisplayName(decodedToken.name);
    setUserEmail(decodedToken.emails[0]);
  }

  const clearTokens = async () => {
    clearAccessToken();
    clearRefreshToken();
    clearLogOutURL();
    setDisplayName('');
    setUserEmail('');    
    setLoggedInStatus(LoggedInStatus.LoggedOut);
  }

  const clearLogOutURL = async () => {
    await deleteStringAsync(LOGOUT_URL_KEY);
    setLogoutUrl('');
  }
  const clearAccessToken = async () => {
    await deleteStringAsync(ACCESS_TOKEN_KEY);
    setAccessToken('');
  }

  const clearRefreshToken = async () => {
    await deleteStringAsync(REFRESH_TOKEN_KEY);
    setRefreshToken('');
  }

  const setAccessTokenPersisted = async (token: string) => {
    await setStringAsync(ACCESS_TOKEN_KEY, token);
    setUserInfo(token);
    setAccessToken(token);
  }

  const setLogOutURLPersisted = async (url: string) => {
    await setStringAsync(LOGOUT_URL_KEY, url);
    setLogoutUrl(url);
  }

  const setRefreshTokenPersisted = async (token: string) => {
    await setStringAsync(REFRESH_TOKEN_KEY, token);
    setRefreshToken(token);
  }




  const fetchAccessTokenAsync = async (): Promise<string> => {
    // Check if access token is already present and not expired
    if (accessToken && (jwtDecode<AccessTokenDecoded>(accessToken).exp * 1000 - Date.now() > 5 * 60 * 1000)) {
      setLoggedInStatus(LoggedInStatus.LoggedIn);
      return accessToken;
    }
    // Check if access token is already present in storage and not expired
    const persistedAccessToken = await getStringAsync(ACCESS_TOKEN_KEY);

    if (persistedAccessToken && (jwtDecode<AccessTokenDecoded>(persistedAccessToken).exp * 1000 - Date.now() > 5 * 60 * 1000)) {
      setAccessToken(persistedAccessToken);
      setUserInfo(persistedAccessToken);
      setLoggedInStatus(LoggedInStatus.LoggedIn);
      console.log('access token fetched from storage');
      return persistedAccessToken;
    }

    // Access token is either not present or expired, refresh it
    const newAccessToken = await refreshTokenAsync();
    if (newAccessToken != '') {
      setLoggedInStatus(LoggedInStatus.LoggedIn);
    }
    else {
      setLoggedInStatus(LoggedInStatus.LoggedOut);
    }
    // Return the new access token
    return newAccessToken;
  };

  const logOutURLAsync = async (): Promise<string> => {
    const logOutURLStateOrPersisted: string | null = logoutUrl || await getStringAsync(LOGOUT_URL_KEY);
    if (logOutURLStateOrPersisted) {
      return logOutURLStateOrPersisted;
    }
    else {
      const logoutUrlWithRedirectUri =
        discovery?.endSessionEndpoint +
        `?post_logout_redirect_uri=${REDIRECT_URI}`;
      setLogOutURLPersisted(logoutUrlWithRedirectUri);
      return logoutUrlWithRedirectUri;
    }
  }

  const refreshTokenAsync = async (): Promise<string> => {


    const refreshTokenStateOrPersisted: string | null = refreshToken || await getStringAsync(REFRESH_TOKEN_KEY);

    if (refreshTokenStateOrPersisted) {
      console.log('refresh token fetched.');
      try {
        const result = await refreshAsync(
          {
            clientId: Constants.AUTH_CLIENT_ID,
            refreshToken: refreshTokenStateOrPersisted,
            extraParams: {
              redirect_uri: REDIRECT_URI,
              // redirect_uri: makeRedirectUri({
              //   scheme: authConstants.scheme,
              //   native:authConstants.scheme+'://auth',
              //   useProxy: false
              // })
            },
          },
          {
            tokenEndpoint: discovery?.tokenEndpoint
          }
        );



        if (result?.refreshToken) {
          setRefreshTokenPersisted(result.refreshToken);
        }
        if (result?.accessToken) {
          setAccessTokenPersisted(result.accessToken);
          await setUserInfo(result.accessToken);
          return result?.accessToken;
        }

      }
      catch (ex) {
        console.error(ex);
        clearTokens();
      }
    }
    return '';
  };

  useEffect(() => {
    handleResponse();
  }, [response]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        //  refreshToken,
        login: handleLoginPress,
        logout: handleLogoutPress,
        getAccessToken: fetchAccessTokenAsync,
        clearTokens: clearTokens,
        userEmail,
        userDisplayName,
        getAccessTokenForceRefresh: refreshTokenAsync,
        loggedInStatus: loggedInStatus,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };