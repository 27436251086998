import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const FormFieldTitle : React.FC<any> =  (props) => {
  const fieldTitle = props.field.label;
  
  return (
    <View style={styles.labelContainer}>
      <Text style={styles.labelText}>{fieldTitle}</Text>
      {props.field.required && (
        <Text style={styles.requiredText}> *</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  labelContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  requiredText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'red',
    marginLeft: 4,
  },
});

export default FormFieldTitle;
