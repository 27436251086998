import * as React from 'react';
import { View } from 'react-native';
import { Button, Dialog, Portal, Text } from 'react-native-paper';

export interface IConfirmBox {
    title:string;
    message: string;
    yesCallback: any;
    noCallback?: any;
    //show: boolean;
    yesText?: string;
    noText?: string;

}

const ConfirmBox: React.FC<IConfirmBox> = (props) => {
    //   const [visible, setVisible] = React.useState(false);

    //   const showDialog = () => setVisible(true);

    //   const hideDialog = () => setVisible(false);

    return (

        <View>
            <Portal>
                <Dialog style={{maxWidth:500,alignSelf:'center',maxHeight:200, minWidth:300}} visible={true} onDismiss={props.noCallback}>
                    <Dialog.Title>{props.title}</Dialog.Title>
                    <Dialog.Content>
                        <Text variant="bodyMedium">{props.message}</Text>
                    </Dialog.Content>
                    <Dialog.Actions>
                        <Button onPress={props.noCallback}>{props.noText || 'No'}</Button>
                        <Button onPress={props.yesCallback}>{props.yesText || 'Yes'}</Button>

                    </Dialog.Actions>
                </Dialog>
            </Portal>
        </View>
    );
};

export default ConfirmBox;