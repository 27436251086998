import React from "react";
import { View, Text } from "react-native";
import ChartStyles from "../common/charts/chartStyles";
interface ConsolCountCardProps {
    cardTitle: string;
    number: number
}
const ConsolLogCountCard: React.FC<ConsolCountCardProps> = ({ number, cardTitle }) => {
    return (
        // <View style={[ChartStyles.box2, {flexDirection:'row'}]}>
        <View>
            <View style={{alignItems:'flex-start'}}>
            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{cardTitle+ ': '+number}</Text>
            {/* <Text style={{ fontSize: 18, fontWeight: 'bold' }}>{number}</Text> */}
            </View>
        </View>
    )
}
export default ConsolLogCountCard;