import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView, LogBox } from 'react-native';
import { interpolateGnBu, interpolateBlues, interpolateOranges, interpolateCool, interpolateBrBG, schemeCategory10, interpolateBuGn, interpolateBuPu } from 'd3-scale-chromatic'
import LineChartScreen from '../common/charts/lineChart-builder';
import PaperTableDynamic from '../common/controls/TableProvider';
import PieChartComp from '../common/charts/piChart-builder';
import { getAllItems, getItemById } from '../common/service/dataOperations';
import { useAuth } from '../../../providers/AuthProvider';
import { useClient } from '../../../providers/ClientProvider';
import { scaleOrdinal, scaleSequential } from 'd3-scale';
import { SingleSelectDropdown } from '../common/controls/Dropdown-filter';
import CustomDT from '../common/controls/customDataTable';
import { Loader } from '../../Common/Loader';
import { colorType3, colorType5, logStyles } from './LogStyles';
import LogFilter from './LogFilterDropdown';
import { TabScreen, Tabs, TabsProvider } from 'react-native-paper-tabs';
import LogCountCard from './LogCountCard';
interface Props {
  siteId: string;
  logTitle: string;
}
export default function WasteLog(props: Props) {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);
  const [wasteLogData, setWasteLogData] = useState<null | any>(null);
  const [wasteLogColumns, setWasteLogColumns] = useState<null | any>(null);
  const { getAccessToken } = useAuth();
  const { clientName, clientAPIURL } = useClient();
  useEffect(() => {
    async function fetchData() {
      try {
        const logTitle = props.logTitle;
        const accessToken = await getAccessToken();
        const json = await getAllItems(logTitle, accessToken, clientAPIURL, props.siteId);
        setWasteLogData(json.items);
        setWasteLogColumns(json.columns);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  return wasteLogData && wasteLogColumns ? <CreateLogCharts setWasteLogData={setWasteLogData} wasteLogData={wasteLogData} wasteLogColumns={wasteLogColumns} siteId={props.siteId} logTitle={props.logTitle} clientAPIURL={clientAPIURL} /> : <Loader />;
}
interface CreateLogChartsProps {
  wasteLogData: any;
  wasteLogColumns: any;
  siteId: string;
  logTitle: string;
  clientAPIURL: string;
  setWasteLogData: any;
}

const CreateLogCharts: React.FC<CreateLogChartsProps> = ({ setWasteLogData, wasteLogData, wasteLogColumns, siteId, logTitle, clientAPIURL }) => {

  const { getAccessToken } = useAuth();
  const ColumnsWithIDColumn = wasteLogColumns && [
    ...wasteLogColumns,
    {
      id: 'CODE_GENERATED_ID',
      title: 'Item ID',
      columnName: 'ID',
      list: '7ae860e9-6c67-46d0-b65e-f2869542a3aa',
      datatype: 'calculated',
      createdAt: 'YOUR_CREATED_AT_TIMESTAMP',
      modifiedAt: 'YOUR_MODIFIED_AT_TIMESTAMP',
      required: false,
      subType: 'Float',
      choiceChoices: null,
      choiceType: null,
      choiceMultiselect: null,
      multiline: false,
      order: 0,
      calculatedFormula: null,
      listNavigation: null,
    }
  ];
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const sortedData = [...wasteLogData].sort((a: any, b: any) => new Date(b.Date) - new Date(a.Date));
  const updatedData = sortedData.map((item: any) => {
    const Period = item.Date && GetPeriod(item.Date);//item.Month + '-' + item.Year;
    const key = item.ID;
    const Date = item.Date && returnDate(item.Date);
    const Hazardous: string = checkHazardous(item.WasteType);
    const Diverted: string = checkDiverted(item.WasteDisposalMethod);

    return { ...item, Period, key, Date, Hazardous, Diverted };
  });

  const [selectedYear, setSelectedYear] = useState<string>('ALL');
  const [newData, setNewData] = useState<any[]>(updatedData);
  function GetPeriod(value: Date) {
    if (value && value != null) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${month < 10 ? '0' + month : month}-${year}`;
      return formattedDate;
    } else {
      return value;
    }
  }
  function returnDate(value: Date) {
    if (value) {
      const date = new Date(value);
      const timezoneOffset = 4 * 60; // UTC +4 timezone offset in minutes
      const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);
      const day = adjustedDate.getDate();
      const month = adjustedDate.getMonth() + 1;
      const year = adjustedDate.getFullYear();
      const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
      return formattedDate;
    } else {
      return value;
    }

  }
  function checkDiverted(div: string) {
    //let val;
    switch (div) {
      case "Reuse on-site": return "Diverted";
      case "Reuse off-site": return "Diverted";
      case "Recycling / Recover": return "Diverted";
      case "Landfill": return "Not Diverted";
      case "Dump site": return "Not Diverted";
      case "Other": return "Not Diverted";
      default: return null
    }
  }

  function checkHazardous(wT: string) {
    //let val;
    switch (wT) {
      case "Paper/ Cardboard": return "Non Hazardous";
      case "Plastic": return "Non Hazardous";
      case "Metal": return "Non Hazardous";
      case "Wood": return "Non Hazardous";
      case "Green": return "Non Hazardous";
      case "Concrete/ Inert debris": return "Non Hazardous";
      case "Asphalt": return "Non Hazardous";
      case "General Domestic/ Food waste": return "Non Hazardous";
      case "General Construction/ Mixed Waste": return "Non Hazardous";
      case "Electronic (without hazardous materials)": return "Non Hazardous";
      case "Other (non hazardous)": return "Non Hazardous";

      case "Electronic (containing hazardous materials)": return "Hazardous";
      case "Used oil": return "Hazardous";
      case "Used oil filters": return "Hazardous";
      case "Contaminated material (including contaminated pads, rags, gloves and soil)": return "Hazardous";
      case "Batteries": return "Hazardous";
      case "Medical": return "Hazardous";
      case "Other (hazardous)": return "Hazardous";
      default: return null
    }
  }


  function handleYearFilterSelect(val: string) {
    let filteredRows;
    if (val === 'ALL') {
      filteredRows = updatedData; // No filter applied
    } else {
      filteredRows = updatedData.filter((item: any) => item.Year === val);
    }
    setNewData(filteredRows);
    setSelectedYear(val);
  }
  async function handleDataUpdate(item: any) {

    if (item.ID) {
      try {
        const accessToken = await getAccessToken();
        const result = await getItemById(logTitle, accessToken, clientAPIURL, siteId, item.ID)
        let data = updatedData.filter((oItem: any) => oItem.ID !== item.ID);
        const Date = result.items[0] && returnDate(result.items[0].Date)
        const newItem = { ...result.items[0], Date }
        let JoinCreatedItem = [...data, newItem];
        setNewData(JoinCreatedItem);
      }
      catch (ex) {
        console.log(ex)
      }
    }
  }
  const uniqueYearsArray = [...new Set(updatedData.map((item: any) => item.Year))];
  const uniquePeriod = [...new Set(newData.map((item) => item.Period))];
  const uniqueDates = [...new Set(newData.map((item) => item.Date))];
  const filteredData = newData.filter(item => item.QuantityTonne !== null && item.QuantityTonne !== '');


  const uniqueDisposalMethods = [...new Set(filteredData.map(item => item.WasteDisposalMethod))].filter(type => type !== null);
  const lineChartData: any = {};
  const isValidNumber = (value: number) => {
    return typeof value === 'number' && !isNaN(value) && isFinite(value);
  };
  uniqueDisposalMethods.forEach((method) => {
    lineChartData[method] = [];
  });

  uniquePeriod.forEach((val) => {
    uniqueDisposalMethods.forEach((method) => {
      const filteredDataByMethod = filteredData.filter((item) => item.Period === val && item.WasteDisposalMethod === method);
      const sum = filteredDataByMethod.reduce((acc, item) => isValidNumber(item.QuantityTonne) ? acc + item.QuantityTonne : acc, 0);
      lineChartData[method].push(sum);
    });
  });
  const colorScaleLine = scaleOrdinal(schemeCategory10);
  const datasets = Object.entries(lineChartData).map(([method, values], index) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLine(index.toString()) + Math.round(opacity * 255).toString(16),
  }));

  const lineData = {
    labels: [...new Set(updatedData.map((item: { Period: any; }) => item.Period))],
    datasets: datasets
  };
  const legendData2 = [...uniqueDisposalMethods].map((type: any, index: any) => ({
    name: type,
  }));



  const uniqueWasteWaterType = [...new Set(filteredData.map(item => item.WasteCategory))].filter(type => type !== null);
  const lineChartDataByType: any = {};
  uniqueWasteWaterType.forEach((method) => {
    lineChartDataByType[method] = [];
  });

  uniquePeriod.forEach((val) => {
    uniqueWasteWaterType.forEach((method) => {
      const filteredDataByMethod = filteredData.filter((item) => item.Period === val && item.WasteCategory === method);
      const sum = filteredDataByMethod.reduce((acc, item) => isValidNumber(item.QuantityTonne) ? acc + item.QuantityTonne : acc, 0);
      lineChartDataByType[method].push(sum);
    });
  });
  const colorScaleLineForType = scaleOrdinal(schemeCategory10);
  const datasetsByType = Object.entries(lineChartDataByType).map(([method, values], index) => ({
    data: values,
    strokeWidth: 2,
    color: (opacity = 1) => colorScaleLineForType(index.toString()) + Math.round(opacity * 255).toString(16),
  }));

  const lineDataByType = {
    labels: [...new Set(updatedData.map((item: { Period: any; }) => item.Period))],
    datasets: datasetsByType
  };
  const legendData1 = [...uniqueWasteWaterType].map((type: any, index: any) => ({
    name: type,
  }));


  const pieData: any = {};

  newData.forEach((item: any) => {
    const wasteCategory = item.WasteCategory;
    const quantityTonne = item.QuantityTonne || 0;

    if (pieData[wasteCategory]) {
      pieData[wasteCategory] += quantityTonne;
    } else {
      pieData[wasteCategory] = quantityTonne;
    }
  });
  const colorScale1 = scaleSequential().domain([0, 1]).interpolator(interpolateOranges);
  const byWasteCategoryPieData = Object.keys(pieData).map((wasteCategory, index) => ({
    name: wasteCategory,
    value: pieData[wasteCategory],
    color: colorType5(index.toString()),//colorScale1(pieData[wasteCategory] / Math.max(...Object.values(pieData) as number[])),
  }));


  const pieData2: any = {};
  newData.forEach((item: any) => {
    const disposalMethod = item.WasteDisposalMethod;
    const quantityTonne = item.QuantityTonne || 0;

    if (pieData2[disposalMethod]) {
      pieData2[disposalMethod] += quantityTonne;
    } else {
      pieData2[disposalMethod] = quantityTonne;
    }
  });
  const colorScale2 = scaleSequential().domain([0, 1]).interpolator(interpolateCool);
  const byWasteDisposalMethodPieData = Object.keys(pieData2).map((wasteDestination, index) => ({
    name: wasteDestination,
    value: pieData2[wasteDestination],
    color: colorType3(index.toString()),//colorScale2(pieData2[wasteDestination] / Math.max(...Object.values(pieData2) as number[])),
  }));


  const pieData3: any = {};
  const nonHazardousData = newData.filter((a) => a.Hazardous == 'Non Hazardous');
  nonHazardousData.forEach((item: any) => {
    const wasteType = item.WasteType;
    const quantityTonne = item.QuantityTonne || 0;

    if (pieData3[wasteType]) {
      pieData3[wasteType] += quantityTonne;
    } else {
      pieData3[wasteType] = quantityTonne;
    }
  });
  const colorScale3 = scaleSequential().domain([0, 1]).interpolator(interpolateGnBu);
  const byTypeNonHazardousPieData = Object.keys(pieData3).map((wasteType, index) => ({
    name: wasteType,
    value: pieData3[wasteType],
    color: colorType5(index.toString()),//colorScale3(pieData3[wasteType] / Math.max(...Object.values(pieData3) as number[])),
  }));


  const pieData4: any = {};
  const HazardousData = newData.filter((a) => a.Hazardous == 'Hazardous');
  HazardousData.forEach((item: any) => {
    const wasteType = item.WasteType;
    const quantityTonne = item.QuantityTonne || 0;

    if (pieData4[wasteType]) {
      pieData4[wasteType] += quantityTonne;
    } else {
      pieData4[wasteType] = quantityTonne;
    }
  });
  const colorScale4 = scaleSequential().domain([0, 1]).interpolator(interpolateBuPu);
  const byTypeHazardousPieData = Object.keys(pieData4).map((wasteType, index) => ({
    name: wasteType,
    value: pieData4[wasteType],
    color: colorType3(index.toString()),//colorScale4(pieData4[wasteType] / Math.max(...Object.values(pieData4) as number[])),
  }));


  const pieData5: any = {};
  //const HazardousData = newData.filter((a) => a.Diverted == 'Diverted');
  newData.forEach((item: any) => {
    const diverted = item.Diverted;
    const quantityTonne = item.QuantityTonne || 0;

    if (pieData5[diverted]) {
      pieData5[diverted] += quantityTonne;
    } else {
      pieData5[diverted] = quantityTonne;
    }
  });
  const colorScale5 = scaleSequential().domain([0, 1]).interpolator(interpolateBrBG);
  const byDivertedPieData = Object.keys(pieData5).map((diverted, index) => ({
    name: diverted,
    value: pieData5[diverted],
    color: colorType5(index.toString()),//colorScale5(pieData5[diverted] / Math.max(...Object.values(pieData5) as number[])),
  }));

  /** Start of Table 1 **/
  const wasteWaterDisposalMethodLabels = wasteLogColumns.find((col: any) => col.columnName === 'WasteDisposalMethod')?.choiceChoices;
  const wasteDisposalMethodTable = wasteWaterDisposalMethodLabels.split("#;#").filter((value: string) => value !== "undefined");
  const groupedDataTable1 = newData.filter(item => item.DisposalMethod != '' || item.DisposalMethod != null).reduce((result, item) => {
    const { Period, DisposalMethod, QuantityTonne } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      wasteDisposalMethodTable.forEach((disposalMethod: string) => {
        result[Period][disposalMethod] = 0; // Initialize with 0 for each DisposalMethod
      });
    }
    result[Period][DisposalMethod] += Math.round(QuantityTonne);
    return result;
  }, {});



  interface GroupedDataItem {
    Period: any;
    [key: string]: number | null;
  }

  const groupedDataTable1Array: GroupedDataItem[] = Object.values(groupedDataTable1);
  const fData = groupedDataTable1Array.map(item => {
    const newItem = { ...item };
    delete newItem.undefined;
    return newItem;
  });


  const periods = [...new Set(newData.map(oItem => oItem.Period))];
  const filteredDataByPeriod = periods.map(Period => {
    const filteredItems = newData.filter(item => item.Period === Period);
    const TotalHaz = filteredItems.filter(item => item.WasteCategory === "Hazardous");
    const TotalNonHaz = filteredItems.filter(item => item.WasteCategory === "Non Hazardous");
    const itemCount = filteredItems.filter(item => item.WasteCategory === "Hazardous" || item.WasteCategory === "Non Hazardous").length;//parseFloat(TotalHaz.length) + parseFloat(TotalNonHaz.length);
    const sumQuantityTonne = filteredItems.reduce((sum, item) => sum + item.QuantityTonne, 0);
    const sumHazardousQuantityTonne = TotalHaz.reduce((sum, item) => sum + item.QuantityTonne, 0);
    const sumNonHazardousQuantityTonne = TotalNonHaz.reduce((sum, item) => sum + item.QuantityTonne, 0);
    const sumNotDivertedQuantityTonne = filteredItems.filter(item => item.Diverted === "Not Diverted").reduce((sum, item) => sum + item.QuantityTonne, 0);
    const sumDivertedQuantityTonne = filteredItems.filter(item => item.Diverted === "Diverted").reduce((sum, item) => sum + item.QuantityTonne, 0);

    //const filteredItems1 = groupedDataTable1.filter((item: { Period: any; }) => item.Period === Period);
    const filteredItems1 = fData.filter(item => item.Period === Period);

    let TotalPercentage = (((sumDivertedQuantityTonne) / (sumDivertedQuantityTonne + sumNotDivertedQuantityTonne)) * 100).toFixed(0);
    if (TotalPercentage == 'NaN') { TotalPercentage = "0" };
    //let TotalPercentage = (sumQuantityTonne/itemCount).toFixed(2);

    const result = [
      ["Period", filteredItems1[0]?.Period], // Assuming the filtered data has only one item with the desired period
      ["Number Of Samples", itemCount],
      ["Total", sumQuantityTonne],
      ["Hazardous", sumHazardousQuantityTonne],
      ["Non Hazardous", sumNonHazardousQuantityTonne],
      // Add other properties in the desired order
      ["Total Not Diverted (Tonnes)", sumNotDivertedQuantityTonne],
      ["Total Diverted (Tonnes)", sumDivertedQuantityTonne],
      ["Percentage", TotalPercentage],
    ];

    filteredItems1.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key !== "Period") {
          result.push([key, value]);
        }
      });
    });

    return Object.fromEntries(result);


  });


  // Convert the grouped data object into an array and calculate the sum for each DisposalMethod within each Period
  const transformedDataTable1 = Object.values(filteredDataByPeriod).map((values: any) => {
    const sumBywasteDisposalMethodTable = wasteDisposalMethodTable.reduce((sum: number, wasteDisposalMethodTable: string) => sum + values[wasteDisposalMethodTable], 0);
    return {
      ...values,
      "Total (By Disposal Method)": sumBywasteDisposalMethodTable
    };
  });

  const combinedData1: { [key: string]: { [key: string]: number } } = {};
  // Merge datasets based on Period
  filteredDataByPeriod.forEach(item => {
    const { Period, ...rest } = item;
    combinedData1[Period] = { ...combinedData1[Period], ...rest };
  });

  // Convert the combinedData object back to an array
  const mergedData1 = Object.entries(combinedData1).map(([Period, values]) => ({
    Period,
    ...values
  }));
  const columnKeys1 = mergedData1.length > 0 ? Object.keys(mergedData1[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];

  /** End of Table 1 **/





  /** Start of Table 2 **/

  //const choices = wasteLogColumns.find((col:any) => col.columnName === 'WasteType')?.choiceChoices;
  // choices below hard coded.
  //const NonHazardData = newData.filter((a) => a.Diverted == 'Diverted');
  const choices = "Paper/ Cardboard#;#Plastic#;#Metal#;#Wood#;#Green#;#Concrete/ Inert debris#;#Asphalt#;#General Domestic/ Food waste#;#General Construction/ Mixed Waste#;#Electronic (without hazardous materials)#;#Electronic (containing hazardous materials)#;#Used oil#;#Used oil filters#;#Contaminated material (including contaminated pads#;#rags#;#gloves and soil)#;#Batteries#;#Medical#;#Other (hazardous)#;#Other (non hazardous)";

  const wasteTypesTable = choices.split("#;#").reduce((filteredValues: any, value: any) => {
    let isHaz = checkHazardous(value);

    if (isHaz === "Non Hazardous") {
      filteredValues.push(value);
    }

    return filteredValues;
  }, []);

  const groupedDataTable = newData.filter((oItem) => oItem.Hazardous === 'Non Hazardous').reduce((result, item) => {
    const { Period, WasteType, QuantityTonne } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      wasteTypesTable.forEach((wasteType: any) => {
        result[Period][wasteType] = 0; // Initialize with 0 for each WastewaterType
      });
    }
    result[Period][WasteType] += Math.round(QuantityTonne);
    return result;
  }, {});

  // Convert the grouped data object into an array and calculate the sum for each WastewaterType within each Period
  const transformedDataTable = Object.values(groupedDataTable).map((values: any) => {
    const sumBywasteType = wasteTypesTable.reduce((sum: number, wasteType: string) => sum + values[wasteType], 0);
    return {
      ...values,
      "Total (By Waste Type)": sumBywasteType
    };
  });
  const combinedData: { [key: string]: { [key: string]: number } } = {};
  // Merge datasets based on Period
  transformedDataTable.forEach(item => {
    const { Period, ...rest } = item;
    combinedData[Period] = { ...combinedData[Period], ...rest };
  });
  // Convert the combinedData object back to an array
  const mergedData = Object.entries(combinedData).map(([Period, values]) => ({
    Period,
    ...values
  }));
  const columnKeys = mergedData.length > 0 ? Object.keys(mergedData[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];

  /** End of Table 2 **/

  /** Start of Table 3 **/

  const wasteTypesTableHazardous = choices.split("#;#").reduce((filteredValues: any, value: any) => {
    let isHaz = checkHazardous(value);

    if (isHaz === "Hazardous") {
      filteredValues.push(value);
    }

    return filteredValues;
  }, []);

  const groupedDataTable2 = newData.filter((oItem) => oItem.Hazardous === 'Hazardous').reduce((result, item) => {
    const { Period, WasteType, QuantityTonne } = item;
    if (!result[Period]) {
      result[Period] = { Period };
      wasteTypesTableHazardous.forEach((wasteTypeHazard: any) => {
        result[Period][wasteTypeHazard] = 0; // Initialize with 0 for each DisposalMethod
      });
    }
    let isHaz = checkHazardous(WasteType);

    //if (isHaz === "Hazardous") {
    result[Period][WasteType] += Math.round(QuantityTonne).toString() === 'NaN' ? 0 : Math.round(QuantityTonne);
    return result;
    // }
    // else return

  }, {});


  // Convert the grouped data object into an array and calculate the sum for each DisposalMethod within each Period
  const transformedDataTable2 = Object.values(groupedDataTable2).map((values: any) => {
    const sumBywasteTypeHazard = wasteTypesTableHazardous.reduce((sum: number, wasteTypeHazard: string) => sum + values[wasteTypeHazard], 0);
    return {
      ...values,
      "Total (By Waste Type)": sumBywasteTypeHazard
    };
  });
  const combinedData2: { [key: string]: { [key: string]: number } } = {};
  // Merge datasets based on Period
  transformedDataTable2.forEach(item => {
    const { Period, ...rest } = item;
    combinedData2[Period] = { ...combinedData2[Period], ...rest };
  });
  // Convert the combinedData object back to an array
  const mergedData2 = Object.entries(combinedData2).map(([Period, values]) => ({
    Period,
    ...values
  }));
  const columnKeys2 = mergedData2.length > 0 ? Object.keys(mergedData2[0]).map((key, index) => {
    return {
      ColumnName: key,
      Order: index,
    };
  }) : [];

  /** End of Table 3 **/



  const uniqueYears = uniqueYearsArray && uniqueYearsArray.filter(item => item !== null).sort((a: any, b: any) => b - a);
  const yearFilterOptions = [
    {
      label: 'ALL',
      value: 'ALL',
    },
    ...new Set(uniqueYears.map((item) => {
      return {
        label: item,
        value: item,
      };
    }))]
  const TotalCount = newData.reduce((total, item) => {
    if (item.QuantityTonne) {
      return total + item.QuantityTonne;
    }
    return total;
  }, 0);
  return (
    <View style={logStyles.logRoot}>
      <TabsProvider defaultIndex={0}>
        <Tabs style={{ backgroundColor: '#fff', height: "100%" }} showLeadingSpace={true} >
          <TabScreen label="Charts" icon="chart-bar" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View style={[logStyles.container]} key={0}>
                <LogCountCard cardTitle={'Total Waste (Tonnes)'} number={Math.round(TotalCount)} />
                <View style={[logStyles.card, { justifyContent: 'center' }]}>
                  <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                </View>
              </View>
              <View>
                <View style={logStyles.container}>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={byWasteCategoryPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne)'} ChartTitle={"Waste Qty / Category"} /></View>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData1} data={lineDataByType} label={uniquePeriod} subTitle={'(Monthly - Tonne)'} ChartTitle={"Waste Qty / Category"} /></View>

                  <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={byWasteDisposalMethodPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne)'} ChartTitle={"Waste Qty / (Destination)"} /></View>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><LineChartScreen legend={legendData2} data={lineData} label={uniquePeriod} subTitle={'(Monthly - Tonne)'} ChartTitle={"Waste Qty / Destination"} /></View>

                  <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={byTypeNonHazardousPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne, %)'} ChartTitle={"Non Hazardous Waste Qty / Type"} /></View>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={byTypeHazardousPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne, %)'} ChartTitle={"Hazardous Waste Qty / Type"} /></View>
                  <View style={[logStyles.card, { backgroundColor: '' }]}><PieChartComp data={byDivertedPieData} colorPallate={interpolateBlues} subTitle={'(Cumulative - Tonne, %)'} ChartTitle={"Waste Diversion"} /></View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
          <TabScreen label="Tables" icon="table" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              <View style={[logStyles.container]} key={0}>
                <View style={logStyles.cardTwoFilter}>
                  <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                </View>
              </View>
              <View>
                <View style={logStyles.container}>
                  <View style={logStyles.cardThree}>
                    {mergedData1 && columnKeys1 ? (
                      <CustomDT
                        data={mergedData1}
                        columns={columnKeys1}
                        siteId={siteId}
                        logTitle={logTitle}
                        tableTitle={"Waste Quantities (Tonne) & Waste Diversion"}
                      />
                    ):null
                    }
                  </View>

                  <View style={logStyles.cardThree}>
                    {mergedData && columnKeys ? (
                      <CustomDT
                        data={mergedData}
                        columns={columnKeys}
                        siteId={siteId}
                        logTitle={logTitle}
                        tableTitle={"Non-Hazardous Waste Quantities (Tonne)"}
                      />
                    ):null
                    }
                  </View>

                  <View style={logStyles.cardThree}>
                    {mergedData2 && columnKeys2 ? (
                      <CustomDT
                        data={mergedData2}
                        columns={columnKeys2}
                        siteId={siteId}
                        logTitle={logTitle}
                        tableTitle={"Hazardous Waste Quantities (Tonne)"}
                      />
                    ):null
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
          <TabScreen label="List" icon="database" >
            <ScrollView contentContainerStyle={logStyles.scrollViewContentContainer}>
              {showFilter === true ?
                <View style={[logStyles.container]} key={0}>
                  <View style={logStyles.cardTwoFilter}>
                    <LogFilter options={yearFilterOptions} label={"Filter by Year"} onSelect={handleYearFilterSelect} selected={""} />
                  </View>
                </View> : null
              }
              <View>
                <View style={logStyles.container}>
                  <View style={logStyles.cardThree}>
                    {
                      setNewData &&
                      newData &&
                      uniqueYearsArray &&
                      ColumnsWithIDColumn && (
                        <PaperTableDynamic
                          data={newData}
                          columns={ColumnsWithIDColumn}
                          siteId={siteId}
                          logTitle={logTitle}
                          updateData={handleDataUpdate}
                          isConsolidatedTable={false}
                          funcSetShowFilter={setShowFilter}
                        />
                      )
                    }
                  </View>
                </View>
              </View>
            </ScrollView>
          </TabScreen>
        </Tabs>
      </TabsProvider>
    </View>
  );
};

