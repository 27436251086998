import { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet, View, Text, Platform, Linking, Image, Touchable, TouchableOpacity, Pressable, Modal } from 'react-native';
import MapView, { Circle, Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import { ClusterProps, MarkerClusterer } from '@teovilla/react-native-web-maps';
import type { Region } from 'react-native-maps';
import { IconButton, MD3Colors, Tooltip } from 'react-native-paper';
import Icon from 'react-native-paper/src/components/Icon';

function MyClusterComponent(props: ClusterProps<{ onPress(): void }>) {
  return (
    <Marker
      onPress={props.onPress}
      coordinate={props.coordinate}
      anchor={{ x: 0.5, y: 0.5 }}
    >
      <View style={styles.cluster}>
        <Text style={styles.clusterText}>{props.pointCountAbbreviated}</Text>
      </View>
    </Marker>
  );
}

export default function GeoMap(props: any) {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState <any> (null);
  const [region, setRegion] = useState<Region | null>(null);
  const firstItem = props.data[0] && JSON.parse(props.data[0].Location);
  const mapRef = useRef<MapView>(null);

  const loadingFallback = useMemo(() => {
    return (
      <View>
        <Text>Loading</Text>
      </View>
    );
  }, []);
  const initialRegion: Region = {
    // latitude: 24.718676652985327,//firstItem.coords.latitude, // Initial latitude
    // longitude: 46.58165245157112,// Initial longitude
    latitude: firstItem && firstItem.coords.latitude, // Initial latitude
    longitude:firstItem && firstItem.coords.longitude,// Initial longitude
    latitudeDelta: 0.5, // Zoom level. Smaller values zoom in.
    longitudeDelta: 0.5, // Zoom level. Smaller values zoom in.
  };
  const GoToLocation = (latitude:any, longitude:any) => {
    try {
      //const latitude = e.nativeEvent.coordinate.latitude;
      //const longitude = e.nativeEvent.coordinate.longitude;
      //const locationData = JSON.parse(e.nativeEvent.coordinate);
      //console.log(JSON.stringify(locationData));
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        //const scheme = Platform.select({ ios: 'maps://0,0?q=', android: 'geo:0,0?q=' });
        const scheme = Platform.select({ ios: 'comgooglemaps://0,0?q=', android: 'geo:0,0?q=' });
        const latLng = `${latitude},${longitude}`;
        const label = 'Observation Location';
        const url = Platform.select({
          ios: `${scheme}${label}@${latLng}`,
          android: `${scheme}${latLng}(${label})`
        });
        Linking.openURL(url);
      }
      else if (Platform.OS === 'web') {
        const googleMapLink = latitude && longitude && `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(googleMapLink, '_blank');
      }
    }
    catch (error) {
      // Handle promise rejection
      console.error('An error occurred:', error);
    }
  }
  let selectedObv = null;
  const selectedObservation = (obv:any) =>{
    selectedObv = obv;
  }
  const GetMarker: any = (d: any) => {
    try {
      return d.map((item: any, index: number) => {
        const locationData = item.Location && JSON.parse(item.Location);
        let markerColor = item.ObservationType === "Good Practice/ Strong Point"? "gray": item.ObservationType ==='Findings'?"yellow":"salmon";
        //console.log(JSON.stringify(locationData));
        return (
          <Marker
            anchor={{ x: 0.5, y: 1 }}
            key={index}
            coordinate={{
              latitude: locationData.coords.latitude,
              longitude: locationData.coords.longitude,
            }}
            onPress={() => {setModalVisible(true); setModalContent(item)}}
          >
            <View
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                borderRadius: 999,
                backgroundColor: markerColor,
                position: 'relative',
                zIndex: 19,
              }}
            >
              <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                  setModalVisible(!modalVisible);
                }}>
                <View style={[styles.centeredView]}>
                  <View style={styles.modalView}>
                    <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end', alignSelf:'flex-end' }}>
                      <IconButton icon="close-circle-outline" iconColor={MD3Colors.error50} size={20} onPress={() => { setModalVisible(!modalVisible) }} />
                    </View>
                    {
                      modalContent &&
                      <View style={{flexDirection:'column'}}>
                        {modalContent.packageName && <Text style={styles.modalText}>Package: {modalContent.packageName}</Text>}
                        <Text style={styles.modalText}>Type: {modalContent.ObservationType}</Text>
                        <Text style={styles.modalText}>Due: {modalContent.DueDate}</Text>
                        <Text style={styles.modalText}>Status: {modalContent.Status}</Text>
                        <Text style={styles.modalText}>Reference Number: {modalContent.ReferenceNumber}</Text>
                      </View>
                    }
                    <View style={{flexDirection:'row', gap:20, padding: 20}}>
                    <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => {Linking.openURL("https://dev.greenport.keo.com/clients/dgda/site/greenport-dgda-ss01-TrainingObservations-EnvironmentalIssuesObservations/list?ID=569", '_blank')}}>
                        <Text style={styles.textStyle}>View Observation</Text>
                      </Pressable>
                      <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => { GoToLocation(locationData.coords.latitude, locationData.coords.longitude) }}>
                        <Text style={styles.textStyle}>Go to Location</Text>
                      </Pressable>
                    </View>
                  </View>
                </View>
              </Modal>
              {/* <Tooltip title={item.packageName ? item.packageName + ", " + item.ObservationType + "," + item.DueDate:item.ObservationType + "," + item.DueDate}> */}
              <Pressable 
              onPress={
                //()=>GoToLocation(locationData.coords.latitude, locationData.coords.longitude)
                () => {setModalVisible(true); setModalContent(item)}
                }>
                <Text>{item.ReferenceNumber ? item.ReferenceNumber : item.ID}</Text>
              </Pressable>
              {/* </Tooltip> */}
              
              <View
                style={{
                  position: 'absolute',
                  width: 10,
                  height: 10,
                  backgroundColor: markerColor,
                  zIndex: 20,
                  bottom: -5,
                  alignSelf: 'center',
                  transform: [{ rotate: '45deg' }],
                }}
              ></View>
            </View>
          </Marker>
        )
      })
    }
    catch (error) {
      // Handle promise rejection
      console.error('An error occurred:', error);
    }
  }
  return (
    Platform.OS === 'web'?
    <View style={styles.container}>
      <MapView
        ref={mapRef}
        provider={'google'}
        mapType={'satellite'}
        style={{ flex: 1 }}
        onRegionChange={setRegion}
        googleMapsApiKey={'AIzaSyCODdOaDzGt90wU9UPzKvqvj0Rz8tihjnw'}
        //loadingFallback={loadingFallback}
        initialRegion={initialRegion}
      >
        <MarkerClusterer
          region={region}
          renderCluster={(cluster) => (
            <MyClusterComponent
              {...cluster}
              onPress={() =>
                mapRef.current?.animateCamera({
                  center: cluster.coordinate,
                  zoom: cluster.expansionZoom + 3,
                })
              }
            />
          )}
        >
         {props.data && GetMarker(props.data)} 
          {/* <Circle
                center={{
                  latitude: 24.71840377103672,
                  longitude: 46.58240347005529,
                }}
                fillColor="rgba(0,0,0,0.5)"
                radius={100}
              /> */}
          <Marker
            anchor={{ x: 0.5, y: 1 }}
            title='You are here'
            coordinate={{
              latitude: 24.71840377103672,
              longitude: 46.58240347005529,
            }}
          >
            <View
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                borderRadius: 999,
                backgroundColor: 'salmon',
                position: 'relative',
                zIndex: 19,
              }}
            >
              <View
                style={{
                  position: 'absolute',
                  width: 10,
                  height: 10,
                  backgroundColor: 'salmon',
                  zIndex: 20,
                  bottom: -5,
                  alignSelf: 'center',
                  transform: [{ rotate: '45deg' }],
                }}
              ></View>
              {/* <Image
                style={{width:35, height:35}}
                source={require('')}
              /> */}
              <Text>You</Text>
            </View>
            {Platform.select({
              native: <View style={{ height: 7 }}></View>,
            })}
          </Marker>
          {/* <Marker
            key={1}
            title='Package 1'
            description='Waste Tracking'
            coordinate={{
              latitude: 24.71840377103672,
              longitude: 46.58240347005529,
            }}
            onPress={(e => {GoToLocation(e)})}
          />
          <Marker
              key={2}
              title='Package 2'
              description='Waste Tracking'
              coordinate={{
                latitude: 24.718053399224416,
                longitude: 46.58160564730828,
              }}
              onPress={(e => {GoToLocation(e)})}
            />
            <Marker
              key={3}
              title='Package 1'
              description='Hazardous Material'
              coordinate={{
                latitude: 24.71822145122344,
                longitude: 46.581921427793866,
            }} 
            onPress={(e => {GoToLocation(e)})}
            />
          <Marker
            key={4}
            title='SWL'
            description='Good Practice'
            coordinate={{
              latitude: 24.717977950043597,
              longitude: 46.581779706219244,
            }}
            onPress={(e => {GoToLocation(e)})}
          />
          <Marker
            key={5}
            title='SWL'
            description='Waste Water'
            coordinate={{
              latitude: 24.71809190522003, 
              longitude: 46.58198504547125,
            }}
            onPress={(e => {GoToLocation(e)})}
          /> */}
          
        </MarkerClusterer>
        {/* {SHOW_LOCATION_BUBBLE && position && (
            <>
              <Marker
                anchor={{ x: 0.5, y: 1 }}
                coordinate={{
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                }}
              >
                <View
                  style={{
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 999,
                    backgroundColor: 'salmon',
                    position: 'relative',
                    zIndex: 19,
                  }}
                >
                  <View
                    style={{
                      position: 'absolute',
                      width: 10,
                      height: 10,
                      backgroundColor: 'salmon',
                      zIndex: 20,
                      bottom: -5,
                      alignSelf: 'center',
                      transform: [{ rotate: '45deg' }],
                    }}
                  ></View>
                  <Text>You are here</Text>
                </View>
                {Platform.select({
                  native: <View style={{ height: 7 }}></View>,
                })}
              </Marker>
              <Circle
                center={{
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                }}
                fillColor="rgba(0,0,0,0.5)"
                radius={100}
              />
            </>
          )} */}
      </MapView>
    </View>

    :
    <View style={styles.container}>
      <MapView
        ref={mapRef}
        provider={'google'}
        style={{ flex: 1 }}
        onRegionChange={setRegion}
        //loadingFallback={loadingFallback}
        initialRegion={initialRegion}
        mapType={'satellite'}
      >
        
        <MarkerClusterer
          region={region}
          renderCluster={(cluster) => (
            <MyClusterComponent
              {...cluster}
              onPress={() =>
                mapRef.current?.animateCamera({
                  center: cluster.coordinate,
                  zoom: cluster.expansionZoom + 3,
                })
              }
            />
          )}
        >
         {props.data && GetMarker(props.data)} 
          {/* <Circle
                center={{
                  latitude: 24.71840377103672,
                  longitude: 46.58240347005529,
                }}
                fillColor="rgba(0,0,0,0.5)"
                radius={100}
              /> */}
          <Marker
            anchor={{ x: 0.5, y: 1 }}
            title='You are here'
            coordinate={{
              latitude: 24.71840377103672,
              longitude: 46.58240347005529,
            }}
          >
            <View
              style={{
                paddingHorizontal: 10,
                paddingVertical: 5,
                borderRadius: 999,
                backgroundColor: 'salmon',
                position: 'relative',
                zIndex: 19,
              }}
            >
              <View
                style={{
                  position: 'absolute',
                  width: 10,
                  height: 10,
                  backgroundColor: 'salmon',
                  zIndex: 20,
                  bottom: -5,
                  alignSelf: 'center',
                  transform: [{ rotate: '45deg' }],
                }}
              ></View>
              {/* <Image
                style={{width:35, height:35}}
                source={require('')}
              /> */}
              <Text>You</Text>
            </View>
            {Platform.select({
              native: <View style={{ height: 7 }}></View>,
            })}
          </Marker>
          {/* <Marker
            key={1}
            title='Package 1'
            description='Waste Tracking'
            coordinate={{
              latitude: 24.71840377103672,
              longitude: 46.58240347005529,
            }}
            onPress={(e => {GoToLocation(e)})}
          />
          <Marker
              key={2}
              title='Package 2'
              description='Waste Tracking'
              coordinate={{
                latitude: 24.718053399224416,
                longitude: 46.58160564730828,
              }}
              onPress={(e => {GoToLocation(e)})}
            />
            <Marker
              key={3}
              title='Package 1'
              description='Hazardous Material'
              coordinate={{
                latitude: 24.71822145122344,
                longitude: 46.581921427793866,
            }} 
            onPress={(e => {GoToLocation(e)})}
            />
          <Marker
            key={4}
            title='SWL'
            description='Good Practice'
            coordinate={{
              latitude: 24.717977950043597,
              longitude: 46.581779706219244,
            }}
            onPress={(e => {GoToLocation(e)})}
          />
          <Marker
            key={5}
            title='SWL'
            description='Waste Water'
            coordinate={{
              latitude: 24.71809190522003, 
              longitude: 46.58198504547125,
            }}
            onPress={(e => {GoToLocation(e)})}
          /> */}
          
        </MarkerClusterer>
        {/* {SHOW_LOCATION_BUBBLE && position && (
            <>
              <Marker
                anchor={{ x: 0.5, y: 1 }}
                coordinate={{
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                }}
              >
                <View
                  style={{
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    borderRadius: 999,
                    backgroundColor: 'salmon',
                    position: 'relative',
                    zIndex: 19,
                  }}
                >
                  <View
                    style={{
                      position: 'absolute',
                      width: 10,
                      height: 10,
                      backgroundColor: 'salmon',
                      zIndex: 20,
                      bottom: -5,
                      alignSelf: 'center',
                      transform: [{ rotate: '45deg' }],
                    }}
                  ></View>
                  <Text>You are here</Text>
                </View>
                {Platform.select({
                  native: <View style={{ height: 7 }}></View>,
                })}
              </Marker>
              <Circle
                center={{
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                }}
                fillColor="rgba(0,0,0,0.5)"
                radius={100}
              />
            </>
          )} */}
      </MapView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cluster: {
    backgroundColor: 'salmon',
    width: 20,
    height: 20,
    borderRadius: 999,
    alignItems: 'center',
    justifyContent: 'center',
  },
  clusterText: {
    fontWeight: '700',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    //margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    //padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 8,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});