import * as SecureStore from 'expo-secure-store';

const nativeStorage = {
  setStringAsync: (key: string, value: string): Promise<void> => {
    return SecureStore.setItemAsync(key, value);
  },

  getStringAsync: (key: string): Promise<string | null> => {
    return SecureStore.getItemAsync(key);
  },

  deleteStringAsync: (key: string): Promise<void> => {
    return SecureStore.deleteItemAsync(key);
  },
};

export default nativeStorage;
