import React from 'react';
import { View, StyleSheet, Text} from 'react-native';
import { IconButton, TextInput } from 'react-native-paper';
import FormFieldTitle from './FormFieldTitle';
interface TextFieldProps {
  field: any;
  control: any;
  editable: boolean;
  styles: any;
  Ctrl: any;
  errors:any;
  logTitle?:any;
}
const styles = StyleSheet.create({
  TextField: {
    borderWidth: 0.5, // Set the border width
    borderColor: 'gray', // Set the border color
    borderRadius: 5, // Set the border radius (optional)

    shadowColor: 'black', // Set the shadow color
    shadowOffset: { width: 0, height: 2 }, // Set the shadow offset
    shadowOpacity: 0.5, // Set the shadow opacity
    shadowRadius: 4, // Set the shadow radius
    elevation: 4
  }
})
const TextField: React.FC<TextFieldProps> = ({ field, control, editable, styles, Ctrl, errors, logTitle }) => {
  const rule = field.required ? { required: field.label + ' is required' } : { required: false};
  //const fieldTitle = field.required ? field.label + ' *' : field.label;

  return (
    <View>
      <View style={{ width: '100%' }}>{ <FormFieldTitle field={field}/> }</View>
      
      {editable === false ? <View><Text style={{fontWeight:'normal', fontSize:12}}>{field.defaultValue ?? null}</Text></View> :
      <Ctrl
        key={field.key}
        control={control}
        rules={rule}
        name={field.key}
        defaultValue={field.defaultValue && field.defaultValue}
        render={({ field: { onChange, onBlur, value } }: { field: { onChange: any, onBlur: any, value: any } }) => (
          logTitle === "Environmental Issues Observations" ?
          <TextInput
            label={null}
            value={field.key === "Location" && field.defaultValue ? field.defaultValue : value}
            onChangeText={onChange}
            style={styles}
            editable={editable}
            disabled={!editable}
            onBlur={onBlur}
            multiline={field.multiline ? true : false}
            outlineStyle={styles}
          />
          :
          <TextInput
          label={null}
          value={field.key === "Location" && logTitle === "Environmental Issues Observations" && field.defaultValue ? field.defaultValue : value}
          onChangeText={onChange}
          style={styles}
          editable={editable}
          disabled={!editable}
          onBlur={onBlur}
          multiline={field.multiline ? true : false}
          outlineStyle={styles}
        />
        )}
      />
        }
        {errors[field.key]?.message && <Text style={{ color: 'red' }}>{errors[field.key]?.message}</Text>}
    </View>
  );
};

export default TextField;