import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import { Camera, CameraType, CameraPictureOptions } from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import ButtonControl from './Button';
import { useAuth } from '../../../../providers/AuthProvider';
import { Loader } from '../../../Common/Loader';
import { RadioButton, Checkbox } from 'react-native-paper';
interface CamControl {
    setCamVisible: any;
    onFileUploaded: any;
    clientAPIURL: string;
}
const CameraScreen: React.FC<CamControl> = ({ setCamVisible, onFileUploaded, clientAPIURL }) => {
    const [cameraPermission, setCameraPermission] = useState(null);
    const [imageUri, setImageUri] = useState(null);
    const [image, setImage] = useState(null);
    const { getAccessToken, accessToken } = useAuth();
    const cameraRef = useRef(null);
    const [isLoading, setLoading] = useState(false);
    const [quality, setQuality] = React.useState('Compress');

    useEffect(() => {
        (async () => {
            const { status } = await Camera.requestCameraPermissionsAsync();
            setCameraPermission(status === 'granted');

            if (Platform.OS !== 'web') {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== 'granted') {
                    alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
    }, []);

    const takePicture = async () => {
        if (cameraRef.current) {
            const pictureOptions: CameraPictureOptions = {
                quality: quality === 'HD'? 0.9: 0.1, // Adjust the quality as needed (0 to 1)
              };
            const photo = await cameraRef.current.takePictureAsync(pictureOptions);
            //console.log(pictureOptions.quality + " / " +photo);
            setImage(photo);
            setImageUri(photo.uri);
        }
    };

    const pickImage = async () => {
        const result: any = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
            base64: true, // Ensure to set base64 to true to get the image as binary
        });

        if (!result.cancelled) {
            setImageUri(result.uri);
            console.log(result.uri);
        }
    };
    const uploadDoc = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        const accessToken = await getAccessToken();
        myHeaders.append("Authorization", `Bearer ${accessToken}`)
        var formdata: any = new FormData();
        let xhr = new XMLHttpRequest();

        let fileName = "image.jpg";//new Date().toString() + ".jpg";
        if (fileName.includes(' ')) {
            const modifiedName = fileName.replace(/ /g, '_');
            fileName = modifiedName;
        }

        if (Platform.OS == "ios") {
            formdata.append("file", {
                uri: imageUri,
                type: 'image/jpeg',
                name: fileName,
            });
            myHeaders.append("Content-Type", "multipart/form-data");
        }
        else {
            formdata.append("file", {
                uri: imageUri,
                type: 'image/jpeg',
                name: fileName,
            });
        }

        var requestOptions: any = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        try {
            xhr.open("POST", clientAPIURL + "/api/uploadFile");
            xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
            xhr.send(requestOptions.body);
            xhr.onload = function () {
                setLoading(false)
                if (xhr.status != 200) { // analyze HTTP response
                    console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
                } else { // show the result
                    console.log(`Done, got ${xhr.response}`); // response is the server response
                    successCall(xhr);
                }
            };

            xhr.onerror = function () {
                setLoading(false)
                console.log("Request failed", xhr.status, xhr.statusText, xhr.responseText);
            };

            xhr.onreadystatechange = function () {
                console.log("State change", xhr.readyState, xhr.status, xhr.statusText);
                if (xhr.readyState === 4) {
                    console.log("Complete response", xhr.responseText);
                }
            };
        }
        catch (ex) {
            console.log(ex);
        }
    }
    const successCall = (result: any) => {
        onFileUploaded(result.response);
        setCamVisible(false);
    }
    return (
        <View style={{ flex: 1, height: 400 }}>
            {!imageUri ?
                <>
                    <Camera
                        ref={cameraRef}
                        style={{ flex: 1 }}
                        type={CameraType.back}
                        ratio="4:3"
                    >

                    </Camera>
                    <View style={{flexDirection:'row', gap:20, justifyContent:'center', alignContent:'center'}}>
                        <View style={{flexDirection:'row',justifyContent:'center', alignContent:'center'}}>
                        <Text style={{alignSelf:'center'}} onPress={() => setQuality('HD')}>HD</Text>
                        {/* <RadioButton
                            value="HD"
                            status={quality === 'HD' ? 'checked' : 'unchecked'}
                            onPress={() => setQuality('HD')}
                            uncheckedColor={'black'}
                        /> */}
                        <View style={{backgroundColor:'silver', borderRadius:50}}>
                            <Checkbox
                                status={quality === 'HD' ? 'checked' : 'unchecked'}
                                uncheckedColor={'gray'}
                                onPress={() => setQuality('HD')} />
                        </View>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{alignSelf:'center'}} onPress={() => setQuality('Compress')}>Compress</Text>
                        {/* <RadioButton
                            value="Compress"
                            status={quality === 'Compress' ? 'checked' : 'unchecked'}
                            onPress={() => setQuality('Compress')}
                            uncheckedColor={'red'}
                        /> */}
                            <View style={{backgroundColor:'silver', borderRadius:50}}>
                                <Checkbox
                                    status={quality === 'Compress' ? 'checked' : 'unchecked'}
                                    uncheckedColor={'gray'}
                                    onPress={() => setQuality('Compress')}/>
                            </View>
                        </View>
                    </View>
                </>
                :
                <>
                    <Image source={{ uri: imageUri }} style={{ flex: 1 }} />

                </>
            }

            {imageUri ?
                isLoading === true ?
                    <Loader size={'small'} />
                    :
                    <>
                        
                        <View style={{margin:10}}>
                            <ButtonControl icon="content-save-edit" mode="contained" onPress={() => uploadDoc()} Title='Upload' />
                        </View>
                        <View style={{margin:10}}>
                            <ButtonControl icon="content-save-edit" mode="contained" onPress={() => setImageUri(null)} Title='Retake Picture' />
                        </View>
                    </>


                :
                <ButtonControl icon="content-save-edit" mode="contained" onPress={takePicture} Title='Take Picture' />
            }
        </View>
    );
}
export default CameraScreen
