import { Animated, View, StyleSheet, TouchableOpacity } from "react-native";
import { Card, FAB, Text } from 'react-native-paper';
import { ISitee } from "./ClientLanding";
import { sortByOrder } from "../services/Utils";
import Icon from 'react-native-paper/src/components/Icon'


export interface ICardGroup {
  title: string;
  sites: ISitee[];
  clickEvent: any;
}

const CardGroup: React.FC<ICardGroup> = ({ title, sites, clickEvent }) => {
  const opacityValue = new Animated.Value(0);

  const startAnimation = () => {
    Animated.timing(opacityValue, {
      toValue: 1,
      duration: 1000, // Duration of the animation in milliseconds
      useNativeDriver: true, // This is required for better performance on some devices
    }).start();
  };
  startAnimation();
  function cardClicked(item: any) {
    clickEvent(item);
  }

  return (
    <>
      {(title != 'null') && <View>
        <Text variant="headlineSmall">{title}</Text>
      </View>}
      <View style={styles.tileContainer} >
        {(sites && sites.length > 0) &&

          sortByOrder(sites).map((item, index) => (
            <>
            {!item.hidden&&
              <TouchableOpacity key={index} style={{ height: 150 }} onPress={() => {
                cardClicked(item);
              }}>
                <Animated.View

                  style={[styles.card, styles.box2, styles.cardTransformed, { flexDirection: 'row', opacity: opacityValue, backgroundColor: (item.title == "Reports" || item.type == 'report') ? '#4BB75F' : (item.type == 'list') ? '#00B8EE' : '#102B27' }]}
                >
                  <View style={{ alignContent: "center", flexDirection: 'column', justifyContent: "center", width: 70 }}>
                    <Icon size={50} source={item.icon || (item.type == 'report' ? 'file' : (item.type == 'list') ? 'view-list' : "web")} color="#fff"></Icon>
                  </View>
                  {/* <Card
                                style={[{ flex: 1, flexDirection:'row', flexWrap:'wrap', alignContent:'center', alignItems:'center', justifyContent:'space-evenly' }]}
                                onPress={() => {
                                    cardClicked(item);
                                }}
                            > */}
                  {/* <Card.Title style={{}} title={item.title} /> */}
                  <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center', alignItems: 'center', justifyContent: 'space-around' }}>
                    <Text variant="labelLarge" style={{ fontWeight: "700", color: '#fff' }}>{item.title}</Text>

                  </View>
                  {/* </Card> */}
                </Animated.View>
              </TouchableOpacity>
}
            </>
          ))}
      </View>
    </>


  );
}



const styles = StyleSheet.create({
  navWrapper: {},
  container: {
    flex: 1,
    padding: 16,
    gap: 20,
    flexDirection: "column",
    justifyContent: 'space-between',
    alignItems: 'center'

  },
  bannerContainer: {
    alignItems: 'center',
    gap: 20,
    padding: 5,
    minWidth: "90%"
  },
  tileContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',

    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "transparent",
    gap: 30,
  },
  card: {
    flex: 1,
    minWidth: 300,
    maxWidth: 300,
    marginVertical: 8,
    minHeight: 100,
    //backgroundColor: '#FFFFFF',
    borderRadius: 8,
    padding: 16,
    // Shadow properties for iOS
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    // Elevation for Android
    elevation: 5,
    //display:'flex',
    justifyContent: 'center',
    //alignItems:'center',

  },
  box2: {
    shadowColor: 'rgba(0, 0, 0, 0.5)',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    elevation: 20,
    //shadowColor: '#52006A',
  },
  // Apply the transform style when pressing the card
  cardTransformed: {
    transform: [{ translateY: 5 }],
    shadowColor: 'rgba(0, 0, 0, 0.25)',
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowOpacity: 1,
    shadowRadius: 20,
  },
  cardContainer: {
    padding: 16,
  },
  itemContainer: {
    minWidth: 300,
    maxWidth: 300,
    marginVertical: 8,
    minHeight: 100,
    backgroundColor: '#e0e0e0',
    padding: 16,
    marginBottom: 8,
    borderRadius: 8,
  },
  itemText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

export default CardGroup;




