import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import LoginButton from './LoginButton';
import { LoggedInStatus, useAuth } from '../../providers/AuthProvider';


const Login: React.FC = () => {
    const { login, loggedInStatus } = useAuth();

    // React.useEffect(() => {
    //     if (accessToken == '') {
    //         getAccessToken();
    //     }
    // }, [loggedInStatus, accessToken]);

    // const handleLoginPress = () => {
    //     login();
    // };
    return (
        <>
            {loggedInStatus == LoggedInStatus.LoggedOut && <View style={styles.buttonContainer}>
                <Image style={styles.logo} source={require('../../assets/Greenport-green.png')} />
                <LoginButton onPress={login} />
            </View>
            }
        </>
    );
};

const styles = StyleSheet.create({
    buttonContainer: {
        padding: 100,
        justifyContent: 'space-around',
        flex: 1,
    },
    logo: {
        width: 360,
        height: 50,
        resizeMode: 'contain',
    },
});

export default Login;