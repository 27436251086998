function getQuantityCalculation(listTitle: string, QuantityVal: string) {
    switch (listTitle) {
        case 'Energy Log':
            return (((parseInt(QuantityVal) * 1000).toFixed(2)).toString())

        case 'Imported Soil Log':
            return (((parseInt(QuantityVal) * 0.3531466672).toFixed(2)).toString())
        case 'Spoil Tracking Log':
            return (((parseInt(QuantityVal) * 0.3531466672).toFixed(2)).toString())
        case 'Waste Tracking Log':
            return (((parseInt(QuantityVal) * 0.3531466672).toFixed(2)).toString())

        case 'Wastewater Tracking Log':
            return (((parseInt(QuantityVal) * 264.172).toFixed(2)).toString())
        case 'Water Log':
            return (((parseInt(QuantityVal) * 264.172).toFixed(2)).toString())

        default: return null
    }
}

export {getQuantityCalculation};