import * as React from 'react';
import { View, Image } from 'react-native';
import { ActivityIndicator, MD2Colors } from 'react-native-paper';
interface prop {
size?:any;
}
const Loader: React.FC<prop> = ({size}) => {

    return (
        <View>
           { size ?
            (<ActivityIndicator size={size} animating={true} color={MD2Colors.greenA700} />)
            :
            (<ActivityIndicator size={"large"} animating={true} color={MD2Colors.greenA700} />)
           }
            {/* <Image
                style={{ width: 100, height: 100 }}
                source={{ uri: 'https://plumsailforms.blob.core.windows.net/0a71f532-582b-42f1-bf8e-e6d64aaa8911-images/0cff4bdb-Ellipsis.gif' }}
            /> */}
        </View>
        
    );
};

export {Loader};