// WARNING: using localStorage to store keys has significant security risks.
// It is safer to host a React app in a server-rendered webapp and store tokens
// in cookie-secured sessions.

const webStorage = {
    setStringAsync: (key: string, value: string): Promise<void> => {
      if (typeof window === 'undefined' || typeof window.localStorage !== 'object') {
        return Promise.reject(new Error('localStorage is not available'));
      }
  
      window.localStorage.setItem(key, value);
      return Promise.resolve();
    },
  
    getStringAsync: (key: string): Promise<string | null> => {
      if (typeof window === 'undefined' || typeof window.localStorage !== 'object') {
        return Promise.reject(new Error('localStorage is not available'));
      }
  
      return Promise.resolve(window.localStorage.getItem(key));
    },
  
    deleteStringAsync: (key: string): Promise<void> => {
      if (typeof window === 'undefined' || typeof window.localStorage !== 'object') {
        return Promise.reject(new Error('localStorage is not available'));
      }
  
      window.localStorage.removeItem(key);
      return Promise.resolve();
    },
  };
  
  export default webStorage;
  
  