import React, { useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView,Animated } from 'react-native';
import { Card } from 'react-native-paper';
import { useNavigation } from '@react-navigation/core';
import { useFocusEffect } from '@react-navigation/native';
//import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { DrawerNavigationProp, DrawerScreenProps } from '@react-navigation/drawer';


import { useClient } from '../providers/ClientProvider';
import { useNav } from '../providers/NavProvider';

import { RootStackParams } from './Home';
import Header from './Header';


// Interface for the API response data
export interface IClientsResp {
  id: number;
  name: string;
  iconURL: string;
  themeColor: string;
  apiUrl: string;
  users: null;
  order: number;
  shortURL: string;
}
type Props = DrawerScreenProps<RootStackParams, "Clients">

const Clients: React.FC<Props> = ({ }) => {
  const navigation = useNavigation<DrawerNavigationProp<RootStackParams>>();
  const { setClient, arrclientObj } = useClient();
  const { setCurrentLeafObj } = useNav();
  //const [clients, setClients] = useState<IClientsResp[]>([]);
  // const [selectedClient, setSelectedClient] = useState<IClientsResp | null>(null);

  const opacityValue = new Animated.Value(0);
  const startAnimation = () => {
    Animated.timing(opacityValue, {
      toValue: 1,
      duration: 1000, // Duration of the animation in milliseconds
      useNativeDriver: true, // This is required for better performance on some devices
    }).start();
  };
  startAnimation();

  useFocusEffect(() => {
    setCurrentLeafObj(null);
  });

  useEffect(() => {
    const fetchData = async () => {
      console.log('Inside Clients');
      try {
        const jsonData = arrclientObj;

        if (arrclientObj.length == 1) {
          setClient(arrclientObj[0]);
          navigation.navigate("Client",{clientShortURL: arrclientObj[0].shortURL });
        }
        // else {
        //   setClient(arrclientObj[1]);
        //   navigation.navigate("Client");
        // }
        //setClients(dataToSet);
        console.log('State Vlaue set')
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [arrclientObj]);

  return (
    <View style={styles.navWrapper}>
      <View>
        <Header></Header>
      </View>

      {/* {console.log('render')} */}
      <ScrollView >
        <View style={styles.container}>
          {arrclientObj.length > 1 && arrclientObj.map((item, index) => (
             <Animated.View key={index}
             style={[{ opacity: opacityValue }]} >
            <Card key={index} style={[styles.card]} onPress={() => {
              setClient(item); navigation.navigate("Client",{clientShortURL: item.shortURL });
            }}>
              {/* {console.log('Card render' + index)} */}
              <Card.Cover style={styles.cardCover} source={{ uri: item.iconURL }} />

              <Card.Title titleVariant='titleLarge' style={styles.cardTitle}   title={item.name} />
              {/* <Card.Content>
                <Text>Theme Color: {item.themeColor}</Text>
                <Text>Theme Color: {item.themeColor}</Text>
                <Text>Theme Color: {item.themeColor}</Text>
                <Text>Theme Color: {item.themeColor}</Text>
                <Text>Theme Color: {item.themeColor}</Text>
                <Text>Theme Color: {item.themeColor}</Text>
                <Text>Theme Color: {item.themeColor}</Text>
                <Text>Theme Color: {item.themeColor}</Text>
                <Text>API URL: {item.apiUrl}</Text>
              </Card.Content> */}
            </Card>
            </Animated.View>
          ))

          }
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  navWrapper: {
    // flex:1
  },

  container: {
    flex: 1,
    // padding: 16,
    gap: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    // alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom:100


  },
  card: {
    minWidth: 350,
    
    marginVertical: 8,
    justifyContent:'center'
    
  },
  cardTitle:{
    alignSelf:'center'
  },
  
  cardCover: {
    height: 350, // Specify desired height for the card cover image
    width: 350,
    resizeMode: 'center',
    // padding:10,
    margin: 10,
    
  },
});

export default Clients;
