import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-paper';
import ButtonControl from '../Logs/common/controls/Button';


interface Props {
  onPress: () => void;
}

const LoginButton: React.FC<Props> = ({ onPress }) => {
  return (
    <View style={styles.container}>
      <ButtonControl icon="account" mode="contained" onPress={() => onPress()} Title='Login' />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }
});

export default LoginButton;