import React, { useState,useEffect } from 'react';
import { View } from 'react-native';
import PaperForm from '../forms/PaperForm';

import DT from './DataTable';
import WebObservationForm from '../forms/WebObservationForm';
import WasteTrackingForm from '../forms/WasteTrackingForm';

interface Props {
  data: Array<any>;
  columns: Array<any>;
  siteId:string;
  logTitle:string;
  updateData:(item:Array<any>)=>void;
  isConsolidatedTable:boolean;
  Permissions?:string;
  QueryStringID?:string;
  chartVisibility?:any;
  funcSetShowFilter?:any;
}
const PaperTableDynamic: React.FC<Props> = React.memo((props) => {
  
  const [data, setData] = useState<Array<any>>(props.data.sort((a, b) => b.ID - a.ID));
  
  
  const [columns, setColumns] = useState<Array<any>>(props.columns.sort((a, b) => a.order - b.order));
  
  const [showPanel, setShowPanel] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [formMode, setformMode] = useState<string>('');
  const formColumns = {
    columns: props.columns.map((column) => {
      if (column.datatype === "calculated") {
        return null;
      }
      return {
        label: column.title,
        key: column.columnName,
        datatype: column.datatype,
        width: 100,
        subType: column.subType,
        options: column.choiceChoices,
        choiceType: column.choiceType,
        order: column.order,
        required: column.required,
        multiline:column.multiline,
        hidden:column.hidden,
      }
    }).filter((column) => column !== null),
  };

  useEffect(() => {
    if (props.logTitle === "Environmental Issues Observations") {
      //setData(props.data.sort((a, b) => b.ID - a.ID));
      if (parseInt(props.QueryStringID) && parseInt(props.QueryStringID) > 0) {
        handleQueryStringItem(parseInt(props.QueryStringID));
        // setTimeout(function () {
        //   handleQueryStringItem(parseInt(props.QueryStringID));
        // }, 2000);
      }
    }
  }, [props.QueryStringID])
  function handleCancelDelete() {
    // Close the confirmation dialog without performing any action
    //setShowConfirmation(false);
  };

  function handleToastDismiss () {
    //setToastVisibility(false);
  };
  function updateFormState () {
    setShowPanel(!showPanel);
    props.funcSetShowFilter(showPanel);
  }
  function updateFormMode (val:string) {
    setformMode(val);
    if(val === 'New')
    {
      updateFormState();
    }
  }
  function ItemCreated(item:Array<any>){
    
    props.updateData(item);
    updateFormState();
  }
  function handleRowPress(row:any) {
    const rowId: number = row.ID;
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(ID => ID !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };
  function handleQueryString(itemID:any) {
    //debugger;
    const rowId: number = parseInt(itemID);
    setSelectedRows([rowId]);
    // if (selectedRows.includes(rowId)) {
    //   setSelectedRows(selectedRows.filter(ID => ID !== rowId));
    // } else {
    //   setSelectedRows([...selectedRows, rowId]);
    // }
  };
  function handleQueryStringItem(sRows:number) {
    const selectedItem = props.data.filter(item => item.ID === sRows);
    setSelectedRows(selectedItem);
    setformMode("View");
    //updateFormState();
    setShowPanel(!showPanel);
    props.funcSetShowFilter(showPanel);
};
  function ViewPressAction(sRows:any) {
      const selectedItem = props.data.filter(item => item.ID === sRows[0]);
      setSelectedRows(selectedItem);
      updateFormState();
      
  };
  function EditPressAction(sRows:any) {
    const selectedItem = props.data.filter(item => item.ID === sRows[0]);
    setSelectedRows(selectedItem);
    updateFormState();
    
  };
  function NewPressAction(){
    updateFormState();
  };
  function chartVisibility(val:boolean){
    props.chartVisibility(val)
    setShowPanel(false);
    props.funcSetShowFilter(true);
  }
  return (
    <View style={{flex:1}}>
      {showPanel=== true ? 
      (
        props.logTitle === "Environmental Issues Observations"? 
        <WebObservationForm
        fields={formColumns.columns}
        formMode={formMode}
        selectedRow={selectedRows}
        closeForm={updateFormState}
        siteId={props.siteId}
        logTitle={props.logTitle}
        updateFormState={updateFormState}
        ItemCreated={ItemCreated}
        Permissions={props.Permissions}
        chartVisibility={chartVisibility}
      />
        :
        props.logTitle === "Waste Tracking Log"?
        
        <WasteTrackingForm
          fields={formColumns.columns}
          formMode={formMode}
          selectedRow={selectedRows}
          closeForm={updateFormState}
          siteId={props.siteId}
          logTitle={props.logTitle}
          updateFormState={updateFormState}
          ItemCreated={ItemCreated}
        />
        :
        <PaperForm
          fields={formColumns.columns}
          formMode={formMode}
          selectedRow={selectedRows}
          closeForm={updateFormState}
          siteId={props.siteId}
          logTitle={props.logTitle}
          updateFormState={updateFormState}
          ItemCreated={ItemCreated}
        />
      )
      : 
      (
        <DT 
          siteId={props.siteId}  
          logTitle={props.logTitle}
          data={props.data.sort((a, b) => b.ID - a.ID)}
          columns={columns}
          updateFormState={updateFormState}
          updateFormMode={updateFormMode}
          handleRowPress={handleRowPress}
          ViewPressAction={ViewPressAction}
          EditPressAction={EditPressAction}
          NewPressAction={NewPressAction}
          isConsolidatedTable={props.isConsolidatedTable}
        />
      )}
    </View>
  );
});

export default PaperTableDynamic;
