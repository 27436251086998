import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select'
import FormFieldTitle from './FormFieldTitle';


interface DropdownField {
  key: string;
  label: string;
  defaultValue?: string;
  options: string;
  required:boolean
}
interface SingleDropdownProps {
  field: DropdownField;
  control?: Control<any>;
  editable?: boolean;
  Ctrl?: typeof Controller;
  errors?:any;
}


const ReactDropdownMultiSelect: React.FC<SingleDropdownProps> = ({field, control, editable, Ctrl, errors}) => {
  const [showDropDown, setShowDropDown] = useState(false);  
  const rule = field.required ? { required: field.label + ' is required' } : { required: false};

  const options = field.options;
  const [items, setItems] = useState(
    options.includes('#;#')
      ? options.split('#;#').map((item, index) => {
          return {
            label: item.trim(),
            value: item.trim(),
            key: index.toString(),
            id: index.toString(),
          };
        })
      : [{ label: options.trim(), value: options.trim(), key: '0' }]
  );
  console.log("Multi select dropdown: "+field.label);
  const [selectedVal, setSelectedVal] = useState<any[]>([]);

  const handleOnChange = (selected: any) => {
    const joinedValues = selected.map((item: any) => item.value).join(', ');
    setSelectedVal(selected); // Update selected values
    console.log('Joined Values:', joinedValues);
  };
  const [selectedItems, setSelectedItems] = useState([]);
  console.log('Selected:', selectedVal);
  
  useEffect(() => {
    if (field.defaultValue) {
      const defaultSelectedItems = field.defaultValue.split(',').map((value, index) => {
        const trimmedValue = value.trim();
        return {
          label: trimmedValue,
          value: trimmedValue,
          key: index.toString(),
          id: index.toString(),
        };
      });
      setSelectedVal(defaultSelectedItems);
    }
  }, [field.defaultValue]);
  const sharedInputStyles = {
    backgroundColor: 'transparent',
    //marginVertical: 5,
    //marginBottom: 30,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 5,
    shadowColor: 'blue',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 4,
    minHeight: 60,
    maxHeight: 60,
  };
  
  return (
    <View style={[{ flexDirection: 'column', paddingTop:6 }]}>
      {/* <ScrollView> */}
      <View style={{width:'100%'}}>{ <FormFieldTitle field={field}/> }</View>
      
        <View style={[styles.container, editable && sharedInputStyles, {paddingTop:0}]}>
        {editable === false ? <Text style={{fontWeight:'normal', fontSize:12}}>{field.defaultValue ?? null}</Text> :
          <Ctrl
            key={field.key}
            control={control}
            name={field.key}
            rules={rule}
            defaultValue={field.defaultValue ?? null}
            render={({ field:{ onChange, onBlur, value} }) => (
              <Select
                options={items}
                isMulti
                value={selectedVal} // controlled component state
                onChange={(selectedValue) => {
                  const joinedValues = selectedValue.map((item: any) => item.value).join(', ');
                  onChange(joinedValues); // <-- This sends the selected values to the form (React Hook Form)
                  handleOnChange(selectedValue); // <-- This handles any custom logic you want
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    borderWidth: 0,
                    backgroundColor: 'rgb(247, 243, 249)', // Set background color for the dropdown
                    zIndex: 100, // Ensures the dropdown is on top of other elements
                  }),
                  control: (base, state) => ({
                    ...base,
                    borderWidth: 0, // No border on control (input box)
                    boxShadow: 'none', // Remove shadow when focused
                    '&:hover': {
                      borderWidth: 0, // No border when hovered
                    },
                    '&:focus': {
                      borderWidth: 0, // No border on focus
                    },
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    borderWidth: 0,
                    zIndex: 9999, // Ensures the dropdown portal is at the highest level
                  }),
                }}
                menuPortalTarget={document.body}
              />
            )}
          />
            }
        </View>
        {errors[field.key]?.message && <Text style={{ color: 'red' }}>{errors[field.key]?.message}</Text>}
      {/* </ScrollView> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    //padding: 24,
  }
});

export default ReactDropdownMultiSelect