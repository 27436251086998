import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import FormFieldTitle from './FormFieldTitle';

interface DropdownField {
  key: string;
  label: string;
  defaultValue?: string;
  options: string;
  required: boolean;
}
interface SingleDropdownProps {
  field: DropdownField;
  control?: Control<any>;
  editable?: boolean;
  Ctrl?: typeof Controller;
  errors?: any;
  changeEvent?: any;
  choices?: any;
  //parent?:string;
}

const ReactDropdownSingleSelect: React.FC<SingleDropdownProps> = ({ field, control, editable, Ctrl, errors, changeEvent, choices }) => {
  const rule = field.required ? { required: field.label + ' is required' } : { required: false };
  const [items, setItems] = useState()


  const [selectedVal, setSelectedVal] = useState<any>(null);

  const handleOnChange = (selected: any) => {
    //debugger;
    setSelectedVal(selected); // Update selected value for single select
    changeEvent && selected.value && changeEvent(selected.value)
  };

  useEffect(() => {
    if (field.defaultValue) {
      const defaultSelectedItem = {
        label: field.defaultValue.trim(),
        value: field.defaultValue.trim(),
        key: '0',
      };
      setSelectedVal(defaultSelectedItem);
    }

    const options = choices && choices ? choices : field.options;
    setItems(
      options.includes('#;#')
        ? options.split('#;#').map((item: string, index: number) => {
          return {
            label: item.trim(),
            value: item.trim(),
            key: index.toString(),
            id: index.toString(),
          };
        })
        : [{ label: options.trim(), value: options.trim(), key: '0' }]
    );

  }, [field.defaultValue, choices]);

  const sharedInputStyles = {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 5,
    shadowColor: 'blue',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 4,
    minHeight: 60,
    maxHeight: 60,
  };

  return (
    <View style={[{ flexDirection: 'column', paddingTop: 6 }]}>
      <View style={{ width: '100%' }}>{<FormFieldTitle field={field} />}</View>

      <View style={[styles.container, editable && sharedInputStyles, { paddingTop: 0 }]}>
        {editable === false ? (
          <Text style={{ fontWeight: 'normal', fontSize: 12 }}>{field.defaultValue ?? null}</Text>
        ) : (
          <Ctrl
            key={field.key}
            control={control}
            name={field.key}
            rules={rule}
            defaultValue={field.defaultValue ?? null}
            render={({ field: { onChange, value } }) => (
              <Select
                options={items}
                isMulti={false} // Single select dropdown
                value={selectedVal} // Controlled component state
                onChange={(selectedValue) => {
                  onChange(selectedValue?.value); // Send the single selected value to the form
                  handleOnChange(selectedValue); // Update the internal selected state
                }}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    borderWidth: 0,
                    backgroundColor: 'rgb(247, 243, 249)', // Set background color for the dropdown
                    zIndex: 100, // Ensures the dropdown is on top of other elements
                  }),
                  control: (base) => ({
                    ...base,
                    borderWidth: 0, // No border on control (input box)
                    boxShadow: 'none', // Remove shadow when focused
                    '&:hover': {
                      borderWidth: 0, // No border when hovered
                    },
                    '&:focus': {
                      borderWidth: 0, // No border on focus
                    },
                  }),
                  menuPortal: (provided) => ({
                    ...provided,
                    borderWidth: 0,
                    zIndex: 9999, // Ensures the dropdown portal is at the highest level
                  }),
                }}
                menuPortalTarget={document.body}
              />
            )}
          />
        )}
      </View>
      {errors[field.key]?.message && <Text style={{ color: 'red' }}>{errors[field.key]?.message}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    justifyContent: 'center',
  },
});

export default ReactDropdownSingleSelect;
