import React, { useEffect, useState } from 'react';
import { Image, ActivityIndicator, View, Text, Platform, useWindowDimensions, } from 'react-native';
import { useAuth } from '../../providers/AuthProvider';
import { useClient } from '../../providers/ClientProvider';
import { getAssetData } from '../Logs/common/service/dataOperations';
export interface IAuthImageProps {
    imageFileName: string;
    targetHeight?: number;
    targetWidth?: number;
}


const AuthImage: React.FC<IAuthImageProps> = ({ imageFileName,  targetWidth ,targetHeight}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [imageData, setImageData] = useState(null);
    //const [imageSize,setImageSize]=useState(null);
    const [imageStyle, setImageStyle] = useState(null);
    const { getAccessToken } = useAuth();
    const { clientAPIURL } = useClient();
    const {width} = useWindowDimensions();
    useEffect(() => {
        const fetchImage = async () => {
            try {
                if (clientAPIURL) {
                    const accessToken = await getAccessToken();
                    const response = await getAssetData(imageFileName, accessToken, clientAPIURL);

                    if (response.ok) {
                        const blob = await response.blob();
                        const fileReaderInstance = new FileReader();
                        fileReaderInstance.readAsDataURL(blob);
                        fileReaderInstance.onload = () => {
                            let base64data = fileReaderInstance.result;
                            Image.getSize(base64data.toString(), (imgWidth, imgHeight) => {
                                //setImageSize({imgWidth,imgHeight});
                                let imageStyle1 = {
                                    width: (Platform.OS == 'web') ? (targetWidth ? (targetWidth>width)? "100%":targetWidth : (imgWidth>width)? "100%":imgWidth) : "100%",
                                    //height: height?height:undefined,                                                               
                                    aspectRatio: (targetWidth && targetHeight) ? (targetWidth / targetHeight) : (imgWidth / imgHeight),
                                    //maxwidth:"100%",
                                    // alignSelf:'center',
                                    // maxHeight:'100%',
                                    //marginLeft:0,
                                    marginRight: (Platform.OS == 'web') ? 0 : 'auto'
                                };
                                setImageStyle(imageStyle1)

                            });
                            setImageData(base64data);

                        }
                        //setImageData(blob);
                    } else {
                        setError(true);
                    }

                    setLoading(false);
                }
            } catch (error) {
                setError(true);
                setLoading(false);
            }
        };

        fetchImage();
    }, [imageFileName, clientAPIURL]);




    if (loading) {
        return (
            <View>
                <ActivityIndicator size="small" />
            </View>
        );
    }

    if (error) {
        return (
            <View>
                <Text>Error loading image</Text>
            </View>
        );
    }

    return (
        <>
            {imageData &&
                <Image
                    source={{ uri: imageData }}
                    style={imageStyle}
                    resizeMode="contain"

                />
            }
        </>
    );
};

export default AuthImage;