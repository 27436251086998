import { StyleSheet } from 'react-native';

const formCustomStyles = StyleSheet.create({
    formBaseStyle: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20
    },
    allFieldsContainer:{
      flex: 1,
      gap: 20,
      flexGrow: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      //alignItems: 'center',
      justifyContent: 'flex-start',

      padding:10,
    },
    fieldContianer: {
      maxWidth: 500,
      minWidth: 200,
      marginVertical: 8,
      minHeight: 100,
      width: '100%',
      //height:'100%',
      //alignItems:'center'
    },
    fieldContianerViewMode: {
      maxWidth: 500,
      minWidth: 200,
      marginVertical: 8,
      minHeight: 50,
      width: '100%',
      //height:'100%',
      //alignItems:'center'
    },
  buttonsContainer: {
    marginBottom: 20,
    alignItems: 'center',
  },
  fileText: {
    fontSize: 16,
    marginVertical: 5,
  },
  modalContainer: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 8,
    alignItems: 'center',
  },
  centeredView: {
    flex: 1,
    gap: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalImage: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
    marginBottom: 20,
  },
  closeButton: {
    marginTop: 10,
    backgroundColor: 'red',
  },
  openButton: {
    backgroundColor: 'green',
  },
});

export default formCustomStyles;